var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFetch } from 'src/hooks/api/useFetch';
import { useQuery } from 'react-query';
var usePayPreviewApi = function () {
    var customFetch = useFetch().request;
    var useGetPreview = function (request) {
        var _a, _b, _c;
        return useQuery([
            'payPreview',
            "".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.shiftRid, "-").concat((_b = request.body) === null || _b === void 0 ? void 0 : _b.shiftStartAt, "-").concat((_c = request.body) === null || _c === void 0 ? void 0 : _c.shiftLocationRid),
        ], function () {
            return customFetch({
                method: 'POST',
                url: "pay_preview",
            }, request.body);
        }, __assign({}, request.options));
    };
    return {
        useGetPreview: useGetPreview,
    };
};
export default usePayPreviewApi;
