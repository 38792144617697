var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useFetch } from './useFetch';
export var useLocalizationApi = function () {
    var customFetch = useFetch().request;
    var useGetTranslationModalView = function (request) {
        return useQuery(['translationModalView', JSON.stringify(request.body)], function () {
            var _a, _b, _c, _d;
            return customFetch({
                method: 'GET',
                url: "localization/translation_modal_view/".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.contentId, "?contentType=").concat((_b = request.body) === null || _b === void 0 ? void 0 : _b.contentType).concat(((_c = request.body) === null || _c === void 0 ? void 0 : _c.contentIndex) !== undefined ? "&contentIndex=".concat((_d = request.body) === null || _d === void 0 ? void 0 : _d.contentIndex) : ''),
            });
        }, __assign({}, request.options));
    };
    var useUpdateTranslationValues = useMutation(function (request) {
        return customFetch({
            method: 'PUT',
            url: 'localization/update_translation_values',
        }, request);
    });
    var useGenerateTranslation = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "localization/generate_translation",
            }, __assign({}, request));
        });
    };
    return {
        useGetTranslationModalView: useGetTranslationModalView,
        useUpdateTranslationValues: useUpdateTranslationValues,
        useGenerateTranslation: useGenerateTranslation,
    };
};
