import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { IpName } from 'src/shared';
import { getIpDisplayText } from 'src/utils/Account';
var IPDisplayCard = function (_a) {
    var ipName = _a.ipName;
    var defaultDetailsBody = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { children: ["Import information directly from ", getIpDisplayText(ipName), " \u00A0\u2714\uFE0F"] }), _jsx("div", { children: "Sync of account information \u00A0\u2714\uFE0F" }), _jsx("div", { children: "24/7 support from your TeamBridge Pilot \u00A0 \u2714\uFE0F" })] }));
    }, [ipName]);
    var greenshadesDetailsBody = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: "Get time card pay data directly from Greenshades \u00A0\u2714\uFE0F" }), _jsx("div", { children: "Automatically include PDF pay stubs on employees' time sheets\u00A0\u2714\uFE0F" })] }));
    }, []);
    var detailsBody = useMemo(function () {
        if (ipName == IpName.GREENSHADES)
            return greenshadesDetailsBody;
        return defaultDetailsBody;
    }, [ipName, defaultDetailsBody, greenshadesDetailsBody]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "IntegrationSetupSubHeading", children: "Integration Details" }), detailsBody] }));
};
export default React.memo(IPDisplayCard);
