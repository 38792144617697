export var DASHBOARD_FEATURE_GATING_KEY = 'account_dashboard';
export var CHAT_FEATURE_GATING_KEY = 'chat';
export var HEALTH_METRICS_FEATURE_GATING_KEY = 'account_health_metrics';
export var PAGE_TYPES = 'page_types';
export var NEEDS_COVERAGE = 'needs_and_coverage';
export var VIRTUALIZED_CALENDAR_DISABLED = 'virtualized_calendar_disabled';
export var NEW_SHIFT_TEMPLATE = 'new_shift_template';
export var NEWS_FEED_BANNER = 'news_feed_banner';
export var SHIFT_RECOMMENDATIONS_FEATURE = 'shift_recommendations';
export var TASKS_FEATURE = 'tasks';
export var RECIPES_FEATURE_GATING_KEY = 'recipes';
export var CUTTING_EDGE_EVENT_REPORTS_FEATURE = 'cutting_edge_event_reports';
export var ROLLUP_SCHEMA_MODAL = 'rollup_schema_modal';
export var GENERATED_SCHEMA_MODAL = 'generated_schema_modal';
export var SMART_TEXT_SCHEMA = 'smart_text_schema';
export var DEFAULT_RATES_TABLE_FRONTEND = 'default_rates_table_frontend';
export var PAYMENT_RULES_TAB = 'payment_rules_tab';
export var FRONTEND_TOTAL_BILL = 'frontend_total_bill';
export var BILL_OVER_TIME_DROPDOWN = 'frontend_total_bill';
export var USE_CALCULATED_OT_THROTTLE = 'use_calculated_ot_throttle';
export var JOBS_AND_PLACEMENTS = 'jobs_and_placements';
export var SPLIT_NEEDS_COVERAGE_PERMISSION = 'split_needs_coverage_permission';
export var TEMPLATE_PROPAGATION_MODAL = 'template_propagation_modal';
export var ENABLE_TITAN_API = 'enable_titan_api';
export var ENABLE_PROLIANT_READYPAY_API = 'enable_proliant_readypay_api';
export var ENABLE_GREENSHADES_API = 'enable_greenshades_api';
export var NEEDS_COVERAGE_BLOCKING_MODAL = 'needs_coverage_blocking_modal';
export var ENABLE_KAMANA = 'enable_kamana';
export var HIDE_START_DAY_OF_WEEK_FROM_CALENDAR = 'hide_start_day_of_week_from_calendar';
export var MAXIMA_SERVICE_AUTOMATION_QUALIFIED_USERS = 'maxima_service_automation_qualified_users';
export var CREATE_NEW_ACCOUNT_BUTTON = 'create_new_account_button';
export var SPECIAL_ACCESS_RECOMMEND_SHIFT = 'special_access_recommend_shifts';
export var CUSTOMIZE_MOBILE_SHIFT_CARD = 'customize_mobile_shift_card';
export var RATING_SCHEMA = 'ratings_schema';
export var LOCALIZATION = 'localization';
export var CUSTOMIZE_MOBILE_JOB_CARD = 'customize_mobile_job_card';
export var OVERTIME_FOUNDATIONS_SETTINGS = 'overtime_foundations_settings';
export var USE_NC_WEEK_CORRECT_CALCULATION = 'use_nc_correct_weekly_calculation';
export var ENABLE_CUSTOM_PHONE_NUMBER_SCHEMA = 'enable_custom_phone_number_schema';
