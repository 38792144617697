var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
var SwitchWithLabel = function (_a) {
    var label = _a.label, tooltip = _a.tooltip, subtitle = _a.subtitle, rest = __rest(_a, ["label", "tooltip", "subtitle"]);
    return (_jsxs("label", { className: "flex items-center gap-3 justify-between", children: [_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "font-medium flex intes-center", children: [label, tooltip && (_jsx(ZiraTooltip, { title: tooltip, children: _jsx(InfoCircleOutlined, { className: "ml-1" }) }))] }), subtitle && _jsx("div", { className: "text-xs text-gray-400", children: subtitle })] }), _jsx(Switch, __assign({}, rest, { className: "m-0" }))] }));
};
export default SwitchWithLabel;
