import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { useHolidayPayApi, useSetting } from 'src/hooks/api';
import { CompliancePageState } from 'src/hooks/state/Compliance';
import SwitchWithLabel from 'src/components/SharedComponent/SwitchWithLabel/SwitchWithLabel';
var HolidayOvertimeSettings = function () {
    var _a = useHolidayPayApi(), useGetHolidayOvertimeSetting = _a.useGetHolidayOvertimeSetting, useSetHolidayOvertimeSetting = _a.useSetHolidayOvertimeSetting;
    var useGetWeeklyAverageOvertimeEnabled = useSetting().useGetWeeklyAverageOvertimeEnabled;
    var _b = useGetHolidayOvertimeSetting(), data = _b.data, isInitialized = _b.isLoading, isFetchingHolidayOvertime = _b.isFetching;
    var mutate = useSetHolidayOvertimeSetting.mutate, isLoading = useSetHolidayOvertimeSetting.isLoading;
    var _c = CompliancePageState.useContainer(), weeklyOvertimeAverageEnabledSetting = _c.weeklyOvertimeAverageEnabledSetting, setWeeklyOvertimeAverageEnabledSetting = _c.setWeeklyOvertimeAverageEnabledSetting;
    var isFetchingWeeklyOvertime = useGetWeeklyAverageOvertimeEnabled(function (data) {
        setWeeklyOvertimeAverageEnabledSetting(data);
    }).isFetching;
    var isFetching = isFetchingHolidayOvertime || isFetchingWeeklyOvertime;
    var _d = useState(false), isHolidayMaskOvertime = _d[0], setIsHolidayMaskOvertime = _d[1];
    var _e = useState(false), isWeightedAverageOvertime = _e[0], setIsWeightedAverageOvertime = _e[1];
    useEffect(function () {
        if (data != null && !isInitialized) {
            setIsHolidayMaskOvertime(data.holidayMaskOvertimeEnabled || false);
            setIsWeightedAverageOvertime(data.weightedWeeklyAverageIncludesOvertimeEnabled || false);
        }
    }, [data, isInitialized]);
    var setHolidayOvertimeSetting = useCallback(function (maskOvertime, holidayWeightedAverage) {
        var holidayOvertime = maskOvertime != undefined ? maskOvertime : isHolidayMaskOvertime;
        var weightedAverage = holidayWeightedAverage != undefined
            ? holidayWeightedAverage
            : isWeightedAverageOvertime;
        setIsHolidayMaskOvertime(holidayOvertime);
        setIsWeightedAverageOvertime(weightedAverage);
        mutate({
            holidayMaskOvertimeEnabled: holidayOvertime,
            weightedWeeklyAverageIncludesOvertimeEnabled: weightedAverage,
        }, {
            onError: function () { },
        });
    }, [isHolidayMaskOvertime, isWeightedAverageOvertime, mutate]);
    return (_jsxs("div", { children: [_jsx("div", { className: "SectionDescriptionTitle", children: "Holiday Overtime" }), _jsx("div", { className: "SectionSubTitle", children: "Set how your holiday and overtime settings will interact" }), !isFetching ? (_jsxs("div", { className: "flex flex-col gap-2 mt-2", children: [_jsx(SwitchWithLabel, { label: "Exclude holiday hours in overtime calculations.", subtitle: "When this setting is on, holiday hours are not counted toward overtime thresholds. \n                            When this setting is off, holiday hours contribute to reaching overtime.", disabled: isLoading, checked: isHolidayMaskOvertime, onChange: function (checked) { return setHolidayOvertimeSetting(checked, undefined); } }), weeklyOvertimeAverageEnabledSetting && (_jsx(SwitchWithLabel, { label: "Include holiday rate in overtime rate", subtitle: "When active, the holiday rate is included in the weighted average calculation for the overtime rate.", disabled: isLoading, checked: isWeightedAverageOvertime, onChange: function (checked) { return setHolidayOvertimeSetting(undefined, checked); } }))] })) : (_jsx(Skeleton, {}))] }));
};
export default HolidayOvertimeSettings;
