var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { usePageApi } from 'src/hooks/api';
import './styles/CsvExportTemplateConfigModal.scss';
import { DeleteOutlined } from '@ant-design/icons';
import ZiraDeletePopconfirm from '../../../ui/ZiraDeletePopconfirm';
import { aggregateReportFormatOptions, PayReportExportTemplateType, TEMPLATE_FORM_FIELD_LABEL_CLASS, } from '../Constant';
var CheckboxGroup = Checkbox.Group;
var Option = Select.Option;
var CsvExportTemplateConfigModal = function (props) {
    var onCloseModal = props.onCloseModal, existingTemplateData = props.existingTemplateData;
    var _a = usePageApi(), useGetTemplateReportSchemasAndMetrics = _a.useGetTemplateReportSchemasAndMetrics, useCreateReportExportTemplate = _a.useCreateReportExportTemplate, useDeleteReportExportTemplate = _a.useDeleteReportExportTemplate, useUpdateReportExportTemplate = _a.useUpdateReportExportTemplate;
    var _b = useState(existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.reportType), selectedFormat = _b[0], setSelectedFormat = _b[1];
    var _c = useState([]), schemas = _c[0], setSchemas = _c[1];
    var _d = useState([]), metrics = _d[0], setMetrics = _d[1];
    var form = Form.useForm()[0];
    useEffect(function () {
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.schemas) {
            var schemas_1 = [];
            existingTemplateData.schemas.forEach(function (schema) {
                schemas_1.push({
                    value: schema.id,
                    label: schema.name,
                });
            });
            setSchemas(schemas_1);
        }
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.metrics) {
            var metrics_1 = [];
            existingTemplateData.metrics.forEach(function (metric) {
                metrics_1.push({
                    value: metric.metric,
                    label: metric.displayName,
                });
            });
            setMetrics(metrics_1);
        }
    }, [existingTemplateData]);
    var selectedMetricsInitialValues = useMemo(function () {
        var _a;
        var metricsInitialValues = [];
        if (existingTemplateData) {
            (_a = existingTemplateData.metrics) === null || _a === void 0 ? void 0 : _a.forEach(function (metric) {
                if (metric.showInReport) {
                    metricsInitialValues.push(metric.metric);
                }
            });
        }
        return metricsInitialValues;
    }, [existingTemplateData]);
    var templateFormInitialValues = useMemo(function () {
        var schemas = [];
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.schemas) {
            existingTemplateData.schemas.forEach(function (schema) {
                if (schema.showInReport) {
                    schemas.push(schema.id);
                }
            });
        }
        return {
            templateName: (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.name) || undefined,
            templateType: (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.reportType) || undefined,
            selectedSchemas: schemas,
            selectedMetrics: selectedMetricsInitialValues,
        };
    }, [existingTemplateData, selectedMetricsInitialValues]);
    var reportFormatOnChange = useCallback(function (reportFormat) {
        setSelectedFormat(reportFormat);
        form.setFieldsValue({
            selectedSchemas: reportFormat !== (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.reportType)
                ? []
                : templateFormInitialValues.selectedSchemas,
        });
        useGetTemplateReportSchemasAndMetrics.mutate(reportFormat, {
            onSuccess: function (data) {
                if (data) {
                    var schemas_2 = [];
                    data.schemas.forEach(function (schema) {
                        schemas_2.push({
                            value: schema.id,
                            label: schema.name,
                        });
                    });
                    setSchemas(schemas_2);
                    if (data.metrics) {
                        var metrics_2 = [];
                        data.metrics.forEach(function (metric) {
                            metrics_2.push({
                                value: metric.metric,
                                label: metric.displayName,
                            });
                        });
                        setMetrics(metrics_2);
                        var allMetrics_1 = [];
                        data.metrics.forEach(function (metric) {
                            allMetrics_1.push(metric.metric);
                            form.setFieldsValue({
                                selectedMetrics: reportFormat !== (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.reportType)
                                    ? allMetrics_1
                                    : selectedMetricsInitialValues,
                            });
                        });
                    }
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onSaveTemplate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues_1, schemasInfo_1, metricsInfo_1, request, errorInfo_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    formValues_1 = _a.sent();
                    schemasInfo_1 = [];
                    metricsInfo_1 = [];
                    schemas.forEach(function (schema) {
                        var isSelected = formValues_1.selectedSchemas.includes(schema.value);
                        schemasInfo_1.push({
                            id: schema.value,
                            showInReport: isSelected,
                        });
                    });
                    metrics.forEach(function (metric) {
                        var isSelected = formValues_1.selectedMetrics.includes(metric.value);
                        metricsInfo_1.push({
                            metric: metric.value,
                            showInReport: isSelected,
                        });
                    });
                    request = {
                        type: PayReportExportTemplateType.CSV,
                        templateName: formValues_1.templateName,
                        csvExportSettings: {
                            schemas: schemasInfo_1,
                            metrics: metricsInfo_1,
                            reportType: formValues_1.templateType,
                        },
                    };
                    if (existingTemplateData) {
                        request = __assign(__assign({}, request), { id: existingTemplateData.id });
                        useUpdateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    else {
                        useCreateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    errorInfo_1 = _a.sent();
                    return [2 /*return*/];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        existingTemplateData,
        form,
        metrics,
        onCloseModal,
        schemas,
        useCreateReportExportTemplate,
        useUpdateReportExportTemplate,
    ]);
    var onDeleteTemplate = useCallback(function () {
        if (existingTemplateData) {
            useDeleteReportExportTemplate.mutate(existingTemplateData.id, {
                onSuccess: function () {
                    onCloseModal();
                },
            });
        }
    }, [existingTemplateData, onCloseModal, useDeleteReportExportTemplate]);
    var modalFooter = (_jsxs("div", { className: "mx-5 flex ".concat(existingTemplateData ? 'justify-between' : 'justify-end'), children: [existingTemplateData && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this template ?", onConfirm: onDeleteTemplate, placement: "top", children: _jsx(Button, { className: "icon-text-btn DeleteButton", icon: _jsx(DeleteOutlined, { className: "mb-1 ml-px" }), children: "Delete" }) })), _jsxs("div", { children: [_jsx(Button, { className: "bg-gray-60 text-black hover:bg-gray-60 hover:text-black ", onClick: onCloseModal, children: "Cancel" }), _jsx(Button, { className: "bg-black text-white hover:bg-black hover:text-white", onClick: onSaveTemplate, children: "Save" })] })] }));
    return (_jsxs(Modal, { className: "CsvTemplateConfigModal", open: true, centered: true, footer: modalFooter, closable: false, width: 600, children: [_jsx("div", { className: "mb-6 text-lg font-semibold", children: "".concat(existingTemplateData ? 'Edit' : 'Create New', " CSV Template") }), _jsxs(Form, { form: form, labelCol: { span: 6 }, wrapperCol: { span: 24 }, layout: "vertical", requiredMark: 'optional', preserve: false, initialValues: templateFormInitialValues, children: [_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Template Name" }), name: "templateName", rules: [
                            {
                                required: true,
                                message: 'Please enter template name.',
                            },
                        ], children: _jsx(Input, { placeholder: "Enter template name here", autoFocus: true }) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Template Type" }), name: "templateType", rules: [
                            {
                                required: true,
                                message: 'Please select template type.',
                            },
                        ], children: _jsx(Select, { className: "w-full", onChange: reportFormatOnChange, children: aggregateReportFormatOptions.slice(0, -1).map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) }), selectedFormat && (_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Include Fields" }), name: "selectedSchemas", rules: [
                            {
                                required: true,
                                message: 'Please at least select one field.',
                            },
                        ], children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: schemas }) })), selectedFormat && (_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Include Metrics" }), name: "selectedMetrics", rules: [
                            {
                                required: true,
                                message: 'Please at least select one metric.',
                            },
                        ], children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: metrics }) }))] })] }));
};
export default React.memo(CsvExportTemplateConfigModal);
