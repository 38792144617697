var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import _, { isNil } from 'lodash';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import { ItalicButton, BoldButton, UnderlineButton, HeadlineOneButton, UnorderedListButton, OrderedListButton, BlockquoteButton, } from '@draft-js-plugins/buttons';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import './MarkdownBlock.scss';
import createMarkdownShortcutsPlugin from 'draft-js-markdown-shortcuts-plugin';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { canEditPageSelector } from 'src/state';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import { usePageApi } from '../../../hooks/api';
//!MARK: - if seen consecutive more than 2 \n, then merge them into \n\n
//       - first \n shouldn't have \ before it
var handleRawMarkdown = function (rawMarkdown) {
    return rawMarkdown.replace(/\n{3,}/g, '\n\n');
};
var convertStateToRawMarkdown = function (state) {
    var currentContentState = state.getCurrentContent();
    var rawJs = convertToRaw(currentContentState);
    var rawMarkdown = draftToMarkdown(rawJs, { preserveNewlines: true });
    return handleRawMarkdown(rawMarkdown);
};
var convertRawDataToEditorState = function (rawData) {
    if (rawData !== null) {
        var data = handleRawMarkdown(rawData);
        var contentState = convertFromRaw(markdownToDraft(data, { preserveNewlines: true }));
        return EditorState.createWithContent(contentState);
    }
    return createEditorStateWithText('');
};
var MarkdownBlock = function (props) {
    var blockId = props.blockId, moveBlockUpDown = props.moveBlockUpDown;
    var canEditPage = useRecoilValue(canEditPageSelector);
    var _a = usePageApi(), useListBlockQuery = _a.useListBlockQuery, useUpdatePageBlock = _a.useUpdatePageBlock;
    var data = useListBlockQuery({
        blockId: blockId,
        contentType: 'markdown',
    }).data;
    var _b = useState(), editorState = _b[0], setEditorState = _b[1];
    useEffect(function () {
        if (data && data[0] && !isNil(data[0].markdown)) {
            setEditorState(convertRawDataToEditorState(data[0].markdown));
        }
    }, [data]);
    var _c = useMemo(function () {
        var inlineToolbarPlugin = createInlineToolbarPlugin();
        var linkPlugin = createLinkPlugin({
            linkTarget: '_blank',
        });
        var markDownPlugin = createMarkdownShortcutsPlugin();
        var linkifyPlugin = createLinkifyPlugin({
            target: '_blank',
        });
        var InlineToolbar = inlineToolbarPlugin.InlineToolbar;
        var plugins = [inlineToolbarPlugin, linkPlugin, markDownPlugin, linkifyPlugin];
        return {
            InlineToolbar: InlineToolbar,
            plugins: plugins,
            linkPlugin: linkPlugin,
        };
    }, []), InlineToolbar = _c.InlineToolbar, plugins = _c.plugins, linkPlugin = _c.linkPlugin;
    var editor = useRef(null);
    // using ref to avoid first time rendering calling update
    var editorLoaded = useRef(false);
    var saveContent = useMemo(function () {
        return _.debounce(function (blockId, editorState) {
            var value = convertStateToRawMarkdown(editorState);
            useUpdatePageBlock.mutate({
                blockId: blockId,
                content: { markdown: value },
            });
        }, 1000, { leading: true });
    }, 
    // in order to make debounce working, need to keep dependency array empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var onChange = function (value) {
        setEditorState(value);
        if (!editorLoaded.current) {
            editorLoaded.current = true;
            return;
        }
        if (canEditPage) {
            saveContent(blockId, value);
        }
    };
    useEffect(function () {
        return function () {
            saveContent.cancel();
        };
    }, [saveContent]);
    var focus = function () {
        var _a;
        (_a = editor.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    // TODO: use permission to switch readOnly
    return !editorState ? (_jsx(_Fragment, {})) : (_jsxs("div", { className: "MarkdownBlockEditor", children: [_jsx(HoverOptionButtons, { data: {
                    blockId: blockId,
                }, moveBlockUpDown: moveBlockUpDown, isNonTitleBlock: true }), _jsxs("div", { onClick: focus, children: [_jsx(Editor, { readOnly: canEditPage ? false : true, placeholder: "type something here", editorState: editorState, onChange: onChange, plugins: plugins, ref: function (element) {
                            editor.current = element;
                        } }), _jsx(InlineToolbar, { children: function (externalProps) { return (_jsxs("span", { className: "TextEditorButtons", children: [_jsx(BoldButton, __assign({}, externalProps)), _jsx(ItalicButton, __assign({}, externalProps)), _jsx(UnderlineButton, __assign({}, externalProps)), _jsx(HeadlineOneButton, __assign({}, externalProps)), _jsx(UnorderedListButton, __assign({}, externalProps)), _jsx(OrderedListButton, __assign({}, externalProps)), _jsx(BlockquoteButton, __assign({}, externalProps)), _jsx(linkPlugin.LinkButton, __assign({}, externalProps, { onOverrideContent: function () { } }))] })); } })] })] }));
};
export default React.memo(MarkdownBlock);
