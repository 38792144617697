var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { BespokeReverseCollectionComponent, CollectionType, DefaultDisplaySettingsObj, } from 'src/shared';
import { detailModalActiveTab, tableDisplaySettingsState, } from 'src/state';
import ShiftReleaseTable from 'src/components/PageView/ShiftRequest/ShiftRelease/ShiftReleaseTable';
import ScheduleReverseTable from 'src/components/ScheduleView/ScheduleReverseTable';
import ReverseCollectionView from 'src/components/OptimizedTableView/ReverseCollection/ReverseCollectionView';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { DEFAULT_RATES_TABLE_FRONTEND } from 'src/components/FeatureGatingKeyConstant';
import ProjectTrackingTable from '../../TableView/ProjectTrackingTable';
import ShiftClaimTable from '../../PageView/ShiftRequest/ShiftClaim/ShiftClaimTable';
import ShiftDetailGeoMap from './Shift/ShiftDetailGeoMap';
import UserDetailPayRateTable from './User/UserDetailPayRateTable';
import UserDetailTimeOffSummary from './User/UserDetailTimeOffSummary';
import LocationDetailPayRateTable from './Location/LocationDetailPayRateTable';
import TasksReverseCollection from './Task/TasksReverseCollection';
import UserDefaultRateTable from './User/UserDefaultRateTable';
var DetailModalReverseCollection = function (_a) {
    var _b, _c, _d, _e;
    var recordId = _a.recordId, reverseCollections = _a.reverseCollections, referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, modalData = _a.modalData, dataSource = _a.dataSource, extras = _a.extras;
    var shiftTimeDetails = (extras !== null && extras !== void 0 ? extras : {}).shiftTimeDetails;
    var activeTab = useRecoilValue(detailModalActiveTab(recordId));
    var _f = useState(), reverseCollection = _f[0], setReverseCollection = _f[1];
    var isDefaultRatesTurnedOn = useRecoilValue(featureEnableStatusState(DEFAULT_RATES_TABLE_FRONTEND));
    useEffect(function () {
        setTimeout(function () {
            return setReverseCollection(reverseCollections.find(function (collection) { var _a; return activeTab === ((_a = collection.schemaId) !== null && _a !== void 0 ? _a : collection.standardRelation); }));
        }, 0);
    }, [activeTab, reverseCollections]);
    var _g = useRecoilState(tableDisplaySettingsState((_c = (_b = reverseCollection === null || reverseCollection === void 0 ? void 0 : reverseCollection.schemaId) !== null && _b !== void 0 ? _b : reverseCollection === null || reverseCollection === void 0 ? void 0 : reverseCollection.standardRelation) !== null && _c !== void 0 ? _c : '')), reverseCollectionDisplaySettings = _g[0], setReverseCollectionDisplaySettings = _g[1];
    // Initialize display settings
    useEffect(function () {
        if ((reverseCollection === null || reverseCollection === void 0 ? void 0 : reverseCollection.displaySettings) && !reverseCollectionDisplaySettings) {
            setReverseCollectionDisplaySettings(reverseCollection.displaySettings);
        }
        else if (reverseCollection && !reverseCollectionDisplaySettings) {
            setReverseCollectionDisplaySettings(DefaultDisplaySettingsObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reverseCollection]);
    var resetReverseCollectionDisplaySettings = useRecoilCallback(function (_a) {
        var reset = _a.reset;
        return function (reverseRelationId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                reset(tableDisplaySettingsState(reverseRelationId));
                return [2 /*return*/];
            });
        }); };
    }, []);
    //! Reset reverse collections' display settings on modal close
    useEffect(function () {
        return function () {
            for (var _i = 0, reverseCollections_1 = reverseCollections; _i < reverseCollections_1.length; _i++) {
                var reverseCollection_1 = reverseCollections_1[_i];
                var schemaId = reverseCollection_1.schemaId, standardRelation = reverseCollection_1.standardRelation;
                var reverseRelationId = schemaId !== null && schemaId !== void 0 ? schemaId : standardRelation;
                resetReverseCollectionDisplaySettings(reverseRelationId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reverseCollection]);
    if (!reverseCollection) {
        // Bespoke reverse collection
        return (_jsxs("div", { className: "ModalBodyCollections", style: { padding: activeTab === 'Details' ? 0 : undefined }, children: [activeTab === BespokeReverseCollectionComponent.SHIFT_TRACKING_MAP &&
                    modalData.mainRecord.nonSchemaNativeFieldsMap[recordId] &&
                    shiftTimeDetails && (_jsx("div", { id: BespokeReverseCollectionComponent.SHIFT_TRACKING_MAP, children: _jsx(ShiftDetailGeoMap, { dataSource: dataSource, shiftTimeDetails: shiftTimeDetails, nonSchemaNativeFields: modalData.mainRecord.nonSchemaNativeFieldsMap[recordId] }) })), activeTab === BespokeReverseCollectionComponent.SHIFT_REQUESTS &&
                    modalData.shiftRequests && (_jsx("div", { id: BespokeReverseCollectionComponent.SHIFT_REQUESTS, children: _jsx(ShiftClaimTable, { defaultData: modalData.shiftRequests, isRecordDetailModal: true }) })), activeTab === BespokeReverseCollectionComponent.RELEASE_REQUESTS &&
                    modalData.shiftRequests && (_jsx("div", { id: BespokeReverseCollectionComponent.RELEASE_REQUESTS, children: _jsx(ShiftReleaseTable, { defaultData: modalData.shiftRelease, isRecordDetailModal: true }) })), activeTab === BespokeReverseCollectionComponent.SHIFT_PROJECT_TRACKING && (_jsx("div", { id: BespokeReverseCollectionComponent.SHIFT_PROJECT_TRACKING, children: _jsx(ProjectTrackingTable, { shiftRid: recordId, defaultData: modalData.projectTracking }) })), activeTab === BespokeReverseCollectionComponent.USER_PAY_RATE &&
                    (isDefaultRatesTurnedOn ? (_jsx(UserDefaultRateTable, { recordId: recordId })) : (modalData.individualPaySettings && (_jsx("div", { id: BespokeReverseCollectionComponent.USER_PAY_RATE, children: _jsx(UserDetailPayRateTable, { recordId: recordId, individualPaySettings: modalData.individualPaySettings }) })))), activeTab === BespokeReverseCollectionComponent.USER_SCHEDULE && (_jsx("div", { id: BespokeReverseCollectionComponent.USER_SCHEDULE, children: _jsx(ScheduleReverseTable, { referenceCollectionId: referenceCollectionId, referenceRecordId: recordId, collectionComponent: BespokeReverseCollectionComponent.USER_SCHEDULE }) })), activeTab === BespokeReverseCollectionComponent.LOCATION_PAY_RATE && (_jsx("div", { id: BespokeReverseCollectionComponent.LOCATION_PAY_RATE, children: _jsx(LocationDetailPayRateTable, { collectionId: dataSource.collectionId, recordId: recordId }) })), activeTab === BespokeReverseCollectionComponent.LOCATION_SCHEDULE && (_jsx("div", { id: BespokeReverseCollectionComponent.LOCATION_SCHEDULE, children: _jsx(ScheduleReverseTable, { referenceCollectionId: referenceCollectionId, referenceRecordId: recordId, collectionComponent: BespokeReverseCollectionComponent.LOCATION_SCHEDULE }) }))] }));
    }
    if (!reverseCollectionDisplaySettings) {
        return _jsx(_Fragment, {});
    }
    if (reverseCollection.collectionType === CollectionType.TASK) {
        return (_jsx(TasksReverseCollection, { reverseCollection: reverseCollection, referenceCollectionId: referenceCollectionId, referenceRecordId: referenceRecordId }));
    }
    return (_jsxs("div", { className: "ModalBodyCollections", id: (_d = reverseCollection.schemaId) !== null && _d !== void 0 ? _d : reverseCollection.standardRelation, children: [_jsx(ReverseCollectionView, __assign({}, reverseCollection, { referenceCollectionId: referenceCollectionId, referenceRecordId: referenceRecordId })), reverseCollection.standardRelation === 'USER_TIMEOFFS' && (_jsx(UserDetailTimeOffSummary, { recordId: recordId }))] }, (_e = reverseCollection.schemaId) !== null && _e !== void 0 ? _e : reverseCollection.standardRelation));
};
export default React.memo(DetailModalReverseCollection);
