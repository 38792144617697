import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { selectedTaskGroupIdState, selectedTaskRuleIdState, selectedTaskTemplateIdState, } from '../atom';
import { NEW_TASK_GROUP_ID, NEW_TASK_RULE_ID, NEW_TASK_TEMPLATE_ID } from '../constant';
import { TaskTab } from '../interface';
var TasksSettingAddButton = function (_a) {
    var activeTab = _a.activeTab;
    var setSelectedTaskGroupId = useSetRecoilState(selectedTaskGroupIdState);
    var setSelectedTaskRuleId = useSetRecoilState(selectedTaskRuleIdState);
    var setSelectedTaskTemplateId = useSetRecoilState(selectedTaskTemplateIdState);
    return (_jsx("div", { className: "text-white bg-black flex items-center justify-center px-4 !min-w-[48px] !min-h-[48px] !rounded-[24px] absolute bottom-[16px] right-[16px] shadow-card", role: "button", onClick: function () {
            if (activeTab === TaskTab.GROUPS) {
                setSelectedTaskGroupId(NEW_TASK_GROUP_ID);
            }
            else if (activeTab === TaskTab.RULES) {
                setSelectedTaskRuleId(NEW_TASK_RULE_ID);
            }
            else if (activeTab === TaskTab.TEMPLATES) {
                setSelectedTaskTemplateId(NEW_TASK_TEMPLATE_ID);
            }
        }, children: _jsx(PlusOutlined, {}) }));
};
export default React.memo(TasksSettingAddButton);
