import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import './ZiraModal.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
var ZiraModal = function (props) {
    var modalStyle = props.modalStyle, children = props.children, show = props.show, onClose = props.onClose, backdropStyle = props.backdropStyle, wrapperClassNameOverride = props.wrapperClassName, showCloseButton = props.showCloseButton, _a = props.size, size = _a === void 0 ? 'md' : _a;
    var _b = useState(wrapperClassNameOverride !== null && wrapperClassNameOverride !== void 0 ? wrapperClassNameOverride : 'ZiraModalWrapper'), wrapperClassName = _b[0], setWrapperClassName = _b[1];
    useEffect(function () {
        if (wrapperClassNameOverride) {
            setWrapperClassName(wrapperClassNameOverride);
        }
    }, [wrapperClassNameOverride]);
    useEffect(function () {
        if (!wrapperClassNameOverride) {
            if (show) {
                setTimeout(function () { return setWrapperClassName('ZiraModalWrapper visible'); }, 0);
            }
            else {
                setWrapperClassName('ZiraModalWrapper');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    var modalClassName = size === 'lg' ? 'LargeZiraModal' : 'ZiraModal';
    return (_jsx("div", { style: backdropStyle, className: wrapperClassName, onClick: function () {
            onClose();
        }, children: _jsxs("div", { style: modalStyle, className: modalClassName, onClick: function (e) {
                e.stopPropagation();
            }, children: [children, showCloseButton && (_jsx(Button, { className: "HeaderCloseButton", onClick: function () {
                        onClose(false);
                    }, children: _jsx(CloseOutlined, {}) }))] }) }));
};
export default React.memo(ZiraModal);
