import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { OPTIONS_COLOR, OPTIONS_COLOR_NAME } from 'src/shared';
import { getTagTextColor } from 'src/utils/Collection';
var OptionColorPicker = function (_a) {
    var index = _a.index, selectedColor = _a.selectedColor;
    return (_jsx("div", { className: "color-picker-item-wrapper", children: _jsxs(Space, { align: "start", children: [_jsx("div", { className: "color-picker-item-indicator", style: {
                        backgroundColor: OPTIONS_COLOR[index],
                        color: getTagTextColor(OPTIONS_COLOR[index]),
                    } }), _jsx("span", { className: "color-picker-item-text", children: OPTIONS_COLOR_NAME[index] }), selectedColor === OPTIONS_COLOR[index] && _jsx(CheckOutlined, {})] }) }));
};
export default OptionColorPicker;
