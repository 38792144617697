var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import { cloneDeep } from 'lodash';
import { useAccountApi, useScheduleTemplateApi } from 'src/hooks/api';
import { ScheduleGroupBy } from 'src/shared';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { ifUserHasGeneralPermission, scheduleGroupBySelector, selectedShiftTemplateIdState, shiftTemplateDetailModalVisibleState, } from 'src/state';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { useShiftTemplateComponent } from 'src/hooks/component/useShiftTemplate';
import { useTemplateComponent } from 'src/hooks/component';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from '../../CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from '../../CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import './NewShiftTemplateDrawer.scss';
import ShiftTemplateList from './ShiftTemplateList';
import { selectedTemplateIdForDeleteState, shiftTemplateListRespState } from './Atom';
import { shiftTemplateListOrderState } from './Selector';
import TemplateCardDeleteActionModal from './TemplateCardDeleteActionModal';
var NewShiftTemplateDrawer = function (props) {
    var closeDrawer = props.closeDrawer, blockId = props.blockId, collectionId = props.collectionId;
    var groupBy = useRecoilValue(scheduleGroupBySelector(blockId));
    var refetchCollection = useRefetchCollection();
    var isTemplateDeleteActionModalVisible = !!useRecoilValue(selectedTemplateIdForDeleteState);
    var _a = useTemplateComponent(blockId), locationFilter = _a.locationFilter, saveTemplateLocationFilter = _a.saveTemplateLocationFilter;
    var _b = useScheduleTemplateApi(), useDeleteShiftTemplate = _b.useDeleteShiftTemplate, useApplyShiftTemplateListSettings = _b.useApplyShiftTemplateListSettings;
    var refetchShiftTemplates = useShiftTemplateComponent().refetchShiftTemplates;
    var _c = useRecoilState(shiftTemplateListRespState(blockId)), templateListResp = _c[0], setTemplateListResp = _c[1];
    var _d = useState(''), searchQuery = _d[0], setSearchQuery = _d[1];
    // Using separate state for list order for better dnd ui experience
    var templateListOrder = useRecoilValue(shiftTemplateListOrderState(blockId));
    var templateList = useMemo(function () {
        if (!templateListResp)
            return [];
        var list = [];
        templateListOrder.map(function (id) {
            var item = templateListResp.data[id];
            if (item)
                list.push(item);
        });
        return list;
    }, [templateListOrder, templateListResp]);
    var filteredTemplateList = useMemo(function () {
        return templateList.filter(function (template) {
            var _a, _b;
            return (locationFilter &&
                ((_a = template.location) === null || _a === void 0 ? void 0 : _a.rid) &&
                locationFilter.includes((_b = template.location) === null || _b === void 0 ? void 0 : _b.rid)) ||
                template.name.toLowerCase().includes(searchQuery.trim().toLowerCase());
        });
    }, [templateList, locationFilter, searchQuery]);
    var onTemplateCardDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var sourceTemplate = filteredTemplateList[source.index];
        var destinationTemplate = filteredTemplateList[destination.index];
        if (!sourceTemplate || !destinationTemplate)
            return;
        //Using full template order array to keep track of template order
        var templateOrder = cloneDeep(templateListOrder);
        var sourceTemplateIndex = templateOrder.findIndex(function (templateId) { return templateId === sourceTemplate.id; });
        var destinationTemplateIndex = templateOrder.findIndex(function (templateId) { return templateId === destinationTemplate.id; });
        if (sourceTemplateIndex === -1 || destinationTemplateIndex === -1)
            return;
        var reorderedItem = templateOrder.splice(sourceTemplateIndex, 1)[0];
        templateOrder.splice(destinationTemplateIndex, 0, reorderedItem);
        // Set state before sending request for better dnd ui experience
        // will revert to old state if request fails
        if (!templateListResp)
            return;
        var prevResp = cloneDeep(templateListResp);
        var newTemplateListResp = cloneDeep(templateListResp);
        newTemplateListResp.settings = __assign(__assign({}, templateListResp.settings), { order: templateOrder });
        setTemplateListResp(newTemplateListResp);
        useApplyShiftTemplateListSettings.mutate({
            order: templateOrder,
        }, {
            onSuccess: function () {
                // Refetch schedule list when group by template
                if (groupBy === ScheduleGroupBy.TEMPLATE)
                    refetchCollection(collectionId);
            },
            // Revert to prev state if request fails
            onError: function () {
                setTemplateListResp(prevResp);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredTemplateList, templateListOrder, templateListResp]);
    var setIsTemplateDetailModalVisible = useSetRecoilState(shiftTemplateDetailModalVisibleState);
    var setSelectedTemplateId = useSetRecoilState(selectedShiftTemplateIdState);
    var onDeleteTemplate = useCallback(function (templateId) {
        useDeleteShiftTemplate.mutate({ templateId: templateId }, {
            onSuccess: function () {
                refetchShiftTemplates();
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var ifUserHasTemplatePermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.SCHEDULE_TEMPLATE));
    var openExistingTemplate = useCallback(function (templateId) {
        if (!ifUserHasTemplatePermission) {
            return;
        }
        setSelectedTemplateId(templateId);
        setIsTemplateDetailModalVisible(true);
    }, [ifUserHasTemplatePermission, setIsTemplateDetailModalVisible, setSelectedTemplateId]);
    var templateDrawerContainerRef = useRef(null);
    var _e = useState(200), templateDrawerContainerHeight = _e[0], setTemplateDrawerContainerHeight = _e[1];
    var modalHeaderObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            if (entries[0]) {
                setTemplateDrawerContainerHeight(entries[0].contentRect.height);
            }
        });
    }, []);
    useEffect(function () {
        if (templateDrawerContainerRef === null || templateDrawerContainerRef === void 0 ? void 0 : templateDrawerContainerRef.current) {
            modalHeaderObserver.observe(templateDrawerContainerRef.current);
        }
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [modalHeaderObserver]);
    // Location filter
    var useGetObjSimpleList = useAccountApi().useGetObjSimpleList;
    var locationsData = useGetObjSimpleList('locations', {
        body: {},
        options: {
            enabled: true,
        },
    }).data;
    var renderLocations = useCallback(function () {
        if (locationsData && 'locations' in locationsData) {
            return (_jsxs("div", { className: "popover-inner template-filter-popover", children: [_jsx("span", { className: 'filterGroup ' + (locationFilter.length == 0 ? 'checked' : ''), onClick: function () { return saveTemplateLocationFilter([]); }, children: "All Locations" }), locationsData.locations.map(function (location) {
                        return (_jsxs("label", { className: 'filterGroup ' +
                                (locationFilter.includes(location.recordId) ? 'checked' : ''), children: [_jsx("input", { type: "checkbox", checked: locationFilter.includes(location.recordId), onChange: function () {
                                        var index = locationFilter.indexOf(location.recordId);
                                        var tempLocationFilter = cloneDeep(locationFilter);
                                        if (index >= 0) {
                                            tempLocationFilter.splice(index, 1);
                                        }
                                        else {
                                            tempLocationFilter.push(location.recordId);
                                        }
                                        saveTemplateLocationFilter(tempLocationFilter);
                                    } }), location.title] }, location.recordId));
                    })] }));
        }
    }, [locationFilter, locationsData, saveTemplateLocationFilter]);
    return (_jsxs("div", { className: "TemplateDrawerContainer", ref: templateDrawerContainerRef, children: [_jsxs("div", { className: "DrawerTitleWrapper", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "DrawerTitle", children: "Templates" }), _jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.SCHEDULING_TEMPLATE], children: _jsx(HelpIcon, { className: "ml-2 text-black" }) })] }), _jsxs("div", { children: [ifUserHasTemplatePermission && (_jsx(Button, { className: "AddNewTemplateButton", icon: _jsx(PlusOutlined, { style: {
                                        color: 'black',
                                        fontSize: '14px',
                                        stroke: 'black',
                                        strokeWidth: '10px',
                                    } }), onClick: function () { return setIsTemplateDetailModalVisible(true); } })), _jsx(Button, { className: "CloseDrawerBtn", icon: _jsx(CloseOutlined, {}), onClick: closeDrawer })] })] }), _jsx("div", { className: "FiltersWrapper", children: _jsx(Popover, { placement: "bottom", content: renderLocations, trigger: "click", children: _jsx("span", { className: "filterGroup", children: "Filter by Location" }) }) }), _jsx(Input, { className: "mb-3", placeholder: "Search...", value: searchQuery, onChange: function (e) { return setSearchQuery(e.target.value); } }), filteredTemplateList.length === 0 ? (_jsx("div", { className: "text-sm text-gray-300 py-1", children: "No Available Templates" })) : (_jsx(ShiftTemplateList, { templateList: filteredTemplateList, onDeleteTemplate: onDeleteTemplate, openExistingTemplate: openExistingTemplate, listHeight: templateDrawerContainerHeight - 90, onTemplateCardDragEnd: onTemplateCardDragEnd })), isTemplateDeleteActionModalVisible && (_jsx(TemplateCardDeleteActionModal, { onDeleteTemplate: onDeleteTemplate }))] }));
};
export default React.memo(NewShiftTemplateDrawer);
