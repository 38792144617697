import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu } from 'antd';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RollupOutlineIcon } from 'src/utils/icons/DropdownIcons/RollupOutlined';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { ButtonSchemaOutlinedIcon } from 'src/utils/icons/DropdownIcons/ButtonSchemaOutlined';
import { SparkleOutlinedIcon } from 'src/utils/icons/DropdownIcons/SparkleOutlined';
import { CurrencyOutlineIcon } from 'src/utils/icons/DropdownIcons/CurrencyOutlined';
import { RatingIcon } from 'src/utils/icons/DropdownIcons/RatingIcon';
import { PhoneOutlinedIcon } from 'src/utils/icons/DropdownIcons/PhoneOutlined';
import { TextOutlinedIcon } from '../../../utils/icons/DropdownIcons/TextOutlined';
import { NumberlinedIcon } from '../../../utils/icons/DropdownIcons/NumberOutlined';
import { SingleSelectOutlinedIcon } from '../../../utils/icons/DropdownIcons/SingleSelectOutlined';
import { MultiSelectOutlinedIcon } from '../../../utils/icons/DropdownIcons/MultiSelectOutlined';
import { FileOutlinedIcon } from '../../../utils/icons/DropdownIcons/FileOutlined';
import { DateOutlinedIcon } from '../../../utils/icons/DropdownIcons/DateOutlined';
import { SignatureOutlinedIcon } from '../../../utils/icons/DropdownIcons/SignatureOutlined';
import { LinkOutlinedIcon } from '../../../utils/icons/DropdownIcons/LinkOutlined';
import { FormulaOutlinedIcon } from '../../../utils/icons/DropdownIcons/FormulaOutlined';
import MenuGroupTitle from '../DropdownMenu/MenuGroupTitle';
import MenuTitleWithIcon from '../DropdownMenu/MenuTitleWithIcon';
import { ENABLE_CUSTOM_PHONE_NUMBER_SCHEMA, GENERATED_SCHEMA_MODAL, RATING_SCHEMA, ROLLUP_SCHEMA_MODAL, } from '../../FeatureGatingKeyConstant';
var SchemaTypeMenuItemGroups = function (_a) {
    var onSchemaConfigModalOpen = _a.onSchemaConfigModalOpen;
    var isRollupSchemaEnabled = useRecoilValue(featureEnableStatusState(ROLLUP_SCHEMA_MODAL));
    var isGeneratedSchemaEnabled = useRecoilValue(featureEnableStatusState(GENERATED_SCHEMA_MODAL));
    var isRatingSchemaEnabled = useRecoilValue(featureEnableStatusState(RATING_SCHEMA));
    var enableCustomPhoneNumberSchema = useRecoilValue(featureEnableStatusState(ENABLE_CUSTOM_PHONE_NUMBER_SCHEMA));
    var standardGroup = useMemo(function () {
        var items = [
            {
                key: 'text',
                icon: _jsx(TextOutlinedIcon, {}),
                text: 'Text',
            },
            {
                key: 'number',
                icon: _jsx(NumberlinedIcon, {}),
                text: 'Number',
            },
            {
                key: 'single',
                icon: _jsx(SingleSelectOutlinedIcon, {}),
                text: 'Single Select',
            },
            {
                key: 'multi',
                icon: _jsx(MultiSelectOutlinedIcon, {}),
                text: 'Multi Select',
            },
            {
                key: 'file',
                icon: _jsx(FileOutlinedIcon, {}),
                text: 'File',
            },
            {
                key: 'datetime',
                icon: _jsx(DateOutlinedIcon, {}),
                text: 'Date',
                onClick: onSchemaConfigModalOpen,
            },
        ];
        if (isRatingSchemaEnabled) {
            items.push({
                key: 'rating',
                icon: _jsx(RatingIcon, {}),
                text: 'Rating',
                onClick: onSchemaConfigModalOpen,
            });
        }
        if (enableCustomPhoneNumberSchema) {
            items.push({
                key: 'phone',
                icon: _jsx(PhoneOutlinedIcon, {}),
                text: 'Phone',
            });
        }
        return items;
    }, [isRatingSchemaEnabled, enableCustomPhoneNumberSchema, onSchemaConfigModalOpen]);
    var ziraDefinedGroup = [
        {
            key: 'signature',
            icon: _jsx(SignatureOutlinedIcon, {}),
            text: 'Signature',
        },
        {
            key: 'currency',
            icon: _jsx(CurrencyOutlineIcon, {}),
            text: 'Currency',
        },
        {
            key: 'button',
            icon: _jsx(ButtonSchemaOutlinedIcon, {}),
            text: 'Button',
            onClick: onSchemaConfigModalOpen,
        },
    ];
    var advancedItems = useMemo(function () {
        var items = [
            {
                key: 'linkToObj',
                icon: _jsx(LinkOutlinedIcon, {}),
                text: 'Link to object',
                onClick: onSchemaConfigModalOpen,
            },
            {
                key: 'linkToSchema',
                icon: _jsx(LinkOutlinedIcon, {}),
                text: 'Link to select column',
                onClick: onSchemaConfigModalOpen,
            },
            {
                key: 'formula',
                icon: _jsx(FormulaOutlinedIcon, {}),
                text: 'Formula',
            },
        ];
        if (isRollupSchemaEnabled) {
            items.push({
                key: 'rollup',
                icon: (_jsx("span", { className: "px-[2.25px] py-[0.5px]", children: _jsx(RollupOutlineIcon, {}) })),
                text: 'Rollup',
                onClick: onSchemaConfigModalOpen,
            });
        }
        if (isGeneratedSchemaEnabled) {
            items.push({
                key: 'generative',
                icon: _jsx(SparkleOutlinedIcon, { className: "px-[2px] pt-[2px]" }),
                text: 'Generated',
                onClick: onSchemaConfigModalOpen,
            });
        }
        return items;
    }, [isRollupSchemaEnabled, isGeneratedSchemaEnabled, onSchemaConfigModalOpen]);
    var itemGroups = [
        {
            title: _jsx(MenuGroupTitle, { text: "Standard" }),
            key: 'standard',
            items: standardGroup,
        },
        {
            title: _jsx(MenuGroupTitle, { text: "Teambridge Defined" }),
            key: 'teambridgeDefined',
            items: ziraDefinedGroup,
        },
        {
            title: _jsx(MenuGroupTitle, { text: "Advanced" }),
            key: 'advanced',
            items: advancedItems,
        },
    ];
    return (_jsx(_Fragment, { children: itemGroups.map(function (itemGroup) {
            return (_jsx(Menu.ItemGroup, { title: itemGroup.title, children: itemGroup.items.map(function (menuItem) { return (_jsx(Menu.Item, { onClick: menuItem.onClick, children: _jsx(MenuTitleWithIcon, { icon: menuItem.icon, text: menuItem.text }) }, menuItem.key)); }) }, itemGroup.key));
        }) }));
};
export default SchemaTypeMenuItemGroups;
