var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Input } from 'antd';
import Button from 'antd/lib/button';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentPageImgSelector, currentPageDescriptionsSelector, currentPageNameState, currentPageIdSelector, currentPageIconSelector, canEditPageSelector, } from 'src/state';
import { useNaviTreeComponent, usePage, useWindowSize } from 'src/hooks/component';
import { useFileApi } from 'src/hooks/api/useFileApi';
import { TEMP_NODE_TITLE } from '../../shared';
import { NaviBarAddButton } from '../../utils/icons/NaviBarAddButton';
import './PageHeaderSection.scss';
import ComponentWithUpload, { UploadFileType } from '../ComponentWithUpload';
import IconSelectorPopover from '../IconSelector/IconSelectorPopover';
var AcceptedFileType = [UploadFileType.IMAGE];
var PageHeaderSection = function (_a) {
    var fullTablePageHeader = _a.fullTablePageHeader;
    var canEditPage = useRecoilValue(canEditPageSelector);
    var pageDescription = useRecoilValue(currentPageDescriptionsSelector);
    var _b = useState(false), showDescription = _b[0], setShowDescription = _b[1];
    useEffect(function () {
        setShowDescription(pageDescription ? true : false);
    }, [pageDescription]);
    var descriptionAreaOnChange = useCallback(function (isShow) { return setShowDescription(isShow); }, []);
    if (fullTablePageHeader) {
        // For full table/schedule page
        return (_jsxs("div", { className: "PageInfo flex items-center", children: [_jsx(PageEmoji, { size: 24 }), _jsx("div", { className: "pl-[4px] pt-[3px]", children: _jsx(PageInput, { fontSize: 24 }) })] }));
    }
    return (_jsxs("div", { className: "PageInfo", children: [_jsx(PageCover, { canEditPage: canEditPage }), _jsxs("div", { className: "PageInfoContent", children: [_jsx("div", { className: "uploadBar", children: canEditPage && (_jsxs(_Fragment, { children: [_jsx(ChangePageEmojiBtn, {}), _jsx(UploadPageCoverBtn, {}), _jsx(AddPageDescriptionBtn, { descriptionAreaOnChange: descriptionAreaOnChange })] })) }), _jsxs("div", { className: "pageEmojiInputWrapper", children: [_jsx(PageEmoji, {}), _jsx(PageInput, {})] })] }), showDescription && _jsx(PageDescriptionArea, {})] }));
};
var AddPropertyButton = function (_a) {
    var text = _a.text;
    return (_jsx(Button, { className: "PageAddCoverButton", icon: _jsx(NaviBarAddButton, {}), children: _jsx("span", { className: "AddCoverDescription", children: text }) }));
};
var UploadPageCoverBtn = function () {
    var _a = useRecoilState(currentPageImgSelector), pageImage = _a[0], setPageImage = _a[1];
    var pageId = useRecoilValue(currentPageIdSelector);
    var updatePageCoverImage = usePage(pageId).updatePageCoverImage;
    var onUploadSucceed = useCallback(function (info) {
        var url = info.file.response.data.url;
        updatePageCoverImage(url);
        setPageImage(url);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return !pageImage ? (_jsx("span", { className: "uploadItem", children: _jsx(ComponentWithUpload, { acceptedFileType: AcceptedFileType, successAction: onUploadSucceed, type: UploadUrlType.Page, children: _jsx(AddPropertyButton, { text: "Add Cover" }) }) })) : (_jsx(_Fragment, { children: " " }));
};
var PageCover = function (_a) {
    var canEditPage = _a.canEditPage;
    var pageId = useRecoilValue(currentPageIdSelector);
    var _b = useRecoilState(currentPageImgSelector), currentPageImg = _b[0], setCurrentPageImg = _b[1];
    var updatePageCoverImage = usePage(pageId).updatePageCoverImage;
    var handleDeleteFile = useFileApi().deleteFile;
    var onChangeCover = useCallback(function (info) { return __awaiter(void 0, void 0, void 0, function () {
        var oldImg, newUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    oldImg = currentPageImg;
                    newUrl = info.file.response.data.url;
                    updatePageCoverImage(newUrl);
                    if (!oldImg) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleDeleteFile(oldImg)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    setCurrentPageImg(newUrl);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageImg, handleDeleteFile]);
    var changeCoverButton = useMemo(function () {
        return (_jsx(Button, { className: "PageChangeCoverButton", children: _jsx("span", { className: "AddCoverDescription", children: "Change cover" }) }));
    }, []);
    var onDeletePageCover = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatePageCoverImage('');
                    if (!currentPageImg) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleDeleteFile(currentPageImg)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    setCurrentPageImg(undefined);
                    return [2 /*return*/];
            }
        });
    }); }, [currentPageImg, handleDeleteFile]);
    return (_jsx("div", { className: "PageAddCover", children: currentPageImg ? (_jsxs("div", { className: "PageCoverBlock", children: [_jsx("img", { className: "PageCoverImage", src: currentPageImg }), canEditPage && (_jsxs("div", { className: "PageCoverButtonRow", children: [_jsx(ComponentWithUpload, { acceptedFileType: AcceptedFileType, successAction: onChangeCover, type: UploadUrlType.Page, children: changeCoverButton }), _jsx(Button, { className: "PageChangeCoverButton", onClick: onDeletePageCover, children: _jsx("span", { className: "AddCoverDescription", children: "Remove" }) })] }))] })) : (_jsx(_Fragment, {})) }));
};
var pageEmojiPickerOpenState = atom({
    key: 'pageEmojiPickerOpenState',
    default: false,
});
var ChangePageEmojiBtn = function () {
    var setEmojiPickerOpen = useSetRecoilState(pageEmojiPickerOpenState);
    return (_jsx("span", { className: "uploadItem", onClick: function () {
            setEmojiPickerOpen(true);
        }, children: _jsx(AddPropertyButton, { text: 'Change icon' }) }));
};
var PageEmoji = function (_a) {
    var size = _a.size;
    var pageId = useRecoilValue(currentPageIdSelector);
    var pageIcon = useRecoilValue(currentPageIconSelector);
    var _b = useRecoilState(pageEmojiPickerOpenState), iconSelectorVisible = _b[0], setIconSelectorVisible = _b[1];
    var _c = useNaviTreeComponent(), saveEmoji = _c.saveEmoji, saveIcon = _c.saveIcon;
    var onSaveIcon = useCallback(function (newIcon) {
        if (!pageId)
            return;
        saveIcon(pageId, 'page', newIcon);
    }, [pageId, saveIcon]);
    var onSaveEmoji = useCallback(function (newEmoji) {
        if (!pageId)
            return;
        saveEmoji(pageId, 'page', newEmoji);
    }, [pageId, saveEmoji]);
    var _d = useWindowSize().windowWidth, windowWidth = _d === void 0 ? 0 : _d;
    var isMobileView = windowWidth <= 576;
    var canUpdatePageHeaderInfo = useRecoilValue(canEditPageSelector);
    return (_jsx(IconSelectorPopover, { currentIcon: pageIcon !== null && pageIcon !== void 0 ? pageIcon : '', selectorVisible: canUpdatePageHeaderInfo ? iconSelectorVisible : false, onSelectorVisibleChange: setIconSelectorVisible, onSaveIcon: onSaveIcon, onSaveEmoji: onSaveEmoji, iconSize: size !== null && size !== void 0 ? size : (isMobileView ? 30 : 40) }));
};
var PageInput = function (_a) {
    var fontSize = _a.fontSize;
    var pageId = useRecoilValue(currentPageIdSelector);
    var pageName = useRecoilValue(currentPageNameState);
    var _b = useState(undefined), newTitle = _b[0], setNewTitle = _b[1];
    var _c = useState(false), isEditing = _c[0], setIsEditing = _c[1];
    useEffect(function () {
        setIsEditing(false);
        var ele = document.getElementById('title');
        ele === null || ele === void 0 ? void 0 : ele.blur();
    }, [pageId]);
    var updatePageTitle = usePage(pageId).updatePageTitle;
    var onChangeInput = useCallback(function (e) {
        setIsEditing(true);
        setNewTitle(e.target.value);
        updatePageTitle(e.target.value, false);
    }, [updatePageTitle]);
    var onSaveInput = useCallback(function () {
        if (isEditing) {
            updatePageTitle(newTitle || TEMP_NODE_TITLE, true);
        }
        setIsEditing(false);
    }, [isEditing, newTitle, updatePageTitle]);
    var canUpdatePageHeaderInfo = useRecoilValue(canEditPageSelector);
    return (_jsx(Input, { className: "PageInput", style: fontSize ? { fontSize: fontSize } : undefined, id: "title", placeholder: pageName || TEMP_NODE_TITLE, value: isEditing ? newTitle : pageName, bordered: false, size: "large", onChange: onChangeInput, onBlur: onSaveInput, onPressEnter: function (e) { return e.currentTarget.blur(); }, disabled: !canUpdatePageHeaderInfo }));
};
var AddPageDescriptionBtn = function (_a) {
    var descriptionAreaOnChange = _a.descriptionAreaOnChange;
    var pageDescription = useRecoilValue(currentPageDescriptionsSelector);
    return !pageDescription ? (_jsx("span", { className: "uploadItem", onClick: function () { return descriptionAreaOnChange(true); }, children: _jsx(AddPropertyButton, { text: "Add Description" }) })) : (_jsx(_Fragment, { children: " " }));
};
var PageDescriptionArea = function () {
    var pageDescription = useRecoilValue(currentPageDescriptionsSelector);
    var _a = useState(pageDescription), newDescription = _a[0], setNewDescription = _a[1];
    var pageId = useRecoilValue(currentPageIdSelector);
    var updatePageDescription = usePage(pageId).updatePageDescription;
    var canUpdatePageHeaderInfo = useRecoilValue(canEditPageSelector);
    return (_jsx(TextArea, { className: "PageInputDescription", id: "description", placeholder: 'Write a description..', defaultValue: newDescription, bordered: false, autoSize: true, onChange: function (e) { return setNewDescription(e.currentTarget.value); }, onBlur: function (e) { return updatePageDescription(e.currentTarget.value); }, onPressEnter: function (e) { return e.currentTarget.blur(); }, disabled: !canUpdatePageHeaderInfo }));
};
export default React.memo(PageHeaderSection);
