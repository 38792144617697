import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, memo, cloneElement, Children, useEffect, useMemo, useCallback } from 'react';
import { Modal } from 'antd';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
import LocalizationIcon from 'src/utils/icons/images/LocalizationIcon.svg';
import './TranslationEditorWrapper.scss';
import { useLocalizationApi } from 'src/hooks/api/useLocalizationApi';
import { useSetting } from 'src/hooks/api';
import { contentTypeTitleMap } from './interface';
import TranslationList from './TranslationList';
import LanguageListDropdown from './LanguageListDropdown';
import AutoTranslateBanner from './AutoTranslateBanner';
var TranslationEditorWrapper = function (_a) {
    var children = _a.children, titleString = _a.titleString, _b = _a.zIndex, zIndex = _b === void 0 ? 1200 : _b, contentType = _a.contentType, contentId = _a.contentId, setIsLocalizationModalVisible = _a.setIsLocalizationModalVisible, contentIndex = _a.contentIndex, upperComponentActions = _a.upperComponentActions;
    var _c = useState(false), hasFetched = _c[0], setHasFetched = _c[1];
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var _e = useState(), translationModalViewData = _e[0], setTranslationModalViewData = _e[1];
    var _f = useLocalizationApi(), useGetTranslationModalView = _f.useGetTranslationModalView, useUpdateTranslationValues = _f.useUpdateTranslationValues;
    var refetch = useGetTranslationModalView({
        body: {
            contentId: contentId,
            contentType: contentType,
            contentIndex: contentIndex,
        },
        options: {
            enabled: isOpen && !hasFetched,
            onSuccess: function (data) {
                setTranslationModalViewData(data);
                setHasFetched(true);
            },
        },
    }).refetch;
    useEffect(function () {
        if (isOpen) {
            refetch();
        }
    }, [titleString, isOpen, refetch]);
    var useGetLocalizationSettings = useSetting().useGetLocalizationSettings;
    var fetchLocalizationSettingsData = useGetLocalizationSettings().data;
    var _g = useState(false), aiTranslationEnabled = _g[0], setAiTranslationEnabled = _g[1];
    useMemo(function () {
        if (fetchLocalizationSettingsData === null || fetchLocalizationSettingsData === void 0 ? void 0 : fetchLocalizationSettingsData.aiTranslation) {
            setAiTranslationEnabled(fetchLocalizationSettingsData === null || fetchLocalizationSettingsData === void 0 ? void 0 : fetchLocalizationSettingsData.aiTranslation);
        }
    }, [fetchLocalizationSettingsData]);
    var childElement = cloneElement(Children.only(children), { onClick: function () { return setIsOpen(true); } });
    var modalTitle = "".concat(titleString, " / ").concat(contentTypeTitleMap[contentType], " Name / Translation");
    var onSave = useCallback(function () {
        var _a, _b, _c;
        if (translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.translationList) {
            useUpdateTranslationValues.mutate({
                contentId: contentId,
                translations: translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.translationList,
                contentType: contentType,
                contentIndex: contentIndex,
            });
            (_a = upperComponentActions === null || upperComponentActions === void 0 ? void 0 : upperComponentActions.onCloseUpperComponent) === null || _a === void 0 ? void 0 : _a.call(upperComponentActions);
            var newTitle = (_b = translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.translationList.find(function (translation) { return translation.languageCode === 'en'; })) === null || _b === void 0 ? void 0 : _b.name;
            if (newTitle) {
                (_c = upperComponentActions === null || upperComponentActions === void 0 ? void 0 : upperComponentActions.onUpdateUpperTitle) === null || _c === void 0 ? void 0 : _c.call(upperComponentActions, newTitle);
            }
            setIsOpen(false);
            if (setIsLocalizationModalVisible) {
                setIsLocalizationModalVisible(false);
            }
        }
    }, [
        contentId,
        contentIndex,
        contentType,
        setIsLocalizationModalVisible,
        translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.translationList,
        upperComponentActions,
        useUpdateTranslationValues,
    ]);
    return (_jsxs(_Fragment, { children: [childElement, isOpen && (_jsxs(Modal, { width: 500, footer: _jsx(ZiraModalFooter, { onCancel: function () {
                        setIsOpen(false);
                        if (setIsLocalizationModalVisible) {
                            setIsLocalizationModalVisible(false);
                        }
                    }, onSave: onSave }), zIndex: zIndex, className: "TranslationEditorModal", open: isOpen, title: _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("img", { src: LocalizationIcon }), _jsx("span", { className: "text-[16px] font-medium", children: modalTitle })] }), centered: true, closable: false, children: [_jsx(AutoTranslateBanner, { aiTranslationEnabled: aiTranslationEnabled }), _jsx(TranslationList, { translationModalViewData: translationModalViewData, setTranslationModalViewData: setTranslationModalViewData, contentType: contentType, aiTranslationEnabled: aiTranslationEnabled, titleString: titleString || '' }), _jsx(LanguageListDropdown, { translationModalViewData: translationModalViewData, setTranslationModalViewData: setTranslationModalViewData })] }))] }));
};
export default memo(TranslationEditorWrapper);
