var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from 'antd';
import { useCallback } from 'react';
var AiTranslationSetting = function (_a) {
    var localizationSettings = _a.localizationSettings, setLocalizationSettings = _a.setLocalizationSettings;
    var onToggleAiTranslation = useCallback(function () {
        if (localizationSettings) {
            setLocalizationSettings(__assign(__assign({}, localizationSettings), { aiTranslation: !localizationSettings.aiTranslation }));
        }
    }, [localizationSettings, setLocalizationSettings]);
    return (_jsx("div", { className: "GradientWrapper Checked mt-8 w-[32%]", children: _jsxs("div", { className: "flex flex-col SmartSwitch bg-white w-full py-2 px-3", children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("div", { className: "font-semibold text-[15px] mt-3", children: "AI Localization" }), _jsx(Switch, { className: "mt-auto", checked: localizationSettings === null || localizationSettings === void 0 ? void 0 : localizationSettings.aiTranslation, onChange: onToggleAiTranslation })] }), _jsx("div", { className: "text-sm font-medium mt-2 mb-4 text-gray-400", children: "Use A.I. to automatically translate all custom values, workspace labels, and column names." })] }) }));
};
export default AiTranslationSetting;
