import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Tag } from 'antd';
import { getDisplayValue } from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/util';
import ZiraTooltip from 'src/ui/ZiraTooltip';
var HolidayPayPreviewTag = function (_a) {
    var cellData = _a.cellData, multiplier = _a.multiplier;
    if (!('amount' in cellData)) {
        return null;
    }
    var symbol = cellData.amount.symbol || '$';
    var value = getDisplayValue(cellData, function () { return '0'; });
    if (!Number.isNaN(value)) {
        return (_jsx(ZiraTooltip, { title: "This is the rate that will be used to calculate totals for the shift.", children: _jsxs(Tag, { color: "green", children: [Number(Number.parseFloat(value) * multiplier).toLocaleString(undefined, {
                        style: 'currency',
                        currency: symbol,
                        minimumFractionDigits: 2,
                    }), ' ', "(", multiplier, "x)"] }) }));
    }
    else {
        return null;
    }
};
export default HolidayPayPreviewTag;
