var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'antd';
import React, { useState } from 'react';
import { useLocalizationApi } from 'src/hooks/api/useLocalizationApi';
import refreshTranslation from 'src/utils/icons/RefreshTranslationIcon.svg';
import { contentTypeTitleMap, } from './interface';
var TranslationList = function (_a) {
    var translationModalViewData = _a.translationModalViewData, contentType = _a.contentType, setTranslationModalViewData = _a.setTranslationModalViewData, aiTranslationEnabled = _a.aiTranslationEnabled, titleString = _a.titleString;
    var useGenerateTranslation = useLocalizationApi().useGenerateTranslation;
    var _b = useState(aiTranslationEnabled), shouldShowRefresh = _b[0], setShouldShowRefresh = _b[1];
    var _c = useState(titleString), stringToTranslate = _c[0], setStringToTranslate = _c[1];
    var mutate = useGenerateTranslation().mutate;
    var onChangeTranslation = function (e, item) {
        if (translationModalViewData) {
            setTranslationModalViewData(__assign(__assign({}, translationModalViewData), { translationList: translationModalViewData.translationList.map(function (translation) {
                    if (translation.languageCode === item.languageCode) {
                        return __assign(__assign({}, translation), { name: e.target.value });
                    }
                    return translation;
                }) }));
            if (item.languageCode === 'en') {
                setStringToTranslate(e.target.value);
            }
        }
    };
    return (_jsxs("div", { className: "relative w-full ml-2 mt-5 mr-2 mb-5", children: [_jsx("div", { className: "text-xs font-semibold text-gray-400", children: "".concat(contentTypeTitleMap[contentType], " Name") }), translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.translationList.map(function (item) {
                return (_jsx(Input, { suffix: shouldShowRefresh && item.languageCode !== 'en' ? (_jsx("img", { alt: "refresh", src: refreshTranslation, onClick: function () {
                            setShouldShowRefresh(false);
                            mutate({
                                text: stringToTranslate,
                                languageCode: item.languageCode,
                            }, {
                                onSuccess: function (data) {
                                    setShouldShowRefresh(false);
                                    if (translationModalViewData && data) {
                                        setTranslationModalViewData(__assign(__assign({}, translationModalViewData), { translationList: translationModalViewData.translationList.map(function (translation) {
                                                if (translation.languageCode ===
                                                    data.languageCode) {
                                                    return __assign(__assign({}, translation), { name: data.text ||
                                                            titleString });
                                                }
                                                return translation;
                                            }) }));
                                    }
                                },
                            });
                        } })) : (_jsx("div", { children: item.languageName })), className: "bg-gray-50 hover:bg-gray-50 focus:bg-gray-50 mt-4", width: 200, height: 300, value: item.name, bordered: false, onChange: function (e) {
                        onChangeTranslation(e, item);
                    } }, item.languageCode));
            })] }));
};
export default React.memo(TranslationList);
