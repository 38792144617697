var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { sortBy, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DragHandlerIcon } from 'src/utils/icons/DragHandlerIcon';
var LinkedDataTemplateDraggableFormFields = function (_a) {
    var form = _a.form, name = _a.name, fields = _a.fields, ops = _a.ops, schemaOptions = _a.schemaOptions;
    var allSchemaOptions = useMemo(function () {
        return sortBy(uniqBy(schemaOptions.reduce(function (all, schema) {
            return __spreadArray(__spreadArray(__spreadArray([], all, true), [
                {
                    name: schema.name,
                    id: schema.id,
                    collectionName: schema.collectionName,
                }
            ], false), schema.secondarySchemas.map(function (secondary) { return ({
                name: "".concat(schema.name, " / ").concat(secondary.name),
                id: schema.id + '&' + secondary.id,
                collectionName: schema.collectionName,
            }); }), true);
        }, []), 'id'), 'name');
    }, [schemaOptions]);
    return (_jsx(Droppable, { droppableId: name, children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { className: "space-y-3", children: [fields.map(function (field, index) { return (_jsx(Draggable, { draggableId: "".concat(name, "-").concat(field.key, "-").concat(index), index: index, children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.draggableProps, { className: "flex items-center space-x-2", children: [_jsx("div", __assign({}, provided.dragHandleProps, { className: "flex items-center", children: _jsx(DragHandlerIcon, { className: "text-gray-400" }) })), _jsxs("div", { className: "flex grow space-x-3", children: [_createElement(Form.Item, __assign({}, field, { key: field.key + field.name, name: [field.name, 'label'], className: "mb-0 w-1/2", rules: name === 'tableContents'
                                            ? [
                                                {
                                                    required: true,
                                                    message: 'Please enter column name',
                                                },
                                            ]
                                            : undefined }),
                                        _jsx(Input, { placeholder: name === 'tableContents'
                                                ? 'Column Name'
                                                : 'Label (optional)' })), _createElement(Form.Item, __assign({}, field, { key: "".concat(field.key, "-").concat(field.name, "-selector"), name: [field.name, 'value'], className: "mb-0 w-1/2", rules: [
                                            {
                                                required: name === 'tableContents' ||
                                                    form.getFieldValue(name)[index].label !==
                                                        '',
                                                message: 'Please select a value or remove this row',
                                            },
                                        ] }),
                                        _jsx(Select, { showSearch: true, autoClearSearchValue: true, allowClear: true, popupClassName: "!min-w-[380px]", filterOption: function (input, option) {
                                                var _a;
                                                var text = ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : [])
                                                    .map(function (child) {
                                                    var _a, _b;
                                                    return (_b = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.children) !== null && _b !== void 0 ? _b : child;
                                                })
                                                    .join('')
                                                    .replace(' / ', ' ');
                                                return text === null || text === void 0 ? void 0 : text.toLowerCase().includes(input.trim().toLowerCase());
                                            }, children: allSchemaOptions.map(function (schema) { return (_jsxs(Select.Option, { value: schema.id, children: [_jsx("span", { children: schema.name }), ' ', _jsx("span", { className: "text-xs text-gray-400", children: schema.collectionName })] }, "".concat(name, "-").concat(schema.name, "-").concat(schema.id))); }) }))] }), _jsx("div", { role: "button", onClick: function () { return ops.remove(field.name); }, className: "flex h-[30px] w-[30px] items-center justify-center rounded border border-[#d9d9d9] rounded-md", children: _jsx(DeleteOutlined, {}) })] }), "inner-div-".concat(name, "-").concat(field.key, "-").concat(index))); } }, "".concat(name, "-").concat(field.key, "-").concat(index))); }), provided.placeholder, _jsxs("div", { role: "button", onClick: function () { return ops.add({ label: '', value: undefined }); }, className: "inline-flex h-[31px] w-[122px] items-center space-x-1 rounded border border-[#d9d9d9] rounded-md px-2 py-1.5", children: [_jsx(PlusOutlined, { className: "text-sm" }), _jsx("span", { className: "text-sm font-semibold leading-[19px]", children: name === 'tableContents' ? 'Add Column' : 'Add Content' })] })] }))); } }));
};
export default LinkedDataTemplateDraggableFormFields;
