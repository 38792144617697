var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
var LanguageListDropdown = function (_a) {
    var _b;
    var translationModalViewData = _a.translationModalViewData, setTranslationModalViewData = _a.setTranslationModalViewData;
    var onClick = useCallback(function (option) {
        if (translationModalViewData) {
            setTranslationModalViewData(__assign(__assign({}, translationModalViewData), { translationList: __spreadArray(__spreadArray([], translationModalViewData.translationList, true), [
                    {
                        languageCode: option.languageCode,
                        name: '',
                        languageName: option.languageName,
                    },
                ], false), languageList: translationModalViewData.languageList.filter(function (language) { return language.languageCode !== option.languageCode; }) }));
        }
    }, [translationModalViewData, setTranslationModalViewData]);
    var dropdownMenuItems = useMemo(function () {
        var _a;
        return (_a = translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.languageList) === null || _a === void 0 ? void 0 : _a.map(function (option) { return ({
            key: option.languageCode,
            label: option.languageName,
            onClick: function () { return onClick(option); },
        }); });
    }, [onClick, translationModalViewData]);
    var disabled = ((_b = translationModalViewData === null || translationModalViewData === void 0 ? void 0 : translationModalViewData.languageList) === null || _b === void 0 ? void 0 : _b.length) === 0;
    var buttonClass = "flex items-center text-black border border-[#e9e9ec] rounded-[18px] mt-3 font-semibold ".concat(disabled ? 'text-[#c0c0c0] border-[#d3d3d3] cursor-not-allowed opacity-50' : '');
    var iconClass = "text-black text-sm mb-[2px] stroke-2 ".concat(disabled ? 'stroke-[#c0c0c0]' : 'stroke-black');
    return (_jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['click'], overlayClassName: "z-1300", children: _jsx(Button, { type: "text", className: buttonClass, disabled: disabled, icon: _jsx(PlusOutlined, { className: iconClass }), children: "Add Language" }) }));
};
export default React.memo(LanguageListDropdown);
