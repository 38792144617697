var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { usePageApi } from 'src/hooks/api';
import { LinkedDataReportDateType, } from 'src/services/Interfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import { toast } from 'react-toastify';
import { ExportPdfTemplateType, PayReportExportPdfType, PayReportExportTemplateType, PayReportExportType, } from '../../Constant';
import { mapLinkedDataFormFieldValuesToTemplate, mapLinkedDataTableColumnValuesToTemplate, mapLinkedDataTemplateToFormFieldValues, mapLinkedDataTemplateToTableColumnValues, } from '../utils';
export var usePdfExportTemplateConfigModal = function (props) {
    var _a, _b, _c;
    var onCloseModal = props.onCloseModal, existingTemplateData = props.existingTemplateData;
    var _d = usePageApi(), useGetTemplateReportSchemasAndMetrics = _d.useGetTemplateReportSchemasAndMetrics, useCreateReportExportTemplate = _d.useCreateReportExportTemplate, useUpdateReportExportTemplate = _d.useUpdateReportExportTemplate, useDeleteReportExportTemplate = _d.useDeleteReportExportTemplate;
    var _e = useState([]), shifTableSchemas = _e[0], setShiftTableSchemas = _e[1];
    var _f = useState([]), shiftTableMetrics = _f[0], setShiftTableMetrics = _f[1];
    var _g = useState((_a = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showShiftTable) !== null && _a !== void 0 ? _a : false), showSchemasAndMetricsCheckBoxGroup = _g[0], setShowSchemasAndMetricsCheckBoxGroup = _g[1];
    var _h = useState((_b = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.pdfTemplateType) !== null && _b !== void 0 ? _b : ExportPdfTemplateType.DEFAULT), pdfTemplateType = _h[0], setPdfTemplateType = _h[1];
    var _j = useState((_c = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.summaryOnly) !== null && _c !== void 0 ? _c : false), isLocationSummaryOnly = _j[0], setIsLocationSummaryOnly = _j[1];
    var isDefaultTemplate = pdfTemplateType === ExportPdfTemplateType.DEFAULT;
    var isShiftDetailTemplate = pdfTemplateType === ExportPdfTemplateType.SHIFT_DETAIL;
    var isLocationInvoiceTemplate = pdfTemplateType === ExportPdfTemplateType.LOCATION_INVOICE;
    var isLocationSummaryTemplate = isLocationInvoiceTemplate && isLocationSummaryOnly;
    var isLinkedDataTemplate = pdfTemplateType === ExportPdfTemplateType.LINKED_DATA;
    var form = Form.useForm()[0];
    useEffect(function () {
        if (!existingTemplateData) {
            useGetTemplateReportSchemasAndMetrics.mutate(PayReportExportType.ALL_SHIFT_DETAIL, {
                onSuccess: function (data) {
                    if (data) {
                        var schemas_1 = [];
                        data.schemas.forEach(function (schema) {
                            schemas_1.push({
                                value: schema.id,
                                label: schema.name,
                            });
                        });
                        setShiftTableSchemas(schemas_1);
                        var metrics_1 = [];
                        data.metrics.forEach(function (metric) {
                            metrics_1.push({
                                value: metric.metric,
                                label: metric.displayName,
                            });
                        });
                        setShiftTableMetrics(metrics_1);
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingTemplateData]);
    useEffect(function () {
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftTableSchemas) {
            var schemas_2 = [];
            existingTemplateData.shiftTableSchemas.forEach(function (schema) {
                schemas_2.push({
                    value: schema.id,
                    label: schema.name,
                });
            });
            setShiftTableSchemas(schemas_2);
        }
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftMetrics) {
            var metrics_2 = [];
            existingTemplateData.shiftMetrics.forEach(function (metric) {
                metrics_2.push({
                    value: metric.metric,
                    label: metric.displayName,
                });
            });
            setShiftTableMetrics(metrics_2);
        }
    }, [existingTemplateData]);
    var templateFormInitialValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        var schemas = [];
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftTableSchemas) {
            existingTemplateData.shiftTableSchemas.forEach(function (schema) {
                if (schema.showInReport) {
                    schemas.push(schema.id);
                }
            });
        }
        var metrics = [];
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftMetrics) {
            existingTemplateData.shiftMetrics.forEach(function (metric) {
                if (metric.showInReport) {
                    metrics.push(metric.metric);
                }
            });
        }
        return {
            templateName: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.name,
            templateType: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.pdfTemplateType,
            groupByType: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.exportType,
            accountLogo: (_a = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showAccountLogo) !== null && _a !== void 0 ? _a : true,
            payMetrics: (_b = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showPayMetrics) !== null && _b !== void 0 ? _b : true,
            shiftTable: (_c = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showShiftTable) !== null && _c !== void 0 ? _c : false,
            shiftTableSchemas: schemas,
            shiftTableMetrics: metrics,
            timeOffTable: (_d = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showTimeoffTable) !== null && _d !== void 0 ? _d : false,
            textContent: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.textContent,
            attestationInfo: (_e = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showAttestationInfo) !== null && _e !== void 0 ? _e : false,
            signature: (_f = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showSignatureFields) !== null && _f !== void 0 ? _f : false,
            taxRate: (_g = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.taxRate) !== null && _g !== void 0 ? _g : 0,
            summaryOnly: (_h = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.summaryOnly) !== null && _h !== void 0 ? _h : false,
            rootObject: (_j = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _j === void 0 ? void 0 : _j.rootCollectionId,
            linkedData: (_k = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _k === void 0 ? void 0 : _k.reverseCollectionId,
            reportDate: LinkedDataReportDateType[(_m = (_l = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _l === void 0 ? void 0 : _l.reportDate) !== null && _m !== void 0 ? _m : LinkedDataReportDateType.DATE_OF_EXPORT],
            topLeft: mapLinkedDataTemplateToFormFieldValues((_p = (_o = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _o === void 0 ? void 0 : _o.topLeftContent) !== null && _p !== void 0 ? _p : []),
            topRight: mapLinkedDataTemplateToFormFieldValues((_r = (_q = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _q === void 0 ? void 0 : _q.topRightContent) !== null && _r !== void 0 ? _r : []),
            tableContents: mapLinkedDataTemplateToTableColumnValues((_t = (_s = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _s === void 0 ? void 0 : _s.tableColumns) !== null && _t !== void 0 ? _t : []),
            bottomLeft: mapLinkedDataTemplateToFormFieldValues((_v = (_u = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _u === void 0 ? void 0 : _u.botLeftContent) !== null && _v !== void 0 ? _v : []),
            bottomRight: mapLinkedDataTemplateToFormFieldValues((_x = (_w = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _w === void 0 ? void 0 : _w.botRightContent) !== null && _x !== void 0 ? _x : []),
            tableHeaderContent: mapLinkedDataTemplateToFormFieldValues((_z = (_y = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.linkedDataSettings) === null || _y === void 0 ? void 0 : _y.tableHeaderContent) !== null && _z !== void 0 ? _z : []),
        };
    }, [existingTemplateData]);
    var onSaveTemplate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues_1, shiftTableSchemasInfo_1, shiftTableMetricsInfo_1, hasSelectableShiftTableProperties_1, request, errorInfo_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    _k.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    formValues_1 = _k.sent();
                    shiftTableSchemasInfo_1 = [];
                    shiftTableMetricsInfo_1 = [];
                    if (!isLocationSummaryTemplate && !isLinkedDataTemplate) {
                        hasSelectableShiftTableProperties_1 = 
                        // formValues.shiftTable is true for a default template with "List of Shift" toggled on OR when an existing location inv or shift detail template is opened
                        formValues_1.shiftTable || isLocationInvoiceTemplate || isShiftDetailTemplate;
                        shifTableSchemas.forEach(function (schema) {
                            var isSelected = hasSelectableShiftTableProperties_1
                                ? formValues_1.shiftTableSchemas.includes(schema.value)
                                : false;
                            shiftTableSchemasInfo_1.push({
                                id: schema.value,
                                showInReport: isSelected,
                            });
                        });
                        shiftTableMetrics.forEach(function (metric) {
                            var isSelected = hasSelectableShiftTableProperties_1
                                ? formValues_1.shiftTableMetrics.includes(metric.value)
                                : false;
                            shiftTableMetricsInfo_1.push({
                                metric: metric.value,
                                showInReport: isSelected,
                            });
                        });
                    }
                    request = {
                        type: PayReportExportTemplateType.PDF,
                        templateName: formValues_1.templateName,
                        pdfExportSettings: isDefaultTemplate
                            ? {
                                exportType: formValues_1.groupByType,
                                showAccountLogo: (_a = formValues_1.accountLogo) !== null && _a !== void 0 ? _a : false,
                                showPayMetrics: (_b = formValues_1.payMetrics) !== null && _b !== void 0 ? _b : false,
                                showShiftTable: (_c = formValues_1.shiftTable) !== null && _c !== void 0 ? _c : false,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                                showTimeoffTable: (_d = formValues_1.timeOffTable) !== null && _d !== void 0 ? _d : false,
                                textContent: (_e = formValues_1.textContent) !== null && _e !== void 0 ? _e : '',
                                showAttestationInfo: (_f = formValues_1.attestationInfo) !== null && _f !== void 0 ? _f : false,
                                showSignatureFields: (_g = formValues_1.signature) !== null && _g !== void 0 ? _g : false,
                            }
                            : undefined,
                        shiftDetailPdfExportSettings: isShiftDetailTemplate
                            ? {
                                exportType: formValues_1.groupByType,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                            }
                            : undefined,
                        locationInvoicePdfExportSettings: isLocationInvoiceTemplate
                            ? {
                                exportType: PayReportExportPdfType.PDF_PER_LOCATION,
                                summaryOnly: (_h = formValues_1.summaryOnly) !== null && _h !== void 0 ? _h : false,
                                taxRate: (_j = formValues_1.taxRate) !== null && _j !== void 0 ? _j : 0,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                            }
                            : undefined,
                        linkedDataPdfExportSettings: isLinkedDataTemplate
                            ? {
                                exportType: formValues_1.groupByType,
                                topLeftContent: mapLinkedDataFormFieldValuesToTemplate(formValues_1.topLeft),
                                topRightContent: mapLinkedDataFormFieldValuesToTemplate(formValues_1.topRight),
                                tableHeaderContent: [], // Supported on BE but omitting on FE for now
                                botLeftContent: mapLinkedDataFormFieldValuesToTemplate(formValues_1.bottomLeft),
                                botRightContent: mapLinkedDataFormFieldValuesToTemplate(formValues_1.bottomRight),
                                tableColumns: mapLinkedDataTableColumnValuesToTemplate(formValues_1.tableContents),
                                rootCollectionId: formValues_1.rootObject,
                                reverseCollectionId: formValues_1.linkedData,
                                reportDate: formValues_1.reportDate,
                            }
                            : undefined,
                    };
                    if (existingTemplateData) {
                        request = __assign(__assign({}, request), { id: existingTemplateData.id });
                        useUpdateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    else {
                        useCreateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    errorInfo_1 = _k.sent();
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (errorInfo_1 === null || errorInfo_1 === void 0 ? void 0 : errorInfo_1.errorFields) {
                        toast('Please address incomplete form fields', { type: 'error' });
                    }
                    return [2 /*return*/];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        existingTemplateData,
        form,
        isDefaultTemplate,
        isLocationInvoiceTemplate,
        isLocationSummaryTemplate,
        isShiftDetailTemplate,
        isLinkedDataTemplate,
        onCloseModal,
        shifTableSchemas,
        shiftTableMetrics,
        useCreateReportExportTemplate,
        useUpdateReportExportTemplate,
    ]);
    var onDeleteTemplate = useCallback(function () {
        if (existingTemplateData) {
            useDeleteReportExportTemplate.mutate(existingTemplateData.id, {
                onSuccess: function () {
                    onCloseModal();
                },
            });
        }
    }, [existingTemplateData, onCloseModal, useDeleteReportExportTemplate]);
    return {
        shifTableSchemas: shifTableSchemas,
        shiftTableMetrics: shiftTableMetrics,
        showSchemasAndMetricsCheckBoxGroup: showSchemasAndMetricsCheckBoxGroup,
        setShowSchemasAndMetricsCheckBoxGroup: setShowSchemasAndMetricsCheckBoxGroup,
        pdfTemplateType: pdfTemplateType,
        setPdfTemplateType: setPdfTemplateType,
        isLocationSummaryOnly: isLocationSummaryOnly,
        isLocationSummaryTemplate: isLocationSummaryTemplate,
        isDefaultTemplate: isDefaultTemplate,
        isShiftDetailTemplate: isShiftDetailTemplate,
        isLocationInvoiceTemplate: isLocationInvoiceTemplate,
        isLinkedDataTemplate: isLinkedDataTemplate,
        setIsLocationSummaryOnly: setIsLocationSummaryOnly,
        form: form,
        templateFormInitialValues: templateFormInitialValues,
        onSaveTemplate: onSaveTemplate,
        onDeleteTemplate: onDeleteTemplate,
    };
};
