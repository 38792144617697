var _a;
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { RecordDataDisplayOptionType, PsuedoSchemaPath, } from 'src/services/Interfaces';
import { CellInputMode, NativePath, ValueDataType } from 'src/shared/Constant';
import { WebAccessIcon, MobileAccessIcon, DataAccessIcon, ViewPermissionIcon, FilterPermissionIcon, EditPermissionIcon, UpdatePermissionIcon, WorkspaceSettingsIcon, AccountSettingsIcon, ManagementSettingsIcon, ToolsSettingsIcon, CalendarAccessIcon, MobileProfileAccessIcon, } from 'src/utils/icons';
import { TimeClockAccessIcon } from 'src/utils/icons/AccessGroupIcons/TimeClockAccessIcon';
import { CommunicationHubIcon } from 'src/utils/icons/CommunicationHubIcon';
// ------------------------- Account General Permissions -------------------------
var GeneralPermissionKey;
(function (GeneralPermissionKey) {
    GeneralPermissionKey["READ_WEB"] = "READ_WEB";
    GeneralPermissionKey["ACCESS_MOBILE"] = "ACCESS_MOBILE";
    GeneralPermissionKey["TIME_CLOCK"] = "TIME_CLOCK";
    GeneralPermissionKey["EDIT_ACCOUNT"] = "EDIT_ACCOUNT";
    GeneralPermissionKey["BUILD_DATA"] = "BUILD_DATA";
    GeneralPermissionKey["AUTOMATICALLY_APPLY_FILTERS"] = "AUTOMATICALLY_APPLY_FILTERS";
    GeneralPermissionKey["EDIT_WORKSPACE"] = "EDIT_WORKSPACE";
    GeneralPermissionKey["ACCESS_GROUPS"] = "ACCESS_GROUPS";
    GeneralPermissionKey["TIME_TRACKING"] = "TIME_TRACKING";
    GeneralPermissionKey["PAY"] = "PAY";
    GeneralPermissionKey["INTEGRATIONS"] = "INTEGRATIONS";
    GeneralPermissionKey["BILLING"] = "BILLING";
    GeneralPermissionKey["AUTOMATION"] = "AUTOMATION";
    GeneralPermissionKey["FORMS"] = "FORMS";
    GeneralPermissionKey["IMPORT_EXPORT"] = "IMPORT_EXPORT";
    GeneralPermissionKey["DASHBOARD"] = "DASHBOARD";
    GeneralPermissionKey["REQUESTS"] = "REQUESTS";
    GeneralPermissionKey["REPORTS"] = "REPORTS";
    GeneralPermissionKey["PAY_PERIODS"] = "PAY_PERIODS";
    GeneralPermissionKey["ACTIVITY_HISTORY"] = "ACTIVITY_HISTORY";
    GeneralPermissionKey["CHAT"] = "CHAT";
    GeneralPermissionKey["GOALS"] = "GOALS";
    GeneralPermissionKey["NEEDS_EDIT_PERMISSION"] = "NEEDS_EDIT_PERMISSION";
    GeneralPermissionKey["COVERAGE_EDIT_PERMISSION"] = "COVERAGE_EDIT_PERMISSION";
    GeneralPermissionKey["RATIO_EDIT_PERMISSION"] = "RATIO_EDIT_PERMISSION";
    GeneralPermissionKey["EXPECTED_EDIT_PERMISSION"] = "EXPECTED_NEEDS_EDIT_PERMISSION";
    GeneralPermissionKey["NEEDS_COVERAGE"] = "NEEDS_COVERAGE";
    GeneralPermissionKey["NEEDS_COVERAGE_VIEW_ONLY"] = "NEEDS_COVERAGE_VIEW_ONLY";
    GeneralPermissionKey["SCHEDULE_TEMPLATE"] = "SCHEDULE_TEMPLATE";
    GeneralPermissionKey["TASKS"] = "TASKS";
    GeneralPermissionKey["COMMENTS"] = "COMMENTS";
    GeneralPermissionKey["MANAGE_DEPARTMENTS"] = "MANAGE_DEPARTMENTS";
    GeneralPermissionKey["LOCALIZATION"] = "LOCALIZATION";
    GeneralPermissionKey["SCHEDULE_ANALYSIS"] = "SCHEDULE_ANALYSIS";
})(GeneralPermissionKey || (GeneralPermissionKey = {}));
var ProductAccessDetails = [
    {
        id: GeneralPermissionKey.READ_WEB,
        icon: _jsx(WebAccessIcon, {}),
        title: 'Web Access',
        description: 'Allow users in this group to access the TeamBridge account via the web or mobile browser from app.teambridge.com. It is recommended that all admins and managers have web access. ',
    },
    {
        id: GeneralPermissionKey.ACCESS_MOBILE,
        title: 'Mobile Access',
        icon: _jsx(MobileAccessIcon, {}),
        description: 'Allow users in this group to log into the TeamBridge mobile application. Note: The mobile app is required for location-based time clock services such as geofencing. You can configure these settings on the Mobile Settings tab.',
    },
    {
        id: GeneralPermissionKey.TIME_CLOCK,
        title: 'Time Clock Portal Access',
        icon: _jsx(TimeClockAccessIcon, {}),
        description: 'Allow users in this group to access the TeamBridge time clock portal application.',
    },
];
var ProductAccessPermission = {
    title: 'Product Access',
    permissions: ProductAccessDetails,
};
var OwnerEditableGeneralPermissions = [
    {
        title: 'Account Editing',
        permissions: [
            {
                id: '',
                icon: _jsx(AccountSettingsIcon, {}),
                title: 'Account Settings',
                description: 'Give this access group access to the Account Settings section. When off teammates will not be able to see the Account settings section at all. Use the sub-sections below to grant limited access within the Account Section.',
                children: [
                    {
                        id: GeneralPermissionKey.AUTOMATICALLY_APPLY_FILTERS,
                        title: 'Automatically save all filter updates',
                        description: 'Any changes made to filters by this user will apply for all users, without needing to click the "Save Filters" button.',
                    },
                ],
            },
        ],
    },
];
var AccountEditDetails = [
    {
        id: GeneralPermissionKey.BUILD_DATA,
        icon: _jsx(DataAccessIcon, {}),
        title: 'Data Builder',
        description: 'Allow users in this group to create, build and delete columns and data structures. These users will be able to create and delete data type, which can erase all data collected. Only select users with knowledge of TeamBridge should have this permission.',
    },
    {
        id: GeneralPermissionKey.EDIT_WORKSPACE,
        icon: _jsx(WorkspaceSettingsIcon, {}),
        title: 'Workspace Control',
        description: 'Allow users in this group to create, move, rename or delete workspace and pages.',
    },
    {
        id: '',
        icon: _jsx(ManagementSettingsIcon, {}),
        title: 'Management Details',
        description: 'Allow these users the ability to view or take action on the following widgets.',
        children: [
            {
                id: GeneralPermissionKey.REQUESTS,
                title: 'Approve Requests',
                description: 'Approve or Reject Shift Claim Requests, Shift Release Requests, Time Off Requests.',
            },
            {
                id: GeneralPermissionKey.PAY_PERIODS,
                title: 'Pay Periods',
                description: 'Can View Export, and Create Pay Periods.',
            },
            {
                id: GeneralPermissionKey.REPORTS,
                title: 'Can Generate Reports/Metrics',
                description: 'View, create/delete and edit custom reports on workspaces.',
            },
        ],
    },
    {
        id: '',
        icon: _jsx(AccountSettingsIcon, {}),
        title: 'Account Settings',
        description: 'Give this access group access to the Account Settings section. When off teammates will not be able to see the Account settings section at all. Use the sub-sections below to grant limited access within the Account Section.',
        children: [
            {
                id: GeneralPermissionKey.EDIT_ACCOUNT,
                title: 'Account Details',
                description: 'View and edit the account details section of the account settings.',
            },
            {
                id: GeneralPermissionKey.ACCESS_GROUPS,
                title: 'Access Groups',
                description: 'View, Create/Delete, and edit access groups in the account setting section.',
            },
            {
                id: GeneralPermissionKey.TIME_TRACKING,
                title: 'Time Tracking',
                description: 'View, and edit time tracking policies in the settings section. This includes  time clock, overtime, breaks, time offs, job codes, and geofences.',
            },
            {
                id: GeneralPermissionKey.PAY,
                title: 'Pay',
                description: 'Allow users in this access group to create, edit and delete pay rates in the account settings, as well as see shift pay metrics and employee pay rates.',
            },
            {
                id: GeneralPermissionKey.INTEGRATIONS,
                title: 'Integrations',
                description: 'View, Create/Delete, and edit the integrations section of the account settings.',
            },
            {
                id: GeneralPermissionKey.BILLING,
                title: 'Billing',
                description: 'View, Create/Delete, and edit the billing settings.',
            },
            {
                id: GeneralPermissionKey.AUTOMATICALLY_APPLY_FILTERS,
                title: 'Automatically apply all filter updates',
                description: 'Any changes made to filters by this user will apply for all users, without needing to click the "Save Filters" button.',
            },
        ],
    },
    {
        id: '',
        icon: _jsx(ToolsSettingsIcon, {}),
        title: 'Tools',
        description: 'Within the section below you can define which tools your users have access to.',
        children: [
            {
                id: GeneralPermissionKey.DASHBOARD,
                title: 'Dashboard',
                description: 'Allow users in this access group access to the account dashboard view.',
            },
            {
                id: GeneralPermissionKey.CHAT,
                title: 'Team Chat',
                description: 'Allow users in this access group access to the communicate view. They will be allowed to send broadcasts if they are part of a chat department.',
            },
            {
                id: GeneralPermissionKey.MANAGE_DEPARTMENTS,
                title: 'Manage Departments',
                description: 'Allow users in this access group access to the manage departments section.',
            },
            {
                id: GeneralPermissionKey.COMMENTS,
                title: 'Record Comments',
                description: 'Allow users in this access group to view and leave comments on records.',
            },
            {
                id: GeneralPermissionKey.GOALS,
                title: 'Goals',
                description: 'Allow users in this access group access to the goals section.',
            },
            {
                id: GeneralPermissionKey.IMPORT_EXPORT,
                title: 'Files',
                description: 'Allow users in this access group to view and download exported data from the Files page.',
            },
            {
                id: GeneralPermissionKey.ACTIVITY_HISTORY,
                title: 'Activity History',
                description: 'When on this permission allows users in this access group to see the activity history of the entire account as well as individual records. When off the activity button will be hidden.',
            },
            {
                id: GeneralPermissionKey.AUTOMATION,
                title: 'Automations',
                description: 'Allow users in this access group access to the automations section to view, edit, activate and delete workflows.',
            },
            {
                id: GeneralPermissionKey.TASKS,
                title: 'Tasks',
                description: 'Allow users in this access group access to the tasks section to view, edit, and assign tasks.',
            },
            {
                id: GeneralPermissionKey.FORMS,
                title: 'Forms',
                description: 'Allow users in this access group access to edit and create forms.',
            },
            {
                id: GeneralPermissionKey.LOCALIZATION,
                title: 'Localization',
                description: 'Allow users in this access group the ability to add and edit translations for pages, collections, schemas, and other content types.',
            },
        ],
    },
    {
        id: '',
        icon: _jsx(CalendarAccessIcon, {}),
        title: 'Scheduling',
        description: 'Within the section below you can define which scheduling tools your users have access to.',
        children: [
            {
                id: GeneralPermissionKey.NEEDS_EDIT_PERMISSION,
                title: 'Edit Needs',
                linkedIds: [GeneralPermissionKey.NEEDS_COVERAGE],
                reverseLinkedIds: [GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY],
                description: 'Allows users in this access group to update and delete needs',
            },
            {
                id: GeneralPermissionKey.COVERAGE_EDIT_PERMISSION,
                title: 'Edit Coverages',
                linkedIds: [GeneralPermissionKey.NEEDS_COVERAGE],
                reverseLinkedIds: [GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY],
                description: 'Allows users in this access group to update and delete coverage',
            },
            {
                id: GeneralPermissionKey.RATIO_EDIT_PERMISSION,
                title: 'Edit Demand Ratio',
                linkedIds: [GeneralPermissionKey.NEEDS_COVERAGE],
                reverseLinkedIds: [GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY],
                description: 'Allows users in this access group to update and delete demand ratio.',
            },
            {
                id: GeneralPermissionKey.EXPECTED_EDIT_PERMISSION,
                title: 'Edit Expected Needs',
                linkedIds: [GeneralPermissionKey.NEEDS_COVERAGE],
                reverseLinkedIds: [GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY],
                description: 'Allows users in this access group to update expected needs',
            },
            {
                id: GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY,
                title: 'View Only Needs And Coverage & Demand Ratio',
                linkedIds: [
                    GeneralPermissionKey.NEEDS_COVERAGE,
                    GeneralPermissionKey.RATIO_EDIT_PERMISSION,
                    GeneralPermissionKey.NEEDS_EDIT_PERMISSION,
                    GeneralPermissionKey.COVERAGE_EDIT_PERMISSION,
                ],
                description: 'Allows users in this access group to view needs, coverage, and demand ratio.',
            },
            {
                id: GeneralPermissionKey.NEEDS_COVERAGE,
                title: 'Needs And Coverage & Demand Ratio',
                description: 'Allow users in this access group to create, update and delete needs, coverage, and demand ratio.',
            },
            {
                id: GeneralPermissionKey.SCHEDULE_TEMPLATE,
                title: 'Schedule Templates',
                description: 'Allow users in this access group to create, update, and delete scheduling templates.',
            },
            {
                id: GeneralPermissionKey.SCHEDULE_ANALYSIS,
                title: 'Schedule Analysis',
                description: 'Allow users in this access group to access scheduling analysis and update scheduling rules.',
            },
        ],
    },
];
var AccountEditPermission = {
    title: 'Account Editing',
    permissions: AccountEditDetails,
};
var GeneralPermissions = [ProductAccessPermission, AccountEditPermission];
// ---------------------------- Object Permissions ----------------------------
/**
 * !!WARNING: Level needs to be in order of least to most access
 */
var PagePermissionLevel;
(function (PagePermissionLevel) {
    PagePermissionLevel["NONE"] = "0";
    PagePermissionLevel["READ"] = "2000";
    PagePermissionLevel["APPLY_FILTER"] = "6000";
    PagePermissionLevel["WRITE"] = "9999";
})(PagePermissionLevel || (PagePermissionLevel = {}));
var SchemaPermissionLevel;
(function (SchemaPermissionLevel) {
    SchemaPermissionLevel["NONE"] = "0";
    SchemaPermissionLevel["READ"] = "500";
    SchemaPermissionLevel["UPDATE_FROM_EMPTY"] = "800";
    SchemaPermissionLevel["WRITE"] = "1000";
})(SchemaPermissionLevel || (SchemaPermissionLevel = {}));
var CollectionPermissionLevel;
(function (CollectionPermissionLevel) {
    CollectionPermissionLevel["NONE"] = "0";
    CollectionPermissionLevel["READ"] = "1000";
    CollectionPermissionLevel["CREATE"] = "9000";
    CollectionPermissionLevel["WRITE"] = "10000";
})(CollectionPermissionLevel || (CollectionPermissionLevel = {}));
var PagePermissionLevelOptions = function (level) {
    if (level == PagePermissionLevel.READ) {
        return 'Can View';
    }
    else if (level == PagePermissionLevel.APPLY_FILTER) {
        return 'Can Change Filters';
    }
    else if (level == PagePermissionLevel.WRITE) {
        return 'Full Access';
    }
    else {
        return 'No Access';
    }
};
var PagePermissionIconSet = function (level) {
    if (level == PagePermissionLevel.READ) {
        return _jsx(ViewPermissionIcon, {});
    }
    else if (level == PagePermissionLevel.APPLY_FILTER) {
        return _jsx(FilterPermissionIcon, {});
    }
    else if (level == PagePermissionLevel.WRITE) {
        return _jsx(EditPermissionIcon, {});
    }
    else {
        return _jsx(_Fragment, {});
    }
};
var SchemaPermissionLevelOptions = function (level) {
    if (level == SchemaPermissionLevel.READ) {
        return 'Can View';
    }
    else if (level == SchemaPermissionLevel.UPDATE_FROM_EMPTY) {
        return 'Can Add From Blank';
    }
    else if (level == SchemaPermissionLevel.WRITE) {
        return 'Full Access';
    }
    else {
        return 'No Access';
    }
};
var SchemaPermissionIconSet = function (level) {
    if (level == SchemaPermissionLevel.READ) {
        return _jsx(ViewPermissionIcon, {});
    }
    else if (level == SchemaPermissionLevel.UPDATE_FROM_EMPTY) {
        return _jsx(UpdatePermissionIcon, {});
    }
    else if (level == SchemaPermissionLevel.WRITE) {
        return _jsx(EditPermissionIcon, {});
    }
    else {
        return _jsx(_Fragment, {});
    }
};
var CollectionPermissionLevelOptions = function (level) {
    if (level == CollectionPermissionLevel.READ) {
        return 'Can View';
    }
    else if (level == CollectionPermissionLevel.CREATE) {
        return 'Can Create';
    }
    else if (level == CollectionPermissionLevel.WRITE) {
        return 'Full Access';
    }
    else {
        return 'No Access';
    }
};
var CollectionPermissionIconSet = function (level) {
    if (level == CollectionPermissionLevel.READ) {
        return _jsx(ViewPermissionIcon, {});
    }
    else if (level == CollectionPermissionLevel.CREATE) {
        return _jsx(PlusOutlined, { style: { fontSize: '1.1rem' } });
    }
    else if (level == CollectionPermissionLevel.WRITE) {
        return _jsx(EditPermissionIcon, {});
    }
};
export var ShiftSchemasCannotHide = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_USER_ID,
]);
export var ForbiddenCustomizableShiftSchemas = new Set([
    NativePath.SHIFT_ARCHIVED,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_PUBLISHED,
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_STATUS,
    NativePath.SHIFT_USER_ID,
    NativePath.SHIFT_PAYROLL_STATUS,
    NativePath.SHIFT_BILLING_STATUS,
]);
export var ForbiddenCustomizableJobSchemas = new Set([NativePath.JOB_PUBLISHED]);
export var ForbiddenCustomizablePlacementSchemas = new Set([
    NativePath.PLACEMENT_PUBLISHED,
    NativePath.PLACEMENT_JOB_RID,
]);
export var MobileRequiredFieldKeys = ['clockOutRequiredFields', 'clockInRequiredFields'];
export var MobileClockOutFieldKeys = ['clockOutRequiredFields', 'clockOutOptionalFields'];
var MobilePermissionDetails = [
    {
        id: 'dashboard',
        title: 'Home',
        description: 'Allow mobile users to access the home page within the app.',
        icon: _jsx(WebAccessIcon, {}),
        children: [
            {
                id: 'onboard',
                title: 'Show Smart Onboarding',
                description: 'Allow users to see the Smart Onboarding',
            },
            {
                id: 'createPost',
                title: 'Create Posts',
                description: 'Allow users to create posts',
            },
            {
                id: 'postComments',
                title: 'Post Comments',
                description: 'Allow users to post comments',
            },
            {
                id: 'pages',
                title: 'Pages',
                description: 'Allow users in this group to access the TeamBridge pages.',
            },
            {
                id: 'helpArticles',
                title: 'Help Articles',
                description: 'Show users TeamBridge help articles on their mobile dashboard.',
            },
            {
                id: 'hideReverseCollection',
                title: 'Hide Reverse Collection',
                description: 'Hide the reverse collection on record details',
            },
        ],
    },
    {
        id: 'timeClock',
        title: 'Time Clock',
        description: 'Allow users in this group to log into the TeamBridge mobile application. Note: The mobile app is required for location-based time clock services such as geofencing. You can configure these settings on the Mobile Settings tab.',
        icon: _jsx(MobileAccessIcon, {}),
        children: [
            {
                id: 'unscheduledShifts',
                title: 'Unscheduled Shift',
                description: 'Allow users to clock in and out of unscheduled shifts',
            },
            {
                id: 'geofence',
                title: 'Restricted By Geofence',
                description: 'Restrict users to clock in and out of shifts based on their location',
            },
            {
                id: 'locationTracking',
                title: 'Track Location',
                description: 'Track users location when they are in the middle of a shift, or when they are clocked in/out',
            },
            {
                id: 'earlyClockIn',
                title: 'Restrict Early Clock In',
                description: 'Restrict users from clocking in early',
            },
            {
                id: 'clockInRequiredFields',
                title: 'Clock In Required Fields',
                description: 'Require users to fill out required fields when clocking in',
            },
            {
                id: 'clockInOptionalFields',
                title: 'Clock In Optional Fields',
                description: 'Allow users to fill out optional fields when clocking in',
            },
            {
                id: 'clockOutRequiredFields',
                title: 'Clock Out Required Fields',
                description: 'Require users to fill out required fields when clocking out',
            },
            {
                id: 'clockOutOptionalFields',
                title: 'Clock Out Optional Fields',
                description: 'Allow users to fill out optional fields when clocking out',
            },
        ],
    },
    {
        id: 'calendar',
        title: 'Calendar',
        description: 'Allow users in this group to access the TeamBridge calendar.',
        icon: _jsx(CalendarAccessIcon, {}),
        children: [
            {
                id: 'customizedShiftViewSchemas',
                title: 'Customize Fields On Shift Card',
                description: 'Show only these fields on shift cards',
            },
            {
                id: 'claimShift',
                title: 'Allow Shift Claim',
                description: 'Allow users to claim shifts',
            },
            {
                id: 'requireShiftRecommendationAdminApproval',
                title: 'Require Approval on Recommended Shift Claim',
                description: 'Require manager approval when claiming a recommended shift',
            },
            {
                id: 'withdrawClaim',
                title: 'Allow Claim Withdrawal',
                description: 'Allow users to withdraw their claim',
            },
            {
                id: 'releaseShift',
                title: 'Allow Shift Release',
                description: 'Allow users to release shifts',
            },
            {
                id: 'swapShift',
                title: 'Require Release Shift to Another User',
                description: 'Require users to release their shift to another user. Creates a recommended shift for others to claim',
            },
            {
                id: 'viewOthersShifts',
                title: "Can View Others' Shifts",
                description: "Allow these users to see others' shifts when they turn on the Team Schedule option",
            },
            {
                id: 'hideOthersShiftSchemas',
                title: "Hide Others' Shift Data",
                description: 'Hide fields on shifts that do not belong to the current user',
            },
            {
                id: 'showOverlappingShifts',
                title: 'Show Overlapping Shifts',
                description: 'Allow users to see overlapping shifts',
            },
        ],
    },
    {
        id: 'profile',
        title: 'Profile',
        description: 'Allow users in this group to access the TeamBridge profile.',
        icon: _jsx(MobileProfileAccessIcon, {}),
        children: [
            {
                id: 'timeSheet',
                title: 'Show Time Sheet',
                description: 'Allow users to see the time sheet',
            },
            {
                id: 'hiddenLinkedData',
                title: 'Linked Data',
                description: 'Choose which linked data to show on the profile.',
            },
        ],
    },
    {
        id: 'chat',
        title: 'Chat',
        description: 'Allow users in this group to access the send chat messages.',
        icon: _jsx(CommunicationHubIcon, {}),
        children: [
            {
                id: 'sendDmToAnyone',
                title: 'Send DM to Anyone',
                description: 'Allow users to send DMs to anyone in the company.',
            },
        ],
    },
    {
        id: 'placement',
        title: 'Jobs & Placements',
        description: 'Allow users in this group to access the "Jobs & Placements" tab on the mobile app.',
        icon: _jsx(ManagementSettingsIcon, {}),
        children: [
            {
                id: 'customizedJobCardFields',
                title: 'Customize Fields on Job Cards',
                description: 'Show only these fields on job cards',
            },
            {
                id: 'customizedPlacementCardFields',
                title: 'Customize Fields on Placement Cards',
                description: 'Show only these fields on placement cards',
            },
            {
                id: 'expressInterest',
                title: 'Express Interest',
                description: 'Allow users to express interest in a open job.',
            },
        ],
    },
];
var MobileJobPseudoSchemaCustomCardFields = [
    {
        type: RecordDataDisplayOptionType.PSEUDO_SCHEMA,
        pseudoPath: PsuedoSchemaPath.JOB_DATE_RANGE,
    },
];
var MobilePlacementPseudoSchemaCustomCardFields = [
    {
        type: RecordDataDisplayOptionType.PSEUDO_SCHEMA,
        pseudoPath: PsuedoSchemaPath.JOB_DATE_RANGE,
    },
    {
        type: RecordDataDisplayOptionType.PSEUDO_SCHEMA,
        pseudoPath: PsuedoSchemaPath.PLACEMENT_JOB_WEEKLY_PAY,
    },
];
var MobileJobPseudoSchemaMap = (_a = {},
    _a[PsuedoSchemaPath.JOB_DATE_RANGE] = {
        id: PsuedoSchemaPath.JOB_DATE_RANGE,
        name: 'Job Date Range',
        type: ValueDataType.TEXT,
        inputMode: CellInputMode.PLAIN,
    },
    _a[PsuedoSchemaPath.JOB_LOCATION_IMAGE] = {
        id: PsuedoSchemaPath.JOB_LOCATION_IMAGE,
        name: 'Location Image',
        type: ValueDataType.TEXT,
        inputMode: CellInputMode.PLAIN,
    },
    _a[PsuedoSchemaPath.PLACEMENT_JOB_LOCATION_IMAGE] = {
        id: PsuedoSchemaPath.PLACEMENT_JOB_LOCATION_IMAGE,
        name: 'Job Location Image',
        type: ValueDataType.TEXT,
        inputMode: CellInputMode.PLAIN,
    },
    _a[PsuedoSchemaPath.PLACEMENT_JOB_WEEKLY_PAY] = {
        id: PsuedoSchemaPath.PLACEMENT_JOB_WEEKLY_PAY,
        name: 'Job Weekly Pay Amount',
        type: ValueDataType.TEXT,
        inputMode: CellInputMode.PLAIN,
    },
    _a);
export { 
// Account General Permissions
GeneralPermissions, GeneralPermissionKey, OwnerEditableGeneralPermissions, 
// Object Permissions
PagePermissionLevel, SchemaPermissionLevel, CollectionPermissionLevel, PagePermissionLevelOptions, SchemaPermissionLevelOptions, CollectionPermissionLevelOptions, SchemaPermissionIconSet, PagePermissionIconSet, CollectionPermissionIconSet, 
// Mobile Permissions
MobilePermissionDetails, 
// Mobile settings
MobileJobPseudoSchemaCustomCardFields, MobileJobPseudoSchemaMap, MobilePlacementPseudoSchemaCustomCardFields, };
