import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { LOCALIZATION } from 'src/components/FeatureGatingKeyConstant';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { ContentType } from 'src/components/TranslationEditorWrapper/interface';
import TranslationEditorWrapper from 'src/components/TranslationEditorWrapper/TranslationEditorWrapper';
import { ifUserHasGeneralPermission } from 'src/state';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import LocalizationIcon from 'src/utils/icons/images/LocalizationIcon.svg';
var TranslationMenuItem = function (_a) {
    var titleString = _a.titleString, onFocus = _a.onFocus, onChange = _a.onChange, onBlur = _a.onBlur, item = _a.item, contentType = _a.contentType, setIsLocalizationModalVisible = _a.setIsLocalizationModalVisible, onPressEnter = _a.onPressEnter, onClick = _a.onClick, upperComponentActions = _a.upperComponentActions, _b = _a.isCreate, isCreate = _b === void 0 ? false : _b;
    var isLocalizationEnabled = useRecoilValue(featureEnableStatusState(LOCALIZATION));
    var ifUserHasLocalizationPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.LOCALIZATION));
    var shouldRenderWrapper = item.id && isLocalizationEnabled && ifUserHasLocalizationPermission && !isCreate;
    var inputClass = 'bg-gray-50 hover:bg-gray-50';
    var inputWrapperClass = 'relative w-full pr-2 mr-2';
    var wrapperButtonStyle = { top: '20%' };
    if (contentType === ContentType.COLLECTION) {
        inputClass = 'text-lg relative w-full pr-2 mr-2 pl-0';
        inputWrapperClass = 'relative w-full pr-2 mr-2 pl-0';
    }
    if (contentType === ContentType.PAGE || contentType === ContentType.PAGE_GROUP) {
        inputClass = 'bg-gray-50 hover:bg-gray-50 mt-4';
        inputWrapperClass = 'relative w-full pl-2 pr-2 mt-5 mr-2 mb-2';
        wrapperButtonStyle = { top: '50%' };
    }
    if (contentType === ContentType.SCHEMA_GROUP) {
        inputClass = 'bg-gray-50 hover:bg-gray-50 h-full';
        inputWrapperClass = 'relative w-full pr-2 mr-2 h-full';
    }
    return (_jsxs("div", { className: inputWrapperClass, children: [_jsx(Input, { autoFocus: onFocus ? true : false, className: inputClass, value: titleString, onChange: function (e) { return onChange(e); }, onPressEnter: function (e) {
                    e.currentTarget.blur();
                    if (onPressEnter) {
                        onPressEnter();
                    }
                }, onBlur: function (e) {
                    if (onBlur) {
                        onBlur(e);
                    }
                }, onFocus: function (e) {
                    if (onFocus) {
                        onFocus(e);
                    }
                }, onClick: function (e) {
                    if (onClick) {
                        onClick(e);
                    }
                }, bordered: false }), shouldRenderWrapper && (_jsx(TranslationEditorWrapper, { contentId: item.id, contentType: contentType, contentIndex: item.index, titleString: titleString, setIsLocalizationModalVisible: setIsLocalizationModalVisible || undefined, upperComponentActions: upperComponentActions, children: _jsx("div", { className: "absolute right-3 text-gray-500 hover:cursor-pointer", style: wrapperButtonStyle, children: _jsx("img", { src: LocalizationIcon, alt: "Localization Settings" }) }) }))] }));
};
export default React.memo(TranslationMenuItem);
