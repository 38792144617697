var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined, } from '@ant-design/icons';
import { Button } from 'antd';
import { intersection } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import CreateSchemaModalV2 from 'src/components/CollectionComponents/CreateSchemaModal/CreateSchemaModalV2';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { ZiraVisibilityDropdownVisibleItem, ZiraVisibilityDropdown, } from 'src/ui/ZiraVisibilityDropdown';
import { ifCurrentUserHasDataBuilderPermission } from 'src/state';
import { ContentType } from 'src/components/TranslationEditorWrapper/interface';
import TranslationMenuItem from 'src/components/SharedComponent/TranslationMenuItem/TranslationMenuItem';
var ConfigureSchemaGroupsDropdown = function (_a) {
    var schemaGroups = _a.schemaGroups, schemas = _a.schemas, onAddNewSchemaGroup = _a.onAddNewSchemaGroup, onSchemaDragEnd = _a.onSchemaDragEnd, onMoveGroupUpDown = _a.onMoveGroupUpDown, onDeleteSchemaGroup = _a.onDeleteSchemaGroup, onRenameSchemaGroup = _a.onRenameSchemaGroup, onAddNewColumn = _a.onAddNewColumn, collectionId = _a.collectionId;
    var _b = useState(), groupNameInEditIndex = _b[0], setGroupNameInEditIndex = _b[1];
    var _c = useState(''), newGroupName = _c[0], setNewGroupName = _c[1];
    var _d = useState(false), createSchemaModalVisible = _d[0], setCreateSchemaModalVisible = _d[1];
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var onEditNewGroupName = useCallback(function (groupIndex) {
        onRenameSchemaGroup(groupIndex, newGroupName);
        setGroupNameInEditIndex(undefined);
    }, [newGroupName, onRenameSchemaGroup]);
    var dropdownContent = useMemo(function () { return (_jsxs("div", { className: "flex w-full flex-col space-y-3 p-4", children: [_jsx(DragDropContext, { onDragEnd: onSchemaDragEnd, children: schemaGroups.map(function (_a, groupIndex) {
                    var groupName = _a.name, schemaIds = _a.schemas;
                    var isFirstGroup = groupIndex === 0;
                    var isLastGroup = groupIndex === schemaGroups.length - 1;
                    var groupId = "schema-group-".concat(groupIndex);
                    // Hide group if no schema in group is visible due to permissions
                    var shouldHideSchemaGroup = schemaIds.length > 0 &&
                        intersection(Object.keys(schemas), schemaIds).length === 0;
                    return (_jsx(Droppable, { droppableId: groupId, children: function (provided) {
                            return (_jsxs("div", __assign({ className: "flex w-full flex-col space-y-3" }, provided.droppableProps, { ref: provided.innerRef, style: shouldHideSchemaGroup
                                    ? { display: 'none' }
                                    : undefined, children: [_jsxs("div", { className: "flex h-10 items-end", children: [_jsxs("div", { className: "ml-2 mr-4 flex flex-col pb-3 text-gray-300", children: [_jsx("span", { className: "h-4 cursor-pointer hover:text-black", onClick: function () {
                                                            return onMoveGroupUpDown(groupIndex, true);
                                                        }, title: "Move Up", children: !isFirstGroup && _jsx(UpOutlined, {}) }), _jsx("span", { className: "h-4 cursor-pointer hover:text-black", onClick: function () {
                                                            return onMoveGroupUpDown(groupIndex, false);
                                                        }, title: "Move Down", children: !isLastGroup && _jsx(DownOutlined, {}) })] }), _jsxs("div", { className: "flex h-full w-3/4 flex-col", children: [_jsx("span", { className: "text-xs font-medium text-gray-300", children: "Group" }), _jsx("span", { className: "overflow-hidden text-ellipsis font-medium", children: groupNameInEditIndex === groupIndex ? (_jsx(TranslationMenuItem, { titleString: newGroupName, item: {
                                                                id: collectionId,
                                                                index: groupIndex,
                                                            }, contentType: ContentType.SCHEMA_GROUP, onBlur: function () { }, onPressEnter: function () {
                                                                return onEditNewGroupName(groupIndex);
                                                            }, onChange: function (e) {
                                                                return setNewGroupName(e.target.value);
                                                            }, onFocus: function () { } })) : (groupName) })] }), _jsx("span", { className: "ml-auto mr-4 cursor-pointer text-gray-300 hover:text-black", onClick: function () {
                                                    if (groupNameInEditIndex === groupIndex) {
                                                        onEditNewGroupName(groupIndex);
                                                    }
                                                    else {
                                                        setGroupNameInEditIndex(groupIndex);
                                                        setNewGroupName(groupName);
                                                    }
                                                }, children: groupNameInEditIndex === groupIndex ? (_jsx(CheckOutlined, {})) : (_jsx(EditOutlined, {})) }), !isFirstGroup && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this group?", onConfirm: function () {
                                                    return onDeleteSchemaGroup(groupIndex);
                                                }, placement: "bottomRight", children: _jsx("span", { className: "mr-4 cursor-pointer pt-1 text-gray-300 hover:text-black", children: "\u2715" }) }))] }), schemaIds.map(function (schemaId, index) {
                                        var schema = schemas[schemaId];
                                        return schema ? (_jsx(Draggable, { draggableId: schemaId, index: index, children: function (provided) { return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: schema.name, draggableProvided: provided })); } }, schemaId)) : (
                                        //! Hidden draggable so correct index is preserved
                                        _jsx(Draggable, { draggableId: schemaId, index: index, children: function (provided) { return (_jsx("div", __assign({ className: "!mt-0" }, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, schemaId));
                                    }), !schemaIds.length && _jsx("div", { className: "h-2" }), provided.placeholder] })));
                        } }, groupId));
                }) }), _jsxs("div", { className: "mt-4 flex flex-col space-y-3", children: [hasDataBuilderAccess && (_jsxs("div", { className: "light-gray-btn h-[52px] font-semibold", onClick: onAddNewSchemaGroup, children: [_jsx("span", { className: "-mt-1 ml-4 mr-4 text-gray-300", children: _jsx(PlusOutlined, {}) }), "Add Group"] })), onAddNewColumn && (_jsxs("div", { className: "light-gray-btn h-[52px] font-semibold", onClick: function () { return setCreateSchemaModalVisible(true); }, children: [_jsx("span", { className: "-mt-1 ml-4 mr-4 text-gray-300", children: _jsx(PlusOutlined, {}) }), "Add Column"] })), onAddNewColumn && createSchemaModalVisible && (_jsx(CreateSchemaModalV2, { onAddNewColumn: onAddNewColumn, isModalVisible: createSchemaModalVisible, setIsModalVisible: setCreateSchemaModalVisible, collectionId: collectionId }))] })] })); }, [
        createSchemaModalVisible,
        groupNameInEditIndex,
        hasDataBuilderAccess,
        onAddNewColumn,
        onAddNewSchemaGroup,
        onDeleteSchemaGroup,
        onEditNewGroupName,
        onMoveGroupUpDown,
        onSchemaDragEnd,
        schemaGroups,
        schemas,
        collectionId,
        newGroupName,
    ]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Configure Data'], tabContents: [dropdownContent], children: _jsx(Button, { className: "bg-gray-50 font-medium hover:bg-gray-50 hover:text-black", children: "Configure Data" }) }));
};
export default React.memo(ConfigureSchemaGroupsDropdown);
