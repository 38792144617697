import { isValidPhoneNumber } from 'libphonenumber-js';
export var emailValidation = function (email) {
    if (email === '')
        return true;
    var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(email);
};
export var phoneNumberValidation = function (phone) {
    if (phone === '')
        return true;
    return isValidPhoneNumber(phone, 'US');
};
export var nameInputValidation = function (name) {
    if (name === '')
        return false;
    if (!name.replace(/\s/g, '').length)
        return false;
    return true;
};
export var numberInputValidation = function (number) {
    var num = Number(number);
    return !isNaN(num) && isFinite(num);
};
