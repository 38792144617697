import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import './TreeNodeTitle.scss';
import { CaretRightOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useNaviTreeComponent } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { ifCurrentUserHasEditWorkspacePermission, naviTreeItemFamilySelector } from 'src/state';
import { getPageGroupExpandedStatus } from 'src/utils/Account';
import IconSelectorPopover from 'src/components/IconSelector/IconSelectorPopover';
import ObjectInlinePermission from 'src/components/Settings/Permission/ObjectPermission/ObjectInlinePermission';
import { ObjType } from 'src/shared';
import { PAGE_TYPES } from 'src/components/FeatureGatingKeyConstant';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
import { ContentType } from 'src/components/TranslationEditorWrapper/interface';
import TranslationMenuItem from 'src/components/SharedComponent/TranslationMenuItem/TranslationMenuItem';
import CreateNewPageDropdownMenu from '../CreatePageOptionMenu/CreateNewPageDropdownMenu';
var TreeNodeTitle = function (props) {
    var _a, _b, _c, _d;
    var itemId = props.itemId, openPageTemplateModal = props.openPageTemplateModal, onExpand = props.onExpand, onCollapse = props.onCollapse, canAddPage = props.canAddPage;
    var item = useRecoilValue(naviTreeItemFamilySelector(itemId));
    var titleString = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.name;
    var nodeKeyPaths = (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.nodeKeyPaths;
    var type = (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.type;
    var _e = useNaviTreeComponent(), saveRenameNode = _e.saveRenameNode, removeNode = _e.removeNode, saveEmoji = _e.saveEmoji, saveIcon = _e.saveIcon;
    var _f = useState(false), isEditing = _f[0], setIsEditing = _f[1];
    var _g = useState(false), dropdownVisible = _g[0], setDropdownVisible = _g[1];
    var _h = useState(false), iconSelectorVisible = _h[0], setIconSelectorVisible = _h[1];
    var isGroupTitle = type === 'pageGroup';
    var isPage = type === 'page';
    var contentType = isGroupTitle ? ContentType.PAGE_GROUP : ContentType.PAGE;
    var onConfirmDelete = useCallback(function () {
        setDropdownVisible(false);
        removeNode(item.id);
    }, [item.id, removeNode]);
    var menu = useMemo(function () {
        var items = [
            {
                key: 'delete',
                label: (_jsx(HardConfirmWrapper, { changeType: "delete", entityName: "".concat(isGroupTitle ? 'section' : 'page', ": ").concat(titleString), explanationText: isGroupTitle
                        ? 'This will delete all pages and blocks in this section!'
                        : 'This will delete all blocks in this page!', onConfirm: onConfirmDelete, children: _jsx("div", { className: "text-red-1000", children: "Delete" }) })),
            },
        ];
        return {
            items: items,
        };
    }, [isGroupTitle, titleString, onConfirmDelete]);
    var getIcon = useCallback(function (onExpand, onCollapse) {
        var _a;
        if (((_a = item.data) === null || _a === void 0 ? void 0 : _a.type) !== 'pageGroup')
            return;
        if (item.children && item.children.length > 0) {
            var isExpanded_1 = getPageGroupExpandedStatus(item.id);
            return (_jsx(Button, { icon: _jsx(CaretRightOutlined, { style: {
                        transform: isExpanded_1 ? 'rotate(90deg)' : undefined,
                        color: 'black',
                    } }), onClick: function () {
                    if (isExpanded_1) {
                        onCollapse(item.id);
                    }
                    else {
                        onExpand(item.id);
                    }
                }, className: "NodeExpandCollapseBtn" }));
        }
        return _jsx("span", { className: "bulletPoint", children: "\u2022" });
    }, [item]);
    var onSaveEmoji = useCallback(function (newEmoji) {
        saveEmoji(item.id, isPage ? 'page' : 'pageGroup', newEmoji);
    }, [isPage, item.id, saveEmoji]);
    var onSaveIcon = useCallback(function (newIcon) {
        saveIcon(item.id, isPage ? 'page' : 'pageGroup', newIcon);
    }, [isPage, item.id, saveIcon]);
    var renderTitleString = useCallback(function () {
        return _jsx("div", { className: "TitleString max-w-[142px]", children: titleString });
    }, [titleString]);
    var renderLink = useCallback(function () {
        if (isGroupTitle) {
            return _jsx("span", { className: "ml-[2px]", children: renderTitleString() });
        }
        else {
            return (_jsx(Link, { to: "/home/page/".concat(nodeKeyPaths[0], "/").concat(nodeKeyPaths[1]), className: "w-full hover:text-black", children: renderTitleString() }));
        }
    }, [isGroupTitle, nodeKeyPaths, renderTitleString]);
    var hasEditWorkspaceAccess = useRecoilValue(ifCurrentUserHasEditWorkspacePermission);
    var isNewPageTypeEnabled = useRecoilValue(featureEnableStatusState(PAGE_TYPES));
    return (_jsxs("div", { className: "TitleWrapper", style: { paddingLeft: isPage ? '56px' : '0px' }, children: [isGroupTitle && _jsx(_Fragment, { children: getIcon(onExpand, onCollapse) }), 'iconInfo' in item.data && (_jsx(IconSelectorPopover, { currentIcon: (_d = item.data.iconInfo) !== null && _d !== void 0 ? _d : (item.data.emoji || '📄'), selectorVisible: hasEditWorkspaceAccess ? iconSelectorVisible : false, onSelectorVisibleChange: setIconSelectorVisible, onSaveIcon: onSaveIcon, onSaveEmoji: onSaveEmoji })), renderLink(), hasEditWorkspaceAccess && (_jsxs("div", { className: "RightButtonSection flex", children: [_jsx(Button, { className: "NaviLeafTitleMoreButton flex items-center", children: _jsx(Dropdown, { menu: menu, dropdownRender: function (menu) { return (_jsxs("div", { className: "MenuContainer", children: [_jsx(TranslationMenuItem, { onPressEnter: function () {
                                            setDropdownVisible(false);
                                        }, titleString: titleString, onChange: function (e) {
                                            saveRenameNode(type, item.id, e.target.value, true);
                                            setIsEditing(true);
                                        }, onBlur: function (e) {
                                            saveRenameNode(type, item.id, e.target.value, true);
                                            setIsEditing(false);
                                        }, item: item, contentType: contentType, upperComponentActions: {
                                            onCloseUpperComponent: function () {
                                                setDropdownVisible(false);
                                            },
                                            onUpdateUpperTitle: function (title) {
                                                saveRenameNode(type, item.id, title);
                                            },
                                        } }), menu, isPage && (_jsx("div", { className: "pb-2", children: _jsx(ObjectInlinePermission, { className: "px-3", type: ObjType.PAGE, objectId: item.id }) }))] })); }, trigger: ['click'], open: dropdownVisible, onOpenChange: function (visible) {
                                setDropdownVisible(visible);
                            }, overlayClassName: "NaviBarSubMenu", children: _jsx(EllipsisOutlined, { className: "MoreButtonIcon" }) }) }), isGroupTitle &&
                        !isEditing &&
                        (isNewPageTypeEnabled ? (_jsx(CreateNewPageDropdownMenu, { pageGroupId: itemId, openPageTemplateModal: function () { return openPageTemplateModal(itemId); }, disabled: !canAddPage, children: _jsx(Button, { className: "NaviLeafTitleAddButton flex items-center", children: _jsx(PlusOutlined, {}) }) })) : (_jsx(Button, { className: "NaviLeafTitleAddButton flex items-center", onClick: function () { return openPageTemplateModal(itemId); }, disabled: !canAddPage, children: _jsx(PlusOutlined, {}) })))] }))] }));
};
export default React.memo(TreeNodeTitle);
