import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { NonSchemaNativePath, CollectionType, BespokeReverseCollectionComponent } from 'src/shared';
import { useRecoilState, useRecoilValue } from 'recoil';
import { detailModalActiveTab, detailModalTabs, ifUserHasGeneralPermission } from 'src/state';
import { useWindowSize } from 'src/hooks/component';
import { cloneDeep } from 'lodash';
import { useSetting } from 'src/hooks/api';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { DEFAULT_RATES_TABLE_FRONTEND } from '../FeatureGatingKeyConstant';
import { GeneralPermissionKey } from '../Settings/Permission/Constants';
import ReverseCollectionSettingsDropdown, { getVisibleReverseCollections, sortAllReverseCollections, } from './ReverseCollectionSettingsDropdown';
var TabPane = Tabs.TabPane;
var getAllReverseCollectionsWithBespoke = function (modalData, isProjectTrackingEnabled, hasPayPermission, isDefaultRatesTurnedOn) {
    var _a;
    var mainRecord = modalData.mainRecord, _b = modalData.allReverseCollections, allReverseCollections = _b === void 0 ? [] : _b, shiftRequests = modalData.shiftRequests, projectTracking = modalData.projectTracking, individualPaySettings = modalData.individualPaySettings;
    var allReverseCollectionsWithBespoke = cloneDeep(allReverseCollections);
    var geoTracking = (_a = mainRecord.nonSchemaNativeFieldsMap[mainRecord.rows[0].recordId]) === null || _a === void 0 ? void 0 : _a.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_GEO_TRACKING; });
    if ((geoTracking === null || geoTracking === void 0 ? void 0 : geoTracking.clockInLocation) || (geoTracking === null || geoTracking === void 0 ? void 0 : geoTracking.clockOutLocation)) {
        allReverseCollectionsWithBespoke.push({
            title: 'Shift Tracking',
            collectionComponent: BespokeReverseCollectionComponent.SHIFT_TRACKING_MAP,
            collectionType: CollectionType.SHIFT,
            collectionId: '',
        });
    }
    if (shiftRequests) {
        allReverseCollectionsWithBespoke.push({
            title: 'Shift Requests',
            collectionComponent: BespokeReverseCollectionComponent.SHIFT_REQUESTS,
            collectionType: CollectionType.SHIFT,
            collectionId: '',
        });
    }
    if (isProjectTrackingEnabled && projectTracking) {
        allReverseCollectionsWithBespoke.push({
            title: 'Project Tracking',
            collectionComponent: BespokeReverseCollectionComponent.SHIFT_PROJECT_TRACKING,
            collectionType: CollectionType.SHIFT,
            collectionId: '',
        });
    }
    if (individualPaySettings && !isDefaultRatesTurnedOn) {
        var title = 'Pay Rate';
        allReverseCollectionsWithBespoke.push({
            title: title,
            collectionComponent: BespokeReverseCollectionComponent.USER_PAY_RATE,
            collectionType: CollectionType.USER,
            collectionId: '',
        });
    }
    if (mainRecord.collectionInfo.type === CollectionType.USER) {
        allReverseCollectionsWithBespoke.push({
            title: 'Schedule',
            collectionComponent: BespokeReverseCollectionComponent.USER_SCHEDULE,
            collectionType: CollectionType.USER,
            collectionId: '',
        });
        // Only show default rate if it's turned on and under user collection
        if (isDefaultRatesTurnedOn) {
            allReverseCollectionsWithBespoke.push({
                title: 'Default Rate',
                collectionComponent: BespokeReverseCollectionComponent.USER_PAY_RATE,
                collectionType: CollectionType.USER,
                collectionId: '',
            });
        }
    }
    if (mainRecord.collectionInfo.type === CollectionType.LOCATION) {
        if (hasPayPermission && !isDefaultRatesTurnedOn) {
            allReverseCollectionsWithBespoke.push({
                title: 'Pay Rate',
                collectionComponent: BespokeReverseCollectionComponent.LOCATION_PAY_RATE,
                collectionType: CollectionType.LOCATION,
                collectionId: '',
            });
        }
        allReverseCollectionsWithBespoke.push({
            title: 'Schedule',
            collectionComponent: BespokeReverseCollectionComponent.LOCATION_SCHEDULE,
            collectionType: CollectionType.LOCATION,
            collectionId: '',
        });
    }
    return allReverseCollectionsWithBespoke;
};
var generateTabsCollection = function (reverseCollections, visibleReverseCollectionIds, isBulkEdit) {
    var _a, _b;
    var tabsCollection = [{ id: 'Details', name: 'Details', type: 'details' }];
    if (isBulkEdit)
        return tabsCollection;
    var visibleReverseCollections = getVisibleReverseCollections(sortAllReverseCollections(reverseCollections, visibleReverseCollectionIds), visibleReverseCollectionIds);
    for (var _i = 0, visibleReverseCollections_1 = visibleReverseCollections; _i < visibleReverseCollections_1.length; _i++) {
        var collection = visibleReverseCollections_1[_i];
        tabsCollection.push({
            id: (_b = (_a = collection.schemaId) !== null && _a !== void 0 ? _a : collection.standardRelation) !== null && _b !== void 0 ? _b : collection.collectionComponent,
            name: collection.title,
            type: collection.collectionType,
            recordCount: collection.totalCount,
        });
    }
    return tabsCollection;
};
var renderTab = function (tab) {
    var name = tab.name, _a = tab.recordCount, recordCount = _a === void 0 ? -1 : _a;
    return (_jsxs("div", { className: "flex h-6 items-center px-2 pb-2 pt-1", children: [name, recordCount >= 0 && _jsxs("span", { children: ["\u00A0(", recordCount, ")"] })] }));
};
var RecordDetailModalTabs = function (_a) {
    var _b;
    var recordId = _a.recordId, modalData = _a.modalData, isProjectTrackingEnabled = _a.isProjectTrackingEnabled, isBulkEdit = _a.isBulkEdit;
    var _c = useRecoilState(detailModalActiveTab(recordId)), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useRecoilState(detailModalTabs(recordId)), modalTabs = _d[0], setModalTabs = _d[1];
    var _e = useState((_b = modalData.visibleReverseCollections) !== null && _b !== void 0 ? _b : []), visibleReverseCollectionIds = _e[0], setVisibleReverseCollectionIds = _e[1];
    // For location pay rate
    var useGetPayRatesSetting = useSetting().useGetPayRatesSetting;
    var hasPayPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.PAY));
    var payEnabledStatuses = useGetPayRatesSetting({
        enabled: hasPayPermission &&
            modalData.mainRecord.collectionInfo.type === CollectionType.LOCATION,
    }).data;
    var accountRatesEnabled = (payEnabledStatuses !== null && payEnabledStatuses !== void 0 ? payEnabledStatuses : {}).accountRatesEnabled;
    var isDefaultRatesTurnedOn = useRecoilValue(featureEnableStatusState(DEFAULT_RATES_TABLE_FRONTEND));
    var allReverseCollectionsWithBespoke = useMemo(function () {
        return getAllReverseCollectionsWithBespoke(modalData, isProjectTrackingEnabled, hasPayPermission && !!accountRatesEnabled, isDefaultRatesTurnedOn);
    }, [
        accountRatesEnabled,
        hasPayPermission,
        isProjectTrackingEnabled,
        modalData,
        isDefaultRatesTurnedOn,
    ]);
    useEffect(function () {
        var tabsCollection = generateTabsCollection(allReverseCollectionsWithBespoke, visibleReverseCollectionIds, isBulkEdit);
        setModalTabs(tabsCollection);
    }, [
        allReverseCollectionsWithBespoke,
        isBulkEdit,
        isProjectTrackingEnabled,
        modalData,
        setModalTabs,
        visibleReverseCollectionIds,
    ]);
    useEffect(function () {
        if (modalData.visibleReverseCollections) {
            setVisibleReverseCollectionIds(modalData.visibleReverseCollections);
        }
    }, [modalData.visibleReverseCollections, setVisibleReverseCollectionIds]);
    // Initialize active tab
    useEffect(function () {
        if (!activeTab && modalTabs.length && !modalTabs.find(function (tab) { return tab.id === activeTab; })) {
            setActiveTab(modalTabs[0].id);
        }
    }, [activeTab, modalTabs, setActiveTab]);
    var windowWidth = useWindowSize().windowWidth;
    var modalTabsWidth = useMemo(function () {
        var _a, _b;
        var activitySaveButtonsWidth = (_b = (_a = document.querySelector('.BodyHeaderButtons')) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0;
        return "calc(100% - ".concat(activitySaveButtonsWidth, "px - 8px)"); // 8px is space between settings and activity button
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);
    return (_jsx(Tabs, { activeKey: activeTab, onChange: setActiveTab, className: "ModalTabs", style: { width: modalTabsWidth }, tabBarExtraContent: {
            right: modalData.allReverseCollections &&
                modalData.visibleReverseCollections &&
                modalData.mainRecord.permissions.canEditCollection ? (_jsx(ReverseCollectionSettingsDropdown, { collectionId: modalData.mainRecord.collectionInfo.id, allReverseCollections: allReverseCollectionsWithBespoke, visibleReverseCollectionIds: visibleReverseCollectionIds, setVisibleReverseCollectionIds: setVisibleReverseCollectionIds })) : null,
        }, children: modalTabs.map(function (tab) { return (_jsx(TabPane, { tab: renderTab(tab) }, tab.id)); }) }));
};
export default React.memo(RecordDetailModalTabs);
