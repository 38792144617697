export var LinkedDataReportDateType;
(function (LinkedDataReportDateType) {
    LinkedDataReportDateType[LinkedDataReportDateType["DATE_OF_EXPORT"] = 0] = "DATE_OF_EXPORT";
    LinkedDataReportDateType[LinkedDataReportDateType["SHIFT_DATE"] = 1] = "SHIFT_DATE";
    LinkedDataReportDateType[LinkedDataReportDateType["PAY_PERIOD_DATE_RANGE"] = 2] = "PAY_PERIOD_DATE_RANGE";
    LinkedDataReportDateType[LinkedDataReportDateType["CHOOSE_AT_TIME_OF_EXPORT"] = 3] = "CHOOSE_AT_TIME_OF_EXPORT";
})(LinkedDataReportDateType || (LinkedDataReportDateType = {}));
export var ClaimUserAvailability;
(function (ClaimUserAvailability) {
    ClaimUserAvailability["CONFLICTING"] = "CONFLICTING";
    ClaimUserAvailability["AVAILABLE"] = "AVAILABLE";
})(ClaimUserAvailability || (ClaimUserAvailability = {}));
export var AccessGroupType;
(function (AccessGroupType) {
    AccessGroupType["OWNER"] = "owner";
    AccessGroupType["OTHER"] = "other";
})(AccessGroupType || (AccessGroupType = {}));
export var PsuedoSchemaPath;
(function (PsuedoSchemaPath) {
    PsuedoSchemaPath["JOB_DATE_RANGE"] = "job.date_range";
    PsuedoSchemaPath["JOB_LOCATION_IMAGE"] = "job.location_image";
    PsuedoSchemaPath["PLACEMENT_JOB_LOCATION_IMAGE"] = "placement.job_location_image";
    PsuedoSchemaPath["PLACEMENT_JOB_WEEKLY_PAY"] = "placement.job_weekly_pay";
})(PsuedoSchemaPath || (PsuedoSchemaPath = {}));
export var RecordDataDisplayOptionType;
(function (RecordDataDisplayOptionType) {
    RecordDataDisplayOptionType["SCHEMA"] = "schema";
    RecordDataDisplayOptionType["PSEUDO_SCHEMA"] = "pseudo_schema";
    RecordDataDisplayOptionType["SECONDARY_SCHEMA"] = "secondary_schema";
})(RecordDataDisplayOptionType || (RecordDataDisplayOptionType = {}));
export function isEntry(item) {
    return item.type == 'ENTRY';
}
export function isDescription(item) {
    return item.type == 'DESCRIPTION';
}
export function isImage(item) {
    return item.type == 'IMAGE';
}
export function isUpdateRecordForm(item) {
    return item.type == 'UPDATE_RECORD';
}
export var DefaultRateFileValidationResultType;
(function (DefaultRateFileValidationResultType) {
    DefaultRateFileValidationResultType["FAILED"] = "FAILED";
    DefaultRateFileValidationResultType["VALIDATED"] = "VALIDATED";
    DefaultRateFileValidationResultType["PROCESSED"] = "PROCESSED";
})(DefaultRateFileValidationResultType || (DefaultRateFileValidationResultType = {}));
