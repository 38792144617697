import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import TaskSelectionDropdown from 'src/components/Settings/Tasks/shared/TaskSelectionDropdown';
import { useTasksApi } from 'src/hooks/api/useTasksApi';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { useRecoilValue } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { collectionTableTypeSelector } from 'src/state';
import AssignTaskButton from './AssignTaskButton';
var TaskAssignmentDropdown = function (_a) {
    var taskCollectionId = _a.taskCollectionId, referenceRecordId = _a.referenceRecordId, referenceCollectionId = _a.referenceCollectionId;
    var referenceCollectionType = useRecoilValue(collectionTableTypeSelector({
        type: CollectionTableType.DETAIL_MODAL,
        collectionId: referenceCollectionId,
    }));
    var refetchCollection = useRefetchCollection();
    var useAssignTaskToRecord = useTasksApi().useAssignTaskToRecord;
    var assignTaskGroup = useCallback(function (taskGroup) {
        useAssignTaskToRecord.mutate({
            recordId: referenceRecordId,
            collectionType: referenceCollectionType,
            taskGroupId: taskGroup.id,
        }, {
            onSuccess: function (resp) {
                if (resp) {
                    refetchCollection(taskCollectionId);
                    return;
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceRecordId, referenceCollectionType, taskCollectionId]);
    var assignTask = useCallback(function (task) {
        useAssignTaskToRecord.mutate({
            recordId: referenceRecordId,
            collectionType: referenceCollectionType,
            taskId: task.id,
        }, {
            onSuccess: function (resp) {
                if (resp) {
                    refetchCollection(taskCollectionId);
                    return;
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceRecordId, referenceCollectionType, taskCollectionId]);
    return (_jsx(TaskSelectionDropdown, { selectedTasks: [], onAddTask: assignTask, onAddTaskGroup: assignTaskGroup, children: _jsx(AssignTaskButton, {}) }));
};
export default React.memo(TaskAssignmentDropdown);
