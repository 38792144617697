var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep, isEqual, uniq, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { SelectorCellWrapper } from 'src/components/TableView/TableCell/CustomTableCell';
import TimePicker from 'src/components/TimePicker';
import { CollectionTableType, CollectionType, NativePath, NonSchemaNativePath, RecordSchemaType, ValueDataType, } from 'src/shared';
import { accountIdState, billRateValueShouldManuallyUpdateState, collectionTableModalRecordSelector, collectionTableNonSchemaMutationsState, collectionTableSchemaMutationsState, collectionTableSchemaNativePathMapSelector, collectionTableSchemasState, payRateValueShouldManuallyUpdateState, shiftDetailModalBreakdownInfoState, } from 'src/state';
import { getCurrencyValue, getNativeFieldCellContent, getNativeFieldCellOptions, } from 'src/utils/Collection';
import { LinkOutlinedIcon } from 'src/utils/icons/DropdownIcons/LinkOutlined';
import { getDayOffset, getQualifyingRates } from 'src/utils/Schedule';
import { DEFAULT_RATES_TABLE_FRONTEND, NEW_SHIFT_TEMPLATE, SHIFT_RECOMMENDATIONS_FEATURE, SPECIAL_ACCESS_RECOMMEND_SHIFT, USE_CALCULATED_OT_THROTTLE, } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { shiftRecommendationsModalPropsState } from 'src/components/PageView/ShiftComponents/ShiftRecommendations/atom';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import usePayPreviewApi from 'src/hooks/api/usePayPreviewApi';
import { useAccountApi, useHolidayPayApi, usePageApi, useScheduleApi, useScheduleTemplateApi, useSetting, } from '../../api';
import AccountState from '../../state/Account';
import { useModalRecordComponent } from '../useModalRecord';
import { useRecordComponent } from '../useRecord';
import { useRefetchCollection } from '../useRefetchCollection';
import { useTimeZone } from '../useTimeZone';
import { findShiftSchemaByNativePath, getAllShiftTimeSchemaPermissions, getShiftTimeDetails, getShiftTimeSchemaIds, getTemplateApplyToDate, } from './util';
var CalendarIcon = lazy(function () { return import('@ant-design/icons/CalendarOutlined'); });
var ClockIcon = lazy(function () { return import('@ant-design/icons/ClockCircleOutlined'); });
var UserIcon = lazy(function () { return import('@ant-design/icons/UserOutlined'); });
var useShiftModal = function (_a) {
    var _b, _c;
    var recordId = _a.recordId, dataSource = _a.dataSource, collectionInfo = _a.collectionInfo, isCreatingNew = _a.isCreatingNew, nonSchemaNativeFields = _a.nonSchemaNativeFields, defaultTimeZone = _a.defaultTimeZone;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var schemaList = useRecoilValue(collectionTableSchemasState(dataSource.collectionId));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var schemaMutations = useRecoilValue(collectionTableSchemaMutationsState(dataSource));
    var accountId = useRecoilValue(accountIdState);
    var refetchCollection = useRefetchCollection();
    var _d = useTimeZone(defaultTimeZone), timeZone = _d.timeZone, timeFormatFn = _d.timeFormatFn;
    var _e = useState(), templateShiftRid = _e[0], setTemplateShiftRid = _e[1];
    var _f = useState(false), isHolidayShift = _f[0], setIsHolidayShift = _f[1];
    var _g = useState(undefined), payPreviewData = _g[0], setPayPreviewData = _g[1];
    var isQuickCreate = dataSource.type === CollectionTableType.QUICK_CREATE_MODAL;
    var _h = useRecordComponent(), editRecordCell = _h.editRecordCell, onCreateNewRecord = _h.onCreateNewRecord;
    var _j = useModalRecordComponent(), bulkEditModalCell = _j.bulkEditModalCell, editScheduledShiftTime = _j.editScheduledShiftTime, editClockInOutShiftTime = _j.editClockInOutShiftTime;
    var shiftDetailModalBreakdownInfo = useRecoilValue(shiftDetailModalBreakdownInfoState(recordId));
    // State for new shift template
    var _k = useState(), shiftTemplateData = _k[0], setShiftTemplateData = _k[1];
    var _l = useState(), selectedShiftTemplateId = _l[0], setSelectedShiftTemplateId = _l[1];
    /** Current shift times as ISO strings */
    var shiftTimeDetails = getShiftTimeDetails(collectionInfo.type, modalRecord, nativeSchemaMap);
    var useGetPreview = usePayPreviewApi().useGetPreview;
    /**
     * Shift Detail Rendering
     */
    var renderShiftTimeSchemas = useCallback(function () {
        var startAt = shiftTimeDetails.startAt, endAt = shiftTimeDetails.endAt, clockInAt = shiftTimeDetails.clockInAt, clockOutAt = shiftTimeDetails.clockOutAt;
        var shiftScheduledTimeData = {
            showTime: true,
            from: startAt,
            to: endAt,
            type: ValueDataType.DATETIME_RANGE,
        };
        var shiftClockInOutTimeData = {
            showTime: true,
            from: clockInAt,
            to: clockOutAt,
            type: ValueDataType.DATETIME_RANGE,
        };
        var onEditShiftTime = function (times, isScheduledTime) {
            if (isScheduledTime) {
                editScheduledShiftTime(dataSource.type, schemaList, dataSource.collectionId, recordId, times, timeZone);
            }
            else {
                editClockInOutShiftTime(dataSource.type, schemaList, dataSource.collectionId, recordId, times, timeZone);
            }
        };
        var _a = getAllShiftTimeSchemaPermissions(schemaList, startAt, endAt, clockInAt, clockOutAt), canEditStartAt = _a.canEditStartAt, canEditEndAt = _a.canEditEndAt, canEditClockedIn = _a.canEditClockedIn, canEditClockedOut = _a.canEditClockedOut, canEditScheduledTime = _a.canEditScheduledTime, canEditClockedTime = _a.canEditClockedTime, showNullScheduledTime = _a.showNullScheduledTime, showNullClockedTime = _a.showNullClockedTime;
        var HolidayTag = (_jsx("div", { className: "absolute top-0 right-0 bg-green-50 text-[green] px-[4px] font-medium", children: "Holiday" }));
        var scheduledTimeCell = (_jsx("div", { className: "SchemaRow SpecialTimePicker w-full", children: _jsxs("div", { className: "ModalCellWrapper".concat(!canEditScheduledTime ? ' cursor-default border-white bg-white' : ''), children: [_jsx("div", { className: "CellIcon", style: { left: 16 }, children: _jsx(CalendarIcon, {}) }), _jsx("span", { className: "SchemaName", children: "Scheduled Time" }), _jsx("div", { className: "CellContent", children: _jsx(TimePicker, { data: shiftScheduledTimeData, onEditTime: function (times) { return onEditShiftTime(times, true); }, canEditStartTime: !!canEditStartAt, canEditEndTime: !!canEditEndAt, timeZone: timeZone, updateWithDefault: true, placeHolderText: showNullScheduledTime ? 'N/A' : 'Enter Scheduled Time' }) }), isHolidayShift && shiftScheduledTimeData.from && HolidayTag] }) }, "shift-time-schema-1"));
        var clockedTimeCell = (_jsxs("div", { className: "SchemaRow SpecialTimePicker w-full", children: [_jsxs("div", { className: "ModalCellWrapper".concat(!canEditClockedTime ? ' cursor-default border-white bg-white' : ''), children: [_jsx("div", { className: "CellIcon", style: { left: 16 }, children: _jsx(ClockIcon, {}) }), _jsx("span", { className: "SchemaName", children: "Clock In/Out Time" }), _jsx("div", { className: "CellContent", children: _jsx(TimePicker, { scheduledTimeData: shiftScheduledTimeData, data: shiftClockInOutTimeData, onEditTime: function (times) { return onEditShiftTime(times, false); }, canEditStartTime: !!canEditClockedIn, canEditEndTime: !!canEditClockedOut, timeZone: timeZone, updateWithDefault: true, placeHolderText: showNullClockedTime ? 'N/A' : 'Enter Clock In/Out Time' }) })] }), isHolidayShift &&
                    !shiftScheduledTimeData.from &&
                    shiftClockInOutTimeData.from &&
                    HolidayTag] }, 'shift-time-schema-2'));
        return [scheduledTimeCell, clockedTimeCell];
    }, [
        dataSource.collectionId,
        dataSource.type,
        editClockInOutShiftTime,
        editScheduledShiftTime,
        isHolidayShift,
        recordId,
        schemaList,
        shiftTimeDetails,
        timeZone,
    ]);
    /**
     * Shift Tracking
     */
    var shiftFacePicData = useMemo(function () {
        return nonSchemaNativeFields === null || nonSchemaNativeFields === void 0 ? void 0 : nonSchemaNativeFields.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_FACE_PIC; });
    }, [nonSchemaNativeFields]);
    /**
     * Recurring shift rule
     */
    var saveRecurringRuleAndScheduleShifts = useScheduleApi().saveRecurringRuleAndScheduleShifts;
    var _m = useState((_c = (_b = nonSchemaNativeFields === null || nonSchemaNativeFields === void 0 ? void 0 : nonSchemaNativeFields.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_RECURRING_RULE_ID; })) === null || _b === void 0 ? void 0 : _b.recurringRule) === null || _c === void 0 ? void 0 : _c.recurrenceDetails), recurringShiftData = _m[0], setRecurringShiftData = _m[1];
    var saveRecurringRule = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var oldRecurringRule;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    oldRecurringRule = (_a = nonSchemaNativeFields === null || nonSchemaNativeFields === void 0 ? void 0 : nonSchemaNativeFields.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_RECURRING_RULE_ID; })) === null || _a === void 0 ? void 0 : _a.recurringRule;
                    if (!!isEqual(recurringShiftData, oldRecurringRule)) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveRecurringRuleAndScheduleShifts({
                            shiftRid: recordId,
                            recurringRuleId: oldRecurringRule === null || oldRecurringRule === void 0 ? void 0 : oldRecurringRule.id,
                            recurringDetails: recurringShiftData,
                        })];
                case 1:
                    _b.sent();
                    setTimeout(function () {
                        refetchCollection(collectionInfo.id);
                    }, 100);
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [
        collectionInfo.id,
        nonSchemaNativeFields,
        recordId,
        recurringShiftData,
        refetchCollection,
        saveRecurringRuleAndScheduleShifts,
    ]);
    /**
     * Edit recurring shift
     */
    var editRecurringShift = useScheduleApi().editRecurringShift;
    var editRecurringShiftWithType = useCallback(function (recurringShiftEditType, schemaProperties) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editRecurringShift({
                        shiftRid: recordId,
                        recurringShiftEditType: recurringShiftEditType,
                        schemaProperties: schemaProperties,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [editRecurringShift, recordId]);
    /**
     * Shift Assignment
     */
    var useGetAutoSchedulerSetting = useAccountApi().useGetAutoSchedulerSetting;
    var _o = AccountState.useContainer(), autoSchedulerSetting = _o.autoSchedulerSetting, setAutoSchedulerSetting = _o.setAutoSchedulerSetting;
    var needFetchAutoSchedulerSetting = useMemo(function () { return !autoSchedulerSetting && collectionInfo.type === CollectionType.SHIFT; }, [autoSchedulerSetting, collectionInfo.type]);
    var autoSchedulerSettingData = useGetAutoSchedulerSetting({
        options: {
            enabled: needFetchAutoSchedulerSetting,
        },
    }).data;
    useEffect(function () {
        if (needFetchAutoSchedulerSetting && autoSchedulerSettingData) {
            setAutoSchedulerSetting(autoSchedulerSettingData);
        }
    }, [autoSchedulerSettingData, needFetchAutoSchedulerSetting, setAutoSchedulerSetting]);
    /**
     * Shift payrate matching
     */
    var isDefaultRatesTurnedOn = useRecoilValue(featureEnableStatusState(DEFAULT_RATES_TABLE_FRONTEND));
    var isCalculatedOvertimeThrottleOn = useRecoilValue(featureEnableStatusState(USE_CALCULATED_OT_THROTTLE));
    var _p = useState(), wageData = _p[0], setWageData = _p[1];
    var _q = useState(isCreatingNew), shouldUpdateShiftRates = _q[0], setShouldUpdateShiftRates = _q[1];
    var shiftPayRateSchema = nativeSchemaMap[NativePath.SHIFT_WAGE];
    var shiftBillRateSchema = nativeSchemaMap[NativePath.SHIFT_BILL_RATE];
    var payRateCellPosInfo = useMemo(function () {
        var _a;
        return {
            recordId: recordId,
            schemaId: (_a = shiftPayRateSchema === null || shiftPayRateSchema === void 0 ? void 0 : shiftPayRateSchema.id) !== null && _a !== void 0 ? _a : '',
            collectionId: collectionInfo.id,
        };
    }, [collectionInfo.id, recordId, shiftPayRateSchema === null || shiftPayRateSchema === void 0 ? void 0 : shiftPayRateSchema.id]);
    var billRateCellPosInfo = useMemo(function () {
        var _a;
        return {
            collectionId: collectionInfo.id,
            recordId: recordId,
            schemaId: (_a = shiftBillRateSchema === null || shiftBillRateSchema === void 0 ? void 0 : shiftBillRateSchema.id) !== null && _a !== void 0 ? _a : '',
        };
    }, [collectionInfo.id, recordId, shiftBillRateSchema === null || shiftBillRateSchema === void 0 ? void 0 : shiftBillRateSchema.id]);
    var setPayRateValueShouldManuallyUpdate = useSetRecoilState(payRateValueShouldManuallyUpdateState(payRateCellPosInfo));
    var setBillRateValueShouldManuallyUpdate = useSetRecoilState(billRateValueShouldManuallyUpdateState(billRateCellPosInfo));
    var canApplyRematchOnPayRate = useMemo(function () {
        var _a;
        var item = shiftDetailModalBreakdownInfo.find(function (item) { return item.schemaId === (shiftPayRateSchema === null || shiftPayRateSchema === void 0 ? void 0 : shiftPayRateSchema.id); });
        return (_a = item === null || item === void 0 ? void 0 : item.shouldReapply) !== null && _a !== void 0 ? _a : true;
    }, [shiftDetailModalBreakdownInfo, shiftPayRateSchema === null || shiftPayRateSchema === void 0 ? void 0 : shiftPayRateSchema.id]);
    var canApplyRematchOnBillRate = useMemo(function () {
        var _a;
        var item = shiftDetailModalBreakdownInfo.find(function (item) { return item.schemaId === (shiftBillRateSchema === null || shiftBillRateSchema === void 0 ? void 0 : shiftBillRateSchema.id); });
        return (_a = item === null || item === void 0 ? void 0 : item.shouldReapply) !== null && _a !== void 0 ? _a : true;
    }, [shiftDetailModalBreakdownInfo, shiftBillRateSchema === null || shiftBillRateSchema === void 0 ? void 0 : shiftBillRateSchema.id]);
    var currentShiftUserId = useMemo(function () {
        var _a, _b, _c, _d;
        var shiftUserSchemaId = (_a = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_USER_ID)) === null || _a === void 0 ? void 0 : _a.id;
        return shiftUserSchemaId ? ((_d = (_c = (_b = modalRecord[shiftUserSchemaId]) === null || _b === void 0 ? void 0 : _b.options[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '') : '';
    }, [modalRecord, schemaList]);
    var currentShiftLocationRid = useMemo(function () {
        var _a, _b, _c;
        var shiftLocationSchemaId = (_a = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_LOCATION_ID)) === null || _a === void 0 ? void 0 : _a.id;
        return shiftLocationSchemaId
            ? (_c = (_b = modalRecord[shiftLocationSchemaId]) === null || _b === void 0 ? void 0 : _b.options[0]) === null || _c === void 0 ? void 0 : _c.id
            : undefined;
    }, [modalRecord, schemaList]);
    // Use account wages for unassigned shift; Use user wages for assigned shift
    var _r = useSetting(), useGetAccountPayRateSettingData = _r.useGetAccountPayRateSettingData, useGetUserPayRateSettingData = _r.useGetUserPayRateSettingData;
    var useGetLocationHolidayMultipliers = useHolidayPayApi().useGetLocationHolidayMultipliers;
    // TODO: Deprecate this fully when isCalculatedOvertimeThrottleOn is fully rolled out
    var isFetchingAccountWages = useGetAccountPayRateSettingData({
        enabled: collectionInfo.type === CollectionType.SHIFT &&
            !currentShiftUserId &&
            !isDefaultRatesTurnedOn,
        onSuccess: function (accountPaySettings) {
            setWageData(accountPaySettings === null || accountPaySettings === void 0 ? void 0 : accountPaySettings.wages);
        },
    }).isFetching;
    useGetPreview({
        body: {
            shiftStartAt: shiftTimeDetails.startAt || shiftTimeDetails.clockInAt,
            shiftRid: recordId ? recordId : undefined,
            shiftLocationRid: currentShiftLocationRid,
            zoneId: timeZone,
        },
        options: {
            enabled: collectionInfo.type === CollectionType.SHIFT && isCalculatedOvertimeThrottleOn,
            onSuccess: function (data) {
                setIsHolidayShift((data === null || data === void 0 ? void 0 : data.isHoliday) || false);
                setPayPreviewData(data);
            },
        },
    });
    // TODO: Deprecate this fully when isCalculatedOvertimeThrottleOn is fully rolled out
    var _s = useGetUserPayRateSettingData(currentShiftUserId, {
        enabled: collectionInfo.type === CollectionType.SHIFT &&
            !!currentShiftUserId &&
            !isDefaultRatesTurnedOn &&
            !isCalculatedOvertimeThrottleOn,
        onSuccess: function (userWageResponse) {
            setWageData(userWageResponse === null || userWageResponse === void 0 ? void 0 : userWageResponse.rates);
        },
    }), userWages = _s.data, isFetchingUserWages = _s.isFetching;
    var isFetchingWageData = isFetchingAccountWages || isFetchingUserWages;
    // TODO: Deprecate when isDefaultRatesTurnedOn is fully rolled out
    var locationHolidayMultipliers = useGetLocationHolidayMultipliers({
        body: {
            locationRid: currentShiftLocationRid,
        },
        options: {
            enabled: collectionInfo.type === CollectionType.SHIFT &&
                !isFetchingWageData &&
                ((wageData === null || wageData === void 0 ? void 0 : wageData.length) || 0) > 0 &&
                !isDefaultRatesTurnedOn,
            onSuccess: function (holidayPayResponse) {
                var isHoliday = getQualifyingRates(modalRecord, wageData || [], (holidayPayResponse === null || holidayPayResponse === void 0 ? void 0 : holidayPayResponse.holidayMultipliers) || [], shiftTimeDetails, timeZone).isHoliday;
                setIsHolidayShift(isHoliday);
            },
        },
    }).data;
    var _t = useMemo(function () {
        var qualifyingSchemaIds = [];
        var qualifyingValueIds = [];
        wageData === null || wageData === void 0 ? void 0 : wageData.forEach(function (wage) {
            Object.values(wage.qualifiers).forEach(function (qualifier) {
                var _a;
                if (qualifier.type === 'TIME') {
                    qualifyingSchemaIds.push(qualifier.schemaId);
                }
                else if (qualifier.type === 'COLLECTION') {
                    var schemaId = (_a = schemaList.find(function (schema) { return schema.refCollectionId === qualifier.collectionId; })) === null || _a === void 0 ? void 0 : _a.id;
                    if (schemaId) {
                        qualifyingSchemaIds.push(schemaId);
                    }
                }
                else {
                    qualifyingValueIds.push(qualifier.selectionOptionId);
                }
            });
        });
        return {
            qualifyingSchemaIds: uniq(qualifyingSchemaIds),
            qualifyingValueIds: uniq(qualifyingValueIds),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wageData]), qualifyingSchemaIds = _t.qualifyingSchemaIds, qualifyingValueIds = _t.qualifyingValueIds;
    // Update pay/bill rate on initial new record and assignee edit(due to differing account and user wages)
    useEffect(function () {
        if (wageData && (isCreatingNew || schemaMutations.length)) {
            setShouldUpdateShiftRates(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentShiftUserId]);
    //! Update pay/bill rate if qualifying column updated (only on qualifying value match for custom select since we do not know schemaId)
    useEffect(function () {
        var _a, _b, _c, _d;
        // Skip rate rematching if default rates feature is turned on
        if (isDefaultRatesTurnedOn)
            return;
        if (!schemaMutations.length || collectionInfo.type !== CollectionType.SHIFT)
            return;
        var latestMutation = schemaMutations[schemaMutations.length - 1];
        // Check edit to qualifying custom select values
        for (var _i = 0, qualifyingValueIds_1 = qualifyingValueIds; _i < qualifyingValueIds_1.length; _i++) {
            var valueId = qualifyingValueIds_1[_i];
            if (latestMutation.uuid === valueId ||
                (latestMutation.uuids
                    ? ((_a = latestMutation.uuids) === null || _a === void 0 ? void 0 : _a.includes(valueId)) ||
                        latestMutation.uuids === valueId ||
                        ((_b = latestMutation.uuids) === null || _b === void 0 ? void 0 : _b.displayValue) === valueId
                    : false)) {
                setShouldUpdateShiftRates(true);
                break;
            }
        }
        // Check edit to qualifying collection schema
        for (var _e = 0, qualifyingSchemaIds_1 = qualifyingSchemaIds; _e < qualifyingSchemaIds_1.length; _e++) {
            var schemaId = qualifyingSchemaIds_1[_e];
            if (latestMutation.schemaId === schemaId) {
                setShouldUpdateShiftRates(true);
                break;
            }
        }
        // Check edit to start time and clock in time for holiday pay rate matching
        if (locationHolidayMultipliers &&
            // Start time edited
            (latestMutation.schemaId === ((_c = nativeSchemaMap[NativePath.SHIFT_START_AT]) === null || _c === void 0 ? void 0 : _c.id) ||
                // Clock in edited (only if start time is not set since start takes precedence)
                (!shiftTimeDetails.startAt &&
                    latestMutation.schemaId === ((_d = nativeSchemaMap[NativePath.SHIFT_CLOCK_IN]) === null || _d === void 0 ? void 0 : _d.id)))) {
            setShouldUpdateShiftRates(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaMutations, qualifyingSchemaIds, qualifyingValueIds]);
    var applyWageId = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (wageId) {
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                var temp = cloneDeep(prev);
                var shiftWageIndex = temp.findIndex(function (_a) {
                    var schema = _a.schema;
                    return schema === NonSchemaNativePath.SHIFT_ACCOUNT_WAGE_ID ||
                        schema === NonSchemaNativePath.SHIFT_INDIV_WAGE_ID;
                });
                if (shiftWageIndex > -1) {
                    temp.splice(shiftWageIndex, 1);
                }
                return __spreadArray(__spreadArray([], temp, true), [
                    {
                        schema: currentShiftUserId && (userWages === null || userWages === void 0 ? void 0 : userWages.type) === 'INDIVIDUAL'
                            ? NonSchemaNativePath.SHIFT_INDIV_WAGE_ID
                            : NonSchemaNativePath.SHIFT_ACCOUNT_WAGE_ID,
                        uuid: wageId,
                    },
                ], false);
            });
        };
    }, [currentShiftUserId, dataSource, userWages === null || userWages === void 0 ? void 0 : userWages.type]);
    // Set timezone
    var setTimeZoneId = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (timeZoneId) {
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.SHIFT_TIME_ZONE_ID,
                        zoneId: timeZoneId,
                    }
                ], prev, true), 'schema');
            });
        };
    }, [dataSource]);
    // Set qualifying pay/bill rate
    useEffect(function () {
        // Skip rate rematching if default rates feature is turned on
        if (isDefaultRatesTurnedOn || isCalculatedOvertimeThrottleOn)
            return;
        if (shouldUpdateShiftRates && !isFetchingWageData) {
            if (!(wageData === null || wageData === void 0 ? void 0 : wageData.length) || !locationHolidayMultipliers)
                return;
            if ((!isQuickCreate && shiftPayRateSchema) ||
                (isQuickCreate && (shiftPayRateSchema === null || shiftPayRateSchema === void 0 ? void 0 : shiftPayRateSchema.isQuickCreate)) ||
                (!isQuickCreate && shiftBillRateSchema) ||
                (isQuickCreate && (shiftBillRateSchema === null || shiftBillRateSchema === void 0 ? void 0 : shiftBillRateSchema.isQuickCreate))) {
                setShouldUpdateShiftRates(false);
                var _a = getQualifyingRates(modalRecord, wageData, locationHolidayMultipliers.holidayMultipliers, shiftTimeDetails, timeZone), payRate = _a.payRate, billRate = _a.billRate, wageId = _a.wageId, isHoliday = _a.isHoliday;
                if (!wageId)
                    return;
                setIsHolidayShift(isHoliday);
                if (shiftPayRateSchema && canApplyRematchOnPayRate) {
                    setPayRateValueShouldManuallyUpdate(false);
                    editRecordCell(dataSource.type, {
                        collectionId: collectionInfo.id,
                        recordId: recordId,
                        schemaId: shiftPayRateSchema.id,
                    }, [String(payRate)], undefined, shiftPayRateSchema.canEdit ||
                        (shiftPayRateSchema.canOnlyUpdateEmpty && isCreatingNew)
                        ? false
                        : true);
                }
                if (shiftBillRateSchema && canApplyRematchOnBillRate) {
                    setBillRateValueShouldManuallyUpdate(false);
                    editRecordCell(dataSource.type, {
                        collectionId: collectionInfo.id,
                        recordId: recordId,
                        schemaId: shiftBillRateSchema.id,
                    }, [String(billRate)], undefined, shiftBillRateSchema.canEdit ||
                        (shiftBillRateSchema.canOnlyUpdateEmpty && isCreatingNew)
                        ? false
                        : true);
                }
                applyWageId(wageId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shouldUpdateShiftRates,
        isCreatingNew,
        isFetchingWageData,
        schemaList,
        timeZone,
        locationHolidayMultipliers,
        shiftTimeDetails,
        canApplyRematchOnBillRate,
        canApplyRematchOnPayRate,
    ]);
    // Set shift timezone
    useEffect(function () {
        if (collectionInfo.type === CollectionType.SHIFT && isCreatingNew) {
            setTimeZoneId(defaultTimeZone);
        }
    }, [collectionInfo.type, isCreatingNew, setTimeZoneId, defaultTimeZone, recordId]);
    /**
     * Shift Duplicate
     */
    var postDuplicateShifts = useScheduleApi().postDuplicateShifts;
    var _u = useState({}), duplicateData = _u[0], setDuplicateData = _u[1];
    var duplicateShifts = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var release, shiftStartTime, offsets, date, dayOffset;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        shiftStartTime = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_START_AT);
                        offsets = [];
                        for (date in duplicateData) {
                            dayOffset = getDayOffset(moment(moment(shiftStartTime).tz(timeZone).format('YYYY-MM-DD')), moment(date));
                            offsets.push({
                                days: dayOffset,
                                copies: duplicateData[date],
                            });
                        }
                        return [4 /*yield*/, postDuplicateShifts({
                                accountId: accountId,
                                shiftRid: recordId,
                                offsets: offsets,
                                collectionId: collectionInfo.id,
                                collectionType: collectionInfo.type,
                                schemaOverrides: [],
                                nonSchemaOverrides: [],
                                timezone: timeZone,
                            })];
                    case 2:
                        _a.sent();
                        setTimeout(function () {
                            refetchCollection(collectionInfo.id);
                        }, 100);
                        return [3 /*break*/, 4];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, [
        accountId,
        collectionInfo.id,
        collectionInfo.type,
        duplicateData,
        modalRecord,
        nativeSchemaMap,
        postDuplicateShifts,
        recordId,
        refetchCollection,
        timeZone,
    ]);
    var getDuplicateFocusDate = useMemo(function () {
        var startAt = shiftTimeDetails.startAt;
        return timeFormatFn(startAt, 'MMM Do YYYY', false);
    }, [shiftTimeDetails, timeFormatFn]);
    var useGetProjectTrackingSetting = useSetting().useGetProjectTrackingSetting;
    var isProjectTrackingEnabled = useGetProjectTrackingSetting().data;
    var recommendShiftsFeatureEnabled = useRecoilValue(featureEnableStatusState(SHIFT_RECOMMENDATIONS_FEATURE));
    var allowSpecialAccessToRecommendShiftsFeature = useRecoilValue(featureEnableStatusState(SPECIAL_ACCESS_RECOMMEND_SHIFT));
    var setShiftRecommendationsPropsState = useSetRecoilState(shiftRecommendationsModalPropsState);
    var renderShiftAssigneeSchemaCell = useCallback(function () {
        var _a;
        var shiftAssigneeSchema = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_USER_ID);
        if (!shiftAssigneeSchema)
            return _jsx(_Fragment, {});
        var assigneeRefCollectionId = shiftAssigneeSchema.refCollectionId;
        var assigneeValue = (_a = modalRecord[shiftAssigneeSchema.id]) === null || _a === void 0 ? void 0 : _a.options[0];
        var startAt = shiftTimeDetails.startAt, endAt = shiftTimeDetails.endAt;
        var published = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_PUBLISHED) ===
            'true';
        var renderRecommendShiftBox = recommendShiftsFeatureEnabled &&
            (shiftAssigneeSchema.canEdit || allowSpecialAccessToRecommendShiftsFeature) &&
            startAt &&
            endAt;
        // Follow the same canEdit logic as other modal cells, ref: canEditCell func in util
        var canEditAssignee = shiftAssigneeSchema.canEdit &&
            (!shiftAssigneeSchema.canOnlyUpdateEmpty || isCreatingNew || !assigneeValue);
        return (_jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "ModalCellWrapper bg-white", tabIndex: 0, children: [_jsx("div", { className: "CellIcon", style: { left: 16 }, children: _jsx(UserIcon, {}) }), _jsx("span", { className: "SchemaName", children: shiftAssigneeSchema.name }), _jsx("div", { className: "CellContent", children: _jsx(SelectorCellWrapper, { schema: shiftAssigneeSchema, editCell: function () { }, canEdit: canEditAssignee, defaultDisplayOptions: assigneeValue
                                    ? [
                                        {
                                            id: assigneeValue.id,
                                            text: assigneeValue.name,
                                            color: '',
                                            userAvatar: assigneeValue.avatarUrl,
                                        },
                                    ]
                                    : [], defaultAvailableOptions: [], detailModalProps: {
                                    isDetailModal: true,
                                    modalRecord: modalRecord,
                                    schemaList: schemaList,
                                    updateWithDefault: true,
                                    onEditModalCell: function (newValues) {
                                        return editRecordCell(dataSource.type, {
                                            collectionId: collectionInfo.id,
                                            recordId: recordId,
                                            schemaId: shiftAssigneeSchema.id,
                                        }, newValues);
                                    },
                                }, recordId: recordId, columnId: shiftAssigneeSchema.id, timeZone: timeZone, allowAddNewAssignee: function () {
                                    return onCreateNewRecord('', assigneeRefCollectionId);
                                } }) })] }), renderRecommendShiftBox && (_jsxs("div", { className: "ModalCellWrapper flex items-center bg-gray-50 pl-[48px]", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-xs", children: "Recommend Staff" }), _jsx("span", { className: "text-xs text-gray-400", children: "Send this shift to one or more staff" })] }), _jsx(ZiraTooltip, { title: !published ? 'Only published shifts can be recommended' : null, children: _jsx("div", { className: "ml-auto cursor-pointer h-[24px] px-[16px] mr-[32px] bg-white text-xs text-black flex items-center rounded-xl border border-gray-100", onClick: function (e) {
                                    if (!published)
                                        return;
                                    e.stopPropagation();
                                    setShiftRecommendationsPropsState({
                                        recordId: recordId,
                                        startAt: shiftTimeDetails.startAt,
                                        endAt: shiftTimeDetails.endAt,
                                    });
                                }, style: !published ? { cursor: 'not-allowed', opacity: 0.5 } : undefined, children: "Recommend" }) })] }))] }));
    }, [
        schemaList,
        modalRecord,
        shiftTimeDetails,
        nativeSchemaMap,
        recommendShiftsFeatureEnabled,
        isCreatingNew,
        recordId,
        timeZone,
        editRecordCell,
        dataSource.type,
        collectionInfo.id,
        onCreateNewRecord,
        setShiftRecommendationsPropsState,
        allowSpecialAccessToRecommendShiftsFeature,
    ]);
    var renderShiftClientSchemaCell = useCallback(function () {
        var _a, _b;
        var contactSchema = schemaList.find(function (_a) {
            var type = _a.type;
            return type === ValueDataType.CONTACT;
        });
        if (!contactSchema)
            return _jsx(_Fragment, {});
        var values = (_b = (_a = modalRecord[contactSchema.id]) === null || _a === void 0 ? void 0 : _a.options.map(function (value) {
            return {
                id: value.id,
                text: value.name,
                color: '',
            };
        })) !== null && _b !== void 0 ? _b : [];
        return (_jsxs("div", { className: "ModalCellWrapper bg-white", tabIndex: 0, children: [_jsx("div", { className: "CellIcon", style: { left: 13 }, children: _jsx(LinkOutlinedIcon, {}) }), _jsx("span", { className: "SchemaName", children: contactSchema.name }), _jsx("div", { className: "CellContent", children: _jsx(SelectorCellWrapper, { schema: contactSchema, editCell: function () { }, canEdit: contactSchema.canEdit, defaultDisplayOptions: values, defaultAvailableOptions: [], detailModalProps: {
                            isDetailModal: true,
                            modalRecord: modalRecord,
                            schemaList: schemaList,
                            updateWithDefault: true,
                            onEditModalCell: function (newValues) {
                                return editRecordCell(dataSource.type, {
                                    collectionId: collectionInfo.id,
                                    recordId: recordId,
                                    schemaId: contactSchema.id,
                                }, newValues);
                            },
                        }, recordId: recordId, columnId: contactSchema.id, timeZone: timeZone }) })] }));
    }, [
        collectionInfo.id,
        dataSource.type,
        editRecordCell,
        modalRecord,
        recordId,
        schemaList,
        timeZone,
    ]);
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    /**
     * New shift template get and apply
     */
    var _v = useState(''), applyToDate = _v[0], setApplyToDate = _v[1];
    var useGetShiftTemplateDetail = useScheduleTemplateApi().useGetShiftTemplateDetail;
    // Get template detail when select template
    var shiftTemplateDetail = useGetShiftTemplateDetail({
        body: {
            templateId: selectedShiftTemplateId !== null && selectedShiftTemplateId !== void 0 ? selectedShiftTemplateId : '',
            date: applyToDate,
            timezone: timeZone,
        },
        options: {
            enabled: isNewShiftTemplateEnabled && !!selectedShiftTemplateId && !!applyToDate,
            cacheTime: 0,
        },
    }).data;
    var applyTemplateShiftToRecordDetail = useCallback(function (templateModalRecord) {
        var _a, _b, _c;
        var _d = getShiftTimeSchemaIds(schemaList), scheduledStartSchemaId = _d.scheduledStartSchemaId, scheduledEndSchemaId = _d.scheduledEndSchemaId, clockInSchemaId = _d.clockInSchemaId, clockOutSchemaId = _d.clockOutSchemaId;
        if (!scheduledStartSchemaId || !scheduledEndSchemaId)
            return;
        var oldStartAt = (_a = modalRecord[scheduledStartSchemaId].content) !== null && _a !== void 0 ? _a : (clockInSchemaId ? (_b = modalRecord[clockInSchemaId]) === null || _b === void 0 ? void 0 : _b.content : undefined);
        var newStartAt = templateModalRecord[scheduledStartSchemaId].content;
        if (!newStartAt)
            return;
        // Add template start time to current start date
        var newStart = moment(newStartAt).date(moment(oldStartAt).date());
        var newEndAt = templateModalRecord[scheduledEndSchemaId].content;
        if (!newEndAt)
            return;
        var newEndAtMoment = moment(newEndAt).add(newStart.isAfter(moment(newEndAt)) ? 1 : 0, 'days');
        var shiftDurationMinutes = Math.abs(newStart.diff(newEndAtMoment, 'minutes'));
        var newEnd = newStart.clone().add(shiftDurationMinutes, 'minutes');
        // Apply template start/end time
        bulkEditModalCell(dataSource.type, [
            {
                collectionId: collectionInfo.id,
                recordId: recordId,
                schemaId: scheduledStartSchemaId,
            },
            {
                collectionId: collectionInfo.id,
                recordId: recordId,
                schemaId: scheduledEndSchemaId,
            },
        ], [[newStart.toISOString()], [newEnd.toISOString()]]);
        var _loop_1 = function (schemaId, newCell) {
            if (![
                scheduledStartSchemaId,
                scheduledEndSchemaId,
                clockInSchemaId,
                clockOutSchemaId,
            ].includes(schemaId)) {
                var isCurrency = ((_c = schemaList.find(function (schema) { return schema.id === schemaId; })) === null || _c === void 0 ? void 0 : _c.type) ===
                    ValueDataType.NATIVE_CURRENCY;
                editRecordCell(dataSource.type, {
                    collectionId: collectionInfo.id,
                    recordId: recordId,
                    schemaId: schemaId,
                }, newCell.content
                    ? [
                        isCurrency && newCell.content
                            ? getCurrencyValue(newCell.content)
                            : newCell.content,
                    ]
                    : newCell.options.length
                        ? newCell.options
                        : newCell.options);
            }
        };
        // Apply all other template values
        for (var _i = 0, _e = Object.entries(templateModalRecord); _i < _e.length; _i++) {
            var _f = _e[_i], schemaId = _f[0], newCell = _f[1];
            _loop_1(schemaId, newCell);
        }
    }, [
        bulkEditModalCell,
        collectionInfo.id,
        dataSource.type,
        editRecordCell,
        modalRecord,
        recordId,
        schemaList,
    ]);
    useEffect(function () {
        if (shiftTemplateDetail) {
            applyTemplateShiftToRecordDetail(Object.values(shiftTemplateDetail.columns)[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftTemplateDetail, timeZone]);
    // Get existing template detail when open modal
    var currentTemplateInfo = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_TEMPLATE_ID)[0];
    useEffect(function () {
        if (currentTemplateInfo) {
            var existingShiftTemplateDetail = currentTemplateInfo;
            setShiftTemplateData({
                id: existingShiftTemplateDetail.id,
                name: existingShiftTemplateDetail.name,
                color: existingShiftTemplateDetail.color,
                status: existingShiftTemplateDetail.status,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTemplateInfo]);
    var onSelectShiftTemplate = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (templateId) {
            var _a;
            var shiftTemplateSchemaId = (_a = nativeSchemaMap[NativePath.SHIFT_TEMPLATE_ID]) === null || _a === void 0 ? void 0 : _a.id;
            if (!shiftTemplateSchemaId)
                return;
            set(collectionTableSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schemaId: shiftTemplateSchemaId,
                        type: RecordSchemaType.UUID,
                        uuid: templateId,
                    }
                ], prev, true), 'schemaId');
            });
        };
    }, [dataSource, nativeSchemaMap]);
    var onDetachExistingTemplate = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            var _a;
            var shiftTemplateSchemaId = (_a = nativeSchemaMap[NativePath.SHIFT_TEMPLATE_ID]) === null || _a === void 0 ? void 0 : _a.id;
            if (!shiftTemplateSchemaId)
                return;
            set(collectionTableSchemaMutationsState(dataSource), function (prev) {
                var temp = cloneDeep(prev);
                var shiftTemplateIndex = temp.findIndex(function (_a) {
                    var schemaId = _a.schemaId;
                    return schemaId === shiftTemplateSchemaId;
                });
                if (shiftTemplateIndex > -1) {
                    temp.splice(shiftTemplateIndex, 1);
                }
                return __spreadArray(__spreadArray([], temp, true), [
                    {
                        schemaId: shiftTemplateSchemaId,
                        type: RecordSchemaType.UUID,
                        uuid: null,
                    },
                ], false);
            });
            setShiftTemplateData(undefined);
        };
    }, [dataSource, nativeSchemaMap]);
    var applyShiftTemplateFields = useCallback(function (templateInfo) { return __awaiter(void 0, void 0, void 0, function () {
        var templateApplyDate;
        return __generator(this, function (_a) {
            templateApplyDate = getTemplateApplyToDate(schemaList, modalRecord);
            setShiftTemplateData(templateInfo);
            setSelectedShiftTemplateId(templateInfo.id);
            setApplyToDate(templateApplyDate);
            onSelectShiftTemplate(templateInfo.id);
            return [2 /*return*/];
        });
    }); }, [modalRecord, onSelectShiftTemplate, schemaList]);
    /**
     * Old shift template get and apply
     */
    var applyTemplateFields = useCallback(function (templateId) { return __awaiter(void 0, void 0, void 0, function () {
        var templateApplyDate;
        return __generator(this, function (_a) {
            templateApplyDate = getTemplateApplyToDate(schemaList, modalRecord);
            setTemplateShiftRid(templateId);
            setApplyToDate(templateApplyDate);
            return [2 /*return*/];
        });
    }); }, [modalRecord, schemaList]);
    var usePostCollectionTemplateRecordDetail = usePageApi().usePostCollectionTemplateRecordDetail;
    var templateModalData = usePostCollectionTemplateRecordDetail({
        body: {
            templateId: templateShiftRid,
            applyToDate: applyToDate,
            applyToZone: timeZone,
        },
        options: {
            enabled: !isNewShiftTemplateEnabled && templateShiftRid != undefined && applyToDate != '',
            cacheTime: 0,
        },
    }).data;
    // TODO: Remove useEffect and put it as a onSuccess option in usePostCollectionTemplateRecordDetail
    useEffect(function () {
        if (templateModalData) {
            applyTemplateShiftToRecordDetail(Object.values(templateModalData.cells)[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateModalData, timeZone]);
    return {
        shiftTimeDetails: shiftTimeDetails,
        renderShiftTimeSchemas: renderShiftTimeSchemas,
        shiftFacePicData: shiftFacePicData,
        duplicateData: duplicateData,
        setDuplicateData: setDuplicateData,
        getDuplicateFocusDate: getDuplicateFocusDate,
        duplicateShifts: duplicateShifts,
        isProjectTrackingEnabled: isProjectTrackingEnabled,
        templateShiftRid: templateShiftRid,
        applyTemplateFields: applyTemplateFields,
        renderShiftAssigneeSchemaCell: renderShiftAssigneeSchemaCell,
        renderShiftClientSchemaCell: renderShiftClientSchemaCell,
        recurringShiftData: recurringShiftData,
        setRecurringShiftData: setRecurringShiftData,
        saveRecurringRule: saveRecurringRule,
        editRecurringShiftWithType: editRecurringShiftWithType,
        shiftTemplateData: shiftTemplateData,
        applyShiftTemplateFields: applyShiftTemplateFields,
        onDetachExistingTemplate: onDetachExistingTemplate,
        payPreviewData: payPreviewData,
    };
};
export { useShiftModal as useShiftModalComponent };
