import { atom, atomFamily } from 'recoil';
import { localStorageEffect } from './localStorageEffects';
var detailModalInstancesState = atom({
    key: 'detailModalInstancesState',
    default: [],
});
var detailModalActiveTab = atomFamily({
    key: 'detailModalActiveTab',
    default: '',
});
var detailModalTabs = atomFamily({
    key: 'detailModalTabs',
    default: [],
});
var detailModalIsFeedVisibleState = atom({
    key: 'detailModalIsFeedVisibleState',
    default: true,
    effects: [localStorageEffect('detailModalIsFeedVisibleState')],
});
var existingDetailModalInfo = atom({
    key: 'existingDetailModalInfo',
    default: undefined,
});
var detailModalSchemaGroups = atomFamily({
    key: 'detailModalSchemaGroups',
    default: [],
});
var feedSiderActiveTabState = atomFamily({
    key: 'feedSiderActiveTabState',
    default: undefined,
});
var tasksReverseCollectionViewState = atom({
    key: 'tasksReverseCollectionViewState',
    default: 'table',
    effects: [localStorageEffect('tasksReverseCollectionViewState')],
});
var shiftDetailModalBreakdownInfoState = atomFamily({
    key: 'shiftDetailModalBreakdownInfo',
    default: [],
});
export { detailModalInstancesState, detailModalActiveTab, existingDetailModalInfo, detailModalSchemaGroups, detailModalTabs, detailModalIsFeedVisibleState, feedSiderActiveTabState, tasksReverseCollectionViewState, shiftDetailModalBreakdownInfoState, };
