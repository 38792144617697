var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useRecordDetail } from 'src/hooks/component';
import { detailModalInstancesState } from 'src/state';
import RecordDetailModalWrapper from './RecordDetailModalWrapper';
var RecordDetailModalViewContainer = function () {
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var detailModalInstances = useRecoilValue(detailModalInstancesState);
    var location = useLocation();
    var recordId = new URLSearchParams(location.search).get('rid');
    useEffect(function () {
        // Aka when a user loads a link directly to a record detail
        var isMountingOnPageLoad = !!recordId && detailModalInstances.length === 0;
        if (isMountingOnPageLoad) {
            openRecordDetail(recordId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(_Fragment, { children: detailModalInstances.map(function (detailModalProps, i) { return (_jsx(RecordDetailModalWrapper, __assign({}, detailModalProps, { recordId: detailModalProps.recordId ||
                detailModalProps.defaultModalData.mainRecord.rows[0].recordId, isTopOfModalRenderStack: i === detailModalInstances.length - 1 }), "record-detail-modal-".concat(detailModalProps.recordId, "-").concat(i))); }) }));
};
export default React.memo(RecordDetailModalViewContainer);
