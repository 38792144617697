var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Menu, Select, Space, Tag } from 'antd';
import { DeleteOutlined, EllipsisOutlined, CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined, } from '@ant-design/icons';
import './SelectorCell.scss';
import _, { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'src/hooks/component/useDebounce';
import AccountState from 'src/hooks/state/Account';
import HoverRecordMenu from 'src/components/OptimizedTableView/Cell/HoverRecordMenu';
import TranslationMenuItem from 'src/components/SharedComponent/TranslationMenuItem/TranslationMenuItem';
import { ContentType } from 'src/components/TranslationEditorWrapper/interface';
import UserTagWithIcon from '../../User/UserTagWithIcon';
import { NativeMultiSet, OPTIONS_COLOR, OPTIONS_COLOR_NAME, ValueDataType, } from '../../../shared';
import { disableScroll, getTagTextColor } from '../../../utils/Collection';
import { RuleType } from '../../ScheduleView/Constant';
import UserEvaluationPopover from '../../ScheduleView/UserEvaluationPopover';
var Option = Select.Option, OptGroup = Select.OptGroup;
export var colorPickerItem = function (index, selectedColor) {
    return (_jsx(Menu.Item, { children: _jsx("div", { className: "color-picker-item-wrapper", children: _jsxs(Space, { align: "start", children: [_jsx("div", { className: "color-picker-item-indicator", style: {
                            backgroundColor: OPTIONS_COLOR[index],
                            color: getTagTextColor(OPTIONS_COLOR[index]),
                        } }), _jsx("span", { className: "color-picker-item-text", children: OPTIONS_COLOR_NAME[index] }), selectedColor === OPTIONS_COLOR[index] && _jsx(CheckOutlined, {})] }) }) }, OPTIONS_COLOR[index]));
};
var generateRandomIndex = function () { return Math.floor(Math.random() * OPTIONS_COLOR.length); };
export var DropdownInput = function (props) {
    var tagTitle = props.tagTitle, onTextChange = props.onTextChange, contentId = props.contentId, _a = props.isCreate, isCreate = _a === void 0 ? false : _a;
    var _b = useState(tagTitle), inputValue = _b[0], setInputValue = _b[1];
    return (_jsx(TranslationMenuItem, { titleString: inputValue, onChange: function (e) {
            setInputValue(e.target.value);
            onTextChange(e.target.value);
        }, onClick: function (e) {
            e.currentTarget.focus();
            e.preventDefault();
            e.stopPropagation();
        }, onPressEnter: function () {
            setInputValue(inputValue);
            onTextChange(inputValue);
        }, onBlur: function () { }, item: { id: contentId }, contentType: ContentType.SELECT_OPTION, isCreate: isCreate }, contentId));
};
var SelectorCell = function (props) {
    var nativePath = props.nativePath, inputMode = props.inputMode, showColorMenu = props.showColorMenu, defaultDisplayOptions = props.displayedOption, defaultAvailableOptions = props.availableOption, defaultIsUserSelecting = props.isUserSelecting, defaultValue = props.defaultValue, mutateDisplayedValueInItem = props.mutateDisplayedValueInItem, mutateColumnOption = props.mutateColumnOption, deleteColumnOption = props.deleteColumnOption, addColumnOption = props.addColumnOption, setDropdownOpen = props.setDropdownOpen, dropdownOpen = props.dropdownOpen, onSearch = props.onSearch, canEdit = props.canEdit, isDetailModal = props.isDetailModal, isForm = props.isForm, type = props.type, updateWithDefault = props.updateWithDefault, shiftAssigneeProps = props.shiftAssigneeProps, evaluationTypeProps = props.evaluationTypeProps, isLoading = props.isLoading;
    var _a = shiftAssigneeProps !== null && shiftAssigneeProps !== void 0 ? shiftAssigneeProps : {}, isShiftAssignmentType = _a.isShiftAssignmentType, locationAndRoleUserOptions = _a.locationAndRoleUserOptions, remainingUserOptions = _a.remainingUserOptions, refetchShiftUserList = _a.refetchShiftUserList, onEditModalCell = _a.onEditModalCell, allowAddNewAssignee = _a.allowAddNewAssignee;
    var _b = evaluationTypeProps !== null && evaluationTypeProps !== void 0 ? evaluationTypeProps : {}, isEvaluationType = _b.isEvaluationType, schemaName = _b.schemaName, shortListOptions = _b.shortListOptions, remainingOptions = _b.remainingOptions;
    var _c = useState(defaultValue || (defaultDisplayOptions === null || defaultDisplayOptions === void 0 ? void 0 : defaultDisplayOptions.map(function (_a) {
        var id = _a.id;
        return id;
    }))), selectedItemIds = _c[0], setSelectedItemIds = _c[1];
    useEffect(function () {
        if (updateWithDefault && isDetailModal) {
            setSelectedItemIds(defaultDisplayOptions === null || defaultDisplayOptions === void 0 ? void 0 : defaultDisplayOptions.map(function (_a) {
                var id = _a.id;
                return id;
            }));
        }
    }, [defaultDisplayOptions, isDetailModal, updateWithDefault]);
    var _d = useState(_.uniqBy(defaultAvailableOptions, 'id')), availableOptions = _d[0], setAvailableOptions = _d[1];
    var _e = useState(0), prevAvailableOptionSize = _e[0], setPrevAvailableOptionSize = _e[1];
    useEffect(function () {
        setAvailableOptions(cloneDeep(_.uniqBy(defaultAvailableOptions, 'id')));
    }, [defaultAvailableOptions]);
    useEffect(function () {
        if (updateWithDefault && defaultValue) {
            setSelectedItemIds(defaultValue);
        }
    }, [defaultValue, updateWithDefault]);
    var isPlainSelection = useMemo(function () { return type === ValueDataType.PLAIN_SELECTION; }, [type]);
    var _f = useState(''), newOptionNameInput = _f[0], setNewOptionNameInput = _f[1];
    var searchText = useDebounce(newOptionNameInput, 600);
    var _g = useState(false), showCantAddMessage = _g[0], setShowCantAddMessage = _g[1];
    var isNewOptionName = useMemo(function () {
        return !_.find(availableOptions, function (option) { return option.text === newOptionNameInput; });
    }, [availableOptions, newOptionNameInput]);
    var areOptionsOnSearch = useMemo(function () {
        return _.find(availableOptions, function (option) { return option.text.toLowerCase().indexOf(newOptionNameInput.toLowerCase()) > -1; });
    }, [availableOptions, newOptionNameInput]);
    var _h = useState(0), editOptionIndex = _h[0], setEditOptionIndex = _h[1];
    var _j = useState(Array(availableOptions.length).fill(false)), isEditMenuVisible = _j[0], setIsEditMenuVisible = _j[1];
    var _k = useState(null), renameText = _k[0], setRenameText = _k[1];
    var _l = useState(generateRandomIndex()), randomColorIndex = _l[0], setRandomColorIndex = _l[1];
    var isShowColorMenu = useMemo(function () { return showColorMenu && isPlainSelection; }, [isPlainSelection, showColorMenu]);
    var isMulti = useMemo(function () { return inputMode === 'multi' || NativeMultiSet.has(nativePath); }, [inputMode, nativePath]);
    var isUserSelecting = useMemo(function () { return defaultIsUserSelecting || false; }, [defaultIsUserSelecting]);
    var selectRef = useRef(null);
    var handleDropDownVisibleChange = useCallback(function (dropdownVisible) {
        var _a, _b;
        // Close if no edit menus are open
        if (!_.includes(isEditMenuVisible, true)) {
            setTimeout(function () { return setDropdownOpen(dropdownVisible); }, 100);
            disableScroll(dropdownVisible);
        }
        if (dropdownVisible) {
            (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else if (!_.includes(isEditMenuVisible, true)) {
            (_b = selectRef.current) === null || _b === void 0 ? void 0 : _b.blur();
            setTimeout(function () { return setNewOptionNameInput(''); }, 100);
            setShowCantAddMessage(false);
        }
    }, [isEditMenuVisible, setDropdownOpen]);
    var onRemoveTag = useCallback(function (id) {
        if (!canEdit)
            return;
        var selectedIds = selectedItemIds.filter(function (itemId) { return itemId !== id; });
        setSelectedItemIds(selectedIds);
        if (isDetailModal) {
            onEditModalCell === null || onEditModalCell === void 0 ? void 0 : onEditModalCell(selectedIds.map(function (id) {
                return {
                    id: id,
                    name: '',
                };
            }));
        }
        if (!isMulti) {
            mutateDisplayedValueInItem(selectedIds);
            setDropdownOpen(false);
        }
    }, [
        isDetailModal,
        isMulti,
        mutateDisplayedValueInItem,
        onEditModalCell,
        selectedItemIds,
        setDropdownOpen,
        canEdit,
    ]);
    var onClearAll = useCallback(function () {
        setSelectedItemIds([]);
        if (isDetailModal) {
            onEditModalCell === null || onEditModalCell === void 0 ? void 0 : onEditModalCell([]);
        }
    }, [isDetailModal, onEditModalCell]);
    var onSelectAll = useCallback(function () {
        var selectedIds = availableOptions.map(function (option) { return option.id; });
        setSelectedItemIds(selectedIds);
        if (isDetailModal) {
            onEditModalCell === null || onEditModalCell === void 0 ? void 0 : onEditModalCell(selectedIds.map(function (id) {
                return {
                    id: id,
                    name: '',
                };
            }));
        }
    }, [availableOptions, isDetailModal, onEditModalCell]);
    var tagRender = useCallback(function (props) {
        var _a, _b, _c;
        var label = props.label;
        var totalOptions = __spreadArray(__spreadArray([], availableOptions, true), defaultDisplayOptions, true);
        var tagColor = (_a = _.find(totalOptions, function (item) { return item.id === label; })) === null || _a === void 0 ? void 0 : _a.color;
        var tagText = (_b = _.find(totalOptions, function (item) { return item.id === label; })) === null || _b === void 0 ? void 0 : _b.text;
        if (!tagText) {
            return _jsx(_Fragment, {});
        }
        var closable = dropdownOpen && canEdit;
        if (isUserSelecting) {
            var selectCellProps = {
                closable: closable,
                onRemoveTag: onRemoveTag,
                label: label,
            };
            var tagAvatar = (_c = _.find(totalOptions, function (item) { return item.id === label; })) === null || _c === void 0 ? void 0 : _c.userAvatar;
            var avatarProps = {
                avatar: tagAvatar,
            };
            return (_jsx(HoverRecordMenu, { rid: label, title: tagText, children: _jsx("div", { children: _jsx(UserTagWithIcon, { name: tagText, size: 22, selectCellProps: selectCellProps, avatarProps: avatarProps }) }) }));
        }
        if (!tagText)
            return _jsx(_Fragment, {});
        return (_jsx(Tag, { color: tagColor, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, className: "selector-tag", closeIcon: _jsx(CloseOutlined, { className: "selector-tag-close" }), closable: closable, onClose: function () { return onRemoveTag(label); }, style: { color: getTagTextColor(tagColor) }, children: tagText }));
    }, [
        availableOptions,
        defaultDisplayOptions,
        dropdownOpen,
        isUserSelecting,
        onRemoveTag,
        canEdit,
    ]);
    var onTextChange = useCallback(function (newText) {
        setRenameText(newText);
    }, []);
    var onOptionColorChange = useCallback(function (e) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        var tempOptionData = cloneDeep(availableOptions);
        tempOptionData[editOptionIndex].color = e.key;
        setAvailableOptions(tempOptionData);
    }, [availableOptions, editOptionIndex]);
    var handleEditMenuVisibleChange = useCallback(function (index, visible) {
        var tempIsEditMenuVisible = cloneDeep(isEditMenuVisible);
        tempIsEditMenuVisible[index] = visible;
        setIsEditMenuVisible(tempIsEditMenuVisible);
    }, [isEditMenuVisible]);
    var deleteSelectedOption = useCallback(function (id) {
        var newSeletedIds = _.filter(selectedItemIds, function (item) { return item !== id; });
        setSelectedItemIds(newSeletedIds);
        var newAvaliableOptions = _.filter(availableOptions, function (option) { return option.id !== id; });
        setAvailableOptions(newAvaliableOptions);
    }, [availableOptions, selectedItemIds]);
    var onDeleteColumnOption = useCallback(function () {
        deleteColumnOption === null || deleteColumnOption === void 0 ? void 0 : deleteColumnOption(availableOptions[editOptionIndex].id).then(function () {
            deleteSelectedOption(availableOptions[editOptionIndex].id);
            handleEditMenuVisibleChange(editOptionIndex, false);
            handleDropDownVisibleChange(false);
        });
    }, [
        deleteColumnOption,
        availableOptions,
        editOptionIndex,
        deleteSelectedOption,
        handleEditMenuVisibleChange,
        handleDropDownVisibleChange,
    ]);
    var onEditMenuClose = useCallback(function () {
        var editedOption = defaultAvailableOptions.find(function (_a) {
            var id = _a.id;
            return id === availableOptions[editOptionIndex].id;
        });
        // cannot find option, no need to mutate
        if (!editedOption)
            return;
        var mutatedColumnOptionContent = {
            id: availableOptions[editOptionIndex].id,
            text: renameText !== null && renameText !== void 0 ? renameText : availableOptions[editOptionIndex].text, // ?? used to check for equality and prevent empty string after reopening edit menu
            color: availableOptions[editOptionIndex].color,
            userAvatar: null,
        };
        // Mutate if changed
        if (!_.isEqual(editedOption, mutatedColumnOptionContent)) {
            mutateColumnOption === null || mutateColumnOption === void 0 ? void 0 : mutateColumnOption(availableOptions[editOptionIndex].id, renameText, availableOptions[editOptionIndex].color);
        }
    }, [availableOptions, defaultAvailableOptions, editOptionIndex, mutateColumnOption, renameText]);
    var editMenu = useCallback(function (selectedColor) { return (_jsxs(Menu, { className: "selector-menu", onClick: onOptionColorChange, children: [isPlainSelection && (_jsxs(_Fragment, { children: [_jsx("div", { className: "selector-menu-input-wrapper", children: _jsx(DropdownInput, { tagTitle: availableOptions[editOptionIndex]
                                ? availableOptions[editOptionIndex].text
                                : '', onTextChange: onTextChange, contentId: availableOptions[editOptionIndex].id }) }), _jsx("div", { className: "block-padding-6px" }), _jsxs(Menu.Item, { onClick: onDeleteColumnOption, children: [_jsx(DeleteOutlined, { className: "selector-menu-delete" }), _jsx("span", { className: "selector-menu-delete-text", children: "Delete" })] })] })), _jsx("div", { className: "block-padding-12px" }), _jsx("span", { className: "selector-menu-color-description", onClick: function (e) { return e.stopPropagation(); }, children: "Select a color." }), _jsx("div", { className: "block-padding-8px" }), OPTIONS_COLOR === null || OPTIONS_COLOR === void 0 ? void 0 : OPTIONS_COLOR.map(function (_color, index) { return colorPickerItem(index, selectedColor); })] })); }, [
        availableOptions,
        editOptionIndex,
        isPlainSelection,
        onDeleteColumnOption,
        onOptionColorChange,
        onTextChange,
    ]);
    var onChangeInput = useCallback(function (values) {
        if (!_.includes(isEditMenuVisible, true)) {
            setNewOptionNameInput('');
            var singleChoiceValue = values.length === 0 ? undefined : values[values.length - 1];
            var selectedIds_1 = isMulti ? values : singleChoiceValue ? [singleChoiceValue] : [];
            setSelectedItemIds(selectedIds_1);
            if (isDetailModal) {
                var foundOptions_1 = availableOptions.filter(function (option) {
                    return selectedIds_1.includes(option.id);
                });
                onEditModalCell === null || onEditModalCell === void 0 ? void 0 : onEditModalCell(selectedIds_1.map(function (id) {
                    var _a;
                    var foundOption = foundOptions_1.find(function (option) { return option.id === id; });
                    return {
                        id: id,
                        name: (_a = foundOption === null || foundOption === void 0 ? void 0 : foundOption.text) !== null && _a !== void 0 ? _a : '',
                        color: foundOption === null || foundOption === void 0 ? void 0 : foundOption.color,
                        avatarUrl: foundOption === null || foundOption === void 0 ? void 0 : foundOption.userAvatar,
                    };
                }));
            }
            if (!isMulti) {
                mutateDisplayedValueInItem(selectedIds_1);
                handleDropDownVisibleChange(false);
            }
        }
    }, [
        availableOptions,
        handleDropDownVisibleChange,
        isDetailModal,
        isEditMenuVisible,
        isMulti,
        mutateDisplayedValueInItem,
        onEditModalCell,
    ]);
    var onSearchInDropdown = useCallback(function (value) {
        setNewOptionNameInput(value);
        setShowCantAddMessage(false);
    }, []);
    // Call search endpoint
    useEffect(function () {
        if (isShiftAssignmentType) {
            onSearch === null || onSearch === void 0 ? void 0 : onSearch(searchText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = availableOptions.find(function (item) { return item.id === option.value; });
        return optionItem
            ? optionItem.text.toLowerCase().indexOf(input.toLowerCase()) > -1
            : false;
    }, [availableOptions]);
    var addNewOption = useCallback(function () {
        var newOptionId = uuidv4();
        setAvailableOptions(__spreadArray(__spreadArray([], availableOptions, true), [
            {
                id: newOptionId,
                text: newOptionNameInput,
                color: isUserSelecting ? '' : OPTIONS_COLOR[randomColorIndex],
            },
        ], false));
        addColumnOption === null || addColumnOption === void 0 ? void 0 : addColumnOption(newOptionId, newOptionNameInput, OPTIONS_COLOR[randomColorIndex]);
        setRandomColorIndex(generateRandomIndex());
        setNewOptionNameInput('');
        // Add option after creating
        onChangeInput(__spreadArray(__spreadArray([], selectedItemIds, true), [newOptionId], false));
    }, [
        availableOptions,
        newOptionNameInput,
        isUserSelecting,
        randomColorIndex,
        addColumnOption,
        onChangeInput,
        selectedItemIds,
    ]);
    var onKeyDown = useCallback(function (e) {
        // Add new option on 'Enter'
        if (e.key === 'Enter') {
            if (isNewOptionName && !areOptionsOnSearch) {
                if (isPlainSelection) {
                    addNewOption();
                }
                else {
                    setShowCantAddMessage(true);
                }
            }
        }
    }, [addNewOption, areOptionsOnSearch, isNewOptionName, isPlainSelection]);
    var handleScroll = useCallback(function (event) {
        event.stopPropagation();
        if (isShiftAssignmentType &&
            prevAvailableOptionSize != availableOptions.length &&
            !searchText) {
            var atBottom = event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <=
                100;
            if (!isLoading && atBottom) {
                setPrevAvailableOptionSize(availableOptions.length);
                refetchShiftUserList === null || refetchShiftUserList === void 0 ? void 0 : refetchShiftUserList();
            }
        }
    }, [
        availableOptions.length,
        isLoading,
        isShiftAssignmentType,
        prevAvailableOptionSize,
        refetchShiftUserList,
        searchText,
    ]);
    var notFoundContent = useMemo(function () { return (_jsx(_Fragment, { children: isPlainSelection && !!addColumnOption ? (_jsx("div", { className: "selector-not-found-content-wrapper", children: newOptionNameInput && (_jsx("div", { className: "selector-not-found-content", children: _jsxs("a", { className: "selector-not-found-content-link", onClick: addNewOption, children: ["Add", ' ', _jsx("span", { style: {
                                backgroundColor: OPTIONS_COLOR[randomColorIndex],
                                color: getTagTextColor(OPTIONS_COLOR[randomColorIndex]),
                            }, children: newOptionNameInput })] }) })) })) : (_jsx(_Fragment, { children: !isLoading && _jsx("span", { children: "No options found" }) })) })); }, [
        addNewOption,
        addColumnOption,
        isLoading,
        isPlainSelection,
        newOptionNameInput,
        randomColorIndex,
    ]);
    var dropdownMenuRender = useCallback(function (menu) { return (_jsxs("div", { onMouseDown: function (e) {
            e.preventDefault();
        }, children: [_jsxs("div", { className: "selector-dropdown-wrapper", children: ["Select an option", isPlainSelection && !!addColumnOption && _jsx(_Fragment, { children: " or create one" }), isMulti && !newOptionNameInput && (_jsxs(Space, { className: "SelectAndClearAllWrapper", size: "large", children: [selectedItemIds.length !== availableOptions.length && (_jsx("span", { className: "SelectAll", onClick: onSelectAll, children: "Select All" })), !!selectedItemIds.length && (_jsx("span", { className: "ClearAll", onClick: onClearAll, children: "Clear All" }))] }))] }), menu, !isLoading && newOptionNameInput && isNewOptionName && areOptionsOnSearch && (_jsx("div", { className: "selector-dropdown-wrapper", style: isNewOptionName ? { paddingTop: '8px', paddingBottom: '0px' } : {}, onMouseDown: function (e) {
                    e.preventDefault();
                }, children: notFoundContent })), showCantAddMessage && (_jsx("div", { className: "selector-dropdown-wrapper", children: "".concat(allowAddNewAssignee ? "Please click Create New Assignee if you want to add new record." : 'You cannot add records to a linked object from the reference. Please add the record to the Data Source first.') })), allowAddNewAssignee && (_jsxs("div", { className: "cursor-pointer bg-gray-50 py-2 px-3 font-medium flex items-center", onClick: function () {
                    allowAddNewAssignee();
                    if (dropdownOpen)
                        handleDropDownVisibleChange(false);
                }, children: [_jsx(PlusOutlined, { className: "mr-1 text-xs mb-[1px]" }), " Create New Assignee"] })), isLoading && (_jsxs(Space, { className: "selector-dropdown-wrapper", children: ["Loading...", _jsx(LoadingOutlined, {})] }))] })); }, [
        areOptionsOnSearch,
        availableOptions.length,
        isLoading,
        isMulti,
        isNewOptionName,
        isPlainSelection,
        newOptionNameInput,
        notFoundContent,
        onClearAll,
        onSelectAll,
        selectedItemIds.length,
        showCantAddMessage,
        dropdownOpen,
        allowAddNewAssignee,
        handleDropDownVisibleChange,
        addColumnOption,
    ]);
    var emptyCellPlaceHolder = (_jsx("div", { className: "EmptyCellPlacerholder", children: isDetailModal && shiftAssigneeProps ? "Enter ".concat(schemaName) : 'Insert Value' }));
    var autoSchedulerSetting = AccountState.useContainer().autoSchedulerSetting;
    var isUserConflictWithStrictRule = useCallback(function (userIndictment) {
        if (!autoSchedulerSetting || !userIndictment.length)
            return false;
        var ifStrict = _.find(userIndictment, function (name) { var _a; return ((_a = autoSchedulerSetting.rulesMap[name]) === null || _a === void 0 ? void 0 : _a.type) === RuleType.STRICT; });
        return ifStrict;
    }, [autoSchedulerSetting]);
    var shiftAssigneeOption = useCallback(function (option) {
        var _a;
        var isOptionActive = !isUserConflictWithStrictRule((_a = option.indictmentNames) !== null && _a !== void 0 ? _a : []);
        return (_jsx(Option, { value: option.id, label: option.id, disabled: !isOptionActive, children: _jsx(UserTagWithIcon, { name: option.text, avatarProps: { avatar: option.userAvatar }, size: 22, isActive: isOptionActive, children: _jsx(UserEvaluationPopover, { indictmentNames: option.indictmentNames, showIndictmentCount: true }) }) }, option.id));
    }, [isUserConflictWithStrictRule]);
    var evaluationOption = useCallback(function (option) {
        var _a;
        var isOptionActive = !isUserConflictWithStrictRule((_a = option.indictmentNames) !== null && _a !== void 0 ? _a : []);
        return (_jsx(Option, { value: option.id, label: option.id, disabled: !isOptionActive, children: _jsx("div", { className: "selector-option-wrapper", children: _jsxs("div", { className: "flex w-full justify-between", children: [_jsx("span", { className: "selector-option-text", style: {
                                backgroundColor: option.color,
                                color: isOptionActive
                                    ? getTagTextColor(option.color)
                                    : '#A7A8AC',
                            }, children: option.text }), _jsx(UserEvaluationPopover, { indictmentNames: option.indictmentNames, showIndictmentCount: true })] }) }) }, option.id));
    }, [isUserConflictWithStrictRule]);
    var renderAssigneeOptions = useCallback(function () {
        return (_jsxs(_Fragment, { children: [_jsx(OptGroup, { label: "Assignees that match Location and Role", children: locationAndRoleUserOptions === null || locationAndRoleUserOptions === void 0 ? void 0 : locationAndRoleUserOptions.map(function (option) { return shiftAssigneeOption(option); }) }), _jsx(OptGroup, { label: "Remaining Assignees", children: remainingUserOptions === null || remainingUserOptions === void 0 ? void 0 : remainingUserOptions.map(function (option) { return shiftAssigneeOption(option); }) })] }));
    }, [locationAndRoleUserOptions, remainingUserOptions, shiftAssigneeOption]);
    var renderEvaluationOptions = useCallback(function () {
        var _a;
        var shortlistRuleKey = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.shortlistRule;
        var shortlistRule = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[shortlistRuleKey];
        var shortListRuleName = (_a = shortlistRule === null || shortlistRule === void 0 ? void 0 : shortlistRule.ruleDisplayName) !== null && _a !== void 0 ? _a : 'Untitled Rule';
        return (_jsxs(_Fragment, { children: [_jsx(OptGroup, { label: "".concat(schemaName, " that match rule: ").concat(shortListRuleName), children: shortListOptions === null || shortListOptions === void 0 ? void 0 : shortListOptions.map(function (option) { return evaluationOption(option); }) }), _jsx(OptGroup, { label: "Remaining ".concat(schemaName), children: remainingOptions === null || remainingOptions === void 0 ? void 0 : remainingOptions.map(function (option) { return evaluationOption(option); }) })] }));
    }, [autoSchedulerSetting, evaluationOption, remainingOptions, schemaName, shortListOptions]);
    var renderRegularOptions = useCallback(function () {
        return availableOptions === null || availableOptions === void 0 ? void 0 : availableOptions.map(function (option, index) { return (_jsx(Option, { value: option.id, label: option.id, children: _jsxs("div", { className: "selector-option-wrapper", children: [isUserSelecting ? (_jsx(UserTagWithIcon, { name: option.text, avatarProps: { avatar: option.userAvatar }, size: 22 })) : (_jsx("div", { className: "flex w-full justify-between", children: _jsx("span", { className: "selector-option-text", style: {
                                backgroundColor: option.color,
                                color: getTagTextColor(option.color),
                            }, children: option.text }) })), _jsx("span", { onClick: function (e) { return e.stopPropagation(); }, onKeyDown: function (e) { return e.stopPropagation(); }, children: isShowColorMenu && (_jsx(Dropdown, { overlay: editMenu(option.color), trigger: ['click'], overlayClassName: "selector-dropdown-overlay", open: isEditMenuVisible[index], onOpenChange: function (visible) {
                                setTimeout(function () { return handleEditMenuVisibleChange(index, visible); }, 100);
                                if (!visible) {
                                    onEditMenuClose();
                                }
                            }, children: _jsx("a", { className: "selector-dropdown-more-icon", onClick: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setEditOptionIndex(index);
                                }, children: _jsx(EllipsisOutlined, {}) }) })) })] }) }, option.id)); });
    }, [
        availableOptions,
        editMenu,
        handleEditMenuVisibleChange,
        isEditMenuVisible,
        isShowColorMenu,
        isUserSelecting,
        onEditMenuClose,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, { ref: selectRef, placeholder: isDetailModal || isForm ? emptyCellPlaceHolder : '', open: dropdownOpen, disabled: !canEdit, showSearch: true, bordered: false, placement: "bottomLeft", mode: "multiple", className: "SelectorCellWrapper", popupClassName: "SelectorCellOptionDropdown", dropdownMatchSelectWidth: 400, optionFilterProp: "children", value: selectedItemIds, onChange: onChangeInput, searchValue: newOptionNameInput, onSearch: onSearchInDropdown, filterOption: onFilterOption, menuItemSelectedIcon: _jsx(_Fragment, {}), tagRender: tagRender, optionLabelProp: "label", notFoundContent: notFoundContent, dropdownRender: dropdownMenuRender, onKeyDown: onKeyDown, loading: isLoading, onFocus: function () {
                    if (!dropdownOpen)
                        handleDropDownVisibleChange(true);
                }, onClick: function () {
                    if (!dropdownOpen)
                        handleDropDownVisibleChange(true);
                }, onDropdownVisibleChange: function () {
                    var ele = document.getElementById('ScheduleEvaluationPopover');
                    if (ele)
                        return;
                    if (dropdownOpen)
                        handleDropDownVisibleChange(false);
                }, onBlur: function () {
                    if (isMulti) {
                        mutateDisplayedValueInItem(selectedItemIds);
                    }
                    handleDropDownVisibleChange(false);
                }, onPopupScroll: handleScroll, children: _jsx(_Fragment, { children: isShiftAssignmentType && isUserSelecting ? (renderAssigneeOptions()) : (_jsx(_Fragment, { children: isEvaluationType ? renderEvaluationOptions() : renderRegularOptions() })) }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    handleDropDownVisibleChange(false);
                }, style: { display: dropdownOpen ? 'block' : 'none' } })] }));
};
export default React.memo(SelectorCell);
