var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../FeedContainer.scss';
import { Collapse } from 'antd';
import moment from 'moment';
var Panel = Collapse.Panel;
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useAccountHistoryComponent, useTimeZone } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { DownArrowOutlinedIcon } from '../../../../utils/icons/DownArrowOutlined';
import { renderAuthorEmployeeIcon } from '../../../../utils/Account';
import { useRenderContentType } from './useRenderContentType';
var ActivityFeedPageSize = 100;
var ActivityFeedEvents = function (props) {
    var collapsed = props.collapsed, subjectId = props.subjectId, actorType = props.actorType, onOpenRecordDetail = props.onOpenRecordDetail, actorIds = props.actorIds;
    var _a = useState(moment().toISOString()), fromTimestamp = _a[0], setFromTimestamp = _a[1];
    var _b = useState(0), pageOffset = _b[0], setPageOffset = _b[1];
    var _c = useState(true), canLoadMore = _c[0], setCanLoadMore = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var firstTimeLoadData = useRef(true);
    var hasScrollBar = useCallback(function () {
        var div = document.getElementsByClassName('activityEvents')[0];
        return (div === null || div === void 0 ? void 0 : div.scrollHeight) > (div === null || div === void 0 ? void 0 : div.clientHeight);
    }, []);
    var _e = ActivityFeedState.useContainer(), getEventSummaryText = _e.getEventSummaryText, collectionFilters = _e.collectionFilters, actorFilters = _e.actorFilters, eventFilters = _e.eventFilters;
    var accountId = useRecoilValue(accountIdState);
    var getAccountHistory = useAccountHistoryComponent().getAccountHistory;
    var _f = useState([]), activityFeedData = _f[0], setActivityFeedData = _f[1];
    var timeFormatFn = useTimeZone().timeFormatFn;
    var getActivityFeedData = useCallback(function (reset) { return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!canLoadMore && !reset && !isLoading)
                        return [2 /*return*/];
                    setIsLoading(true);
                    if (reset)
                        firstTimeLoadData.current = true;
                    requestBody = {
                        accountId: accountId,
                        subjectId: subjectId,
                        filters: {
                            collectionIds: collectionFilters,
                            actorTypes: actorFilters.length == 0 && actorType ? [actorType] : actorFilters,
                            eventTypes: eventFilters,
                            actorIds: actorIds,
                        },
                        from: reset ? moment().toISOString() : fromTimestamp,
                        pageSize: ActivityFeedPageSize,
                        pageOffset: reset ? 0 : pageOffset,
                    };
                    return [4 /*yield*/, getAccountHistory(requestBody)];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        setActivityFeedData(reset ? resp.accountHistories : activityFeedData.concat(resp.accountHistories));
                        if (resp.nextFrom) {
                            if (resp.nextFrom === requestBody.from) {
                                setPageOffset(requestBody.pageOffset + 1);
                            }
                            else {
                                setPageOffset(0);
                            }
                            setFromTimestamp(resp.nextFrom);
                        }
                        setCanLoadMore(resp.size === ActivityFeedPageSize);
                    }
                    setIsLoading(false);
                    if (reset)
                        firstTimeLoadData.current = false;
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        accountId,
        activityFeedData,
        actorFilters,
        canLoadMore,
        collectionFilters,
        eventFilters,
        fromTimestamp,
        isLoading,
        subjectId,
        pageOffset,
    ]);
    // Initial and filter change fetch
    useEffect(function () {
        getActivityFeedData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, actorFilters, collectionFilters, eventFilters, subjectId, actorType, collapsed]);
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        if (hasScrollBar()) {
            var atBottom = event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <=
                50;
            if (!isLoading && atBottom && canLoadMore) {
                getActivityFeedData();
            }
        }
    }, [canLoadMore, getActivityFeedData, hasScrollBar, isLoading]);
    var genHeader = useCallback(function (events) {
        var _a;
        var timeAgo = timeFormatFn(events[0].recordedAt, 'MMM D', false);
        var actorIconData = {
            actorType: events[0].content.actor.type,
            authorTitle: events[0].content.actor.name,
            authorIcon: (_a = events[0].content.actor.avatarUrl) !== null && _a !== void 0 ? _a : '',
        };
        var summaryText = getEventSummaryText(events);
        var timeStamp = timeFormatFn(events[0].recordedAt, 'h:mm a', true);
        var canOpenActorRecordDetail = events[0].content.actor.recordId && onOpenRecordDetail;
        return (_jsx("div", { className: "activityOuterContainer", children: _jsxs("div", { className: "activityInnerContainer", children: [_jsx("div", { className: "activityAvatar", children: renderAuthorEmployeeIcon(actorIconData, true) }), _jsxs("div", { className: "activityDescription", children: [_jsxs("span", { className: "eventDescription font-medium", children: [_jsx(ZiraTooltip, { title: canOpenActorRecordDetail
                                            ? "Open ".concat(actorIconData.authorTitle, "'s record details")
                                            : undefined, children: _jsx("span", { className: "font-semibold".concat(canOpenActorRecordDetail
                                                ? ' cursor-pointer hover:underline'
                                                : ''), onClick: function (e) {
                                                if (canOpenActorRecordDetail) {
                                                    e.stopPropagation();
                                                    onOpenRecordDetail(events[0].content.actor.recordId);
                                                }
                                            }, children: "".concat(actorIconData.authorTitle) }) }), summaryText] }), _jsx("span", { className: "timeAgo text-gray-400", children: "".concat(timeAgo, " at ").concat(timeStamp) })] })] }) }));
    }, [getEventSummaryText, onOpenRecordDetail, timeFormatFn]);
    var renderContentType = useRenderContentType(timeFormatFn);
    if (isLoading && firstTimeLoadData.current === true)
        return (_jsx("div", { className: "activityEvents", children: _jsx(ZiraRowsSkeleton, { rowNumber: 10 }) }));
    return (_jsx("div", { className: "activityEvents", onScroll: handlePaginationScroll, children: _jsxs(Collapse, { expandIcon: function () { return _jsx(DownArrowOutlinedIcon, {}); }, expandIconPosition: "end", ghost: true, children: [activityFeedData === null || activityFeedData === void 0 ? void 0 : activityFeedData.map(function (events, idx) {
                    return (_jsx(Panel, { className: "activityPanel mb-[12px] bg-white hover:border-azure-50 hover:shadow-hover", header: genHeader(events), children: events === null || events === void 0 ? void 0 : events.map(function (event) {
                            var content = event.content;
                            var canOpenRecordDetail = onOpenRecordDetail && !!content.recordId;
                            return (_jsxs("div", { className: "panelRow", children: [_jsx("div", {}), _jsxs("div", { className: "activityDetail".concat(canOpenRecordDetail ? ' hover:shadow-card' : ''), onClick: function () {
                                            if (canOpenRecordDetail) {
                                                onOpenRecordDetail(content.recordId);
                                            }
                                        }, children: [_jsx("div", { className: "employeeIcon" }), renderContentType(event)] })] }, "".concat(event.recordedAt, "-").concat(idx)));
                        }) }, idx));
                }), !(activityFeedData === null || activityFeedData === void 0 ? void 0 : activityFeedData.length) ? _jsx("div", { children: "No Events" }) : _jsx(_Fragment, {}), canLoadMore && !hasScrollBar() && (_jsxs("div", { className: "loadMore", onClick: function () { return getActivityFeedData(); }, children: [_jsx(ArrowDownOutlined, {}), _jsx("span", { children: "Load More" })] }))] }) }));
};
export default React.memo(ActivityFeedEvents);
