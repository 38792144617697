export var ContentType;
(function (ContentType) {
    ContentType["PAGE"] = "PAGE";
    ContentType["PAGE_GROUP"] = "PAGE_GROUP";
    ContentType["SCHEMA"] = "SCHEMA";
    ContentType["COLLECTION"] = "COLLECTION";
    ContentType["SCHEMA_GROUP"] = "SCHEMA_GROUP";
    ContentType["SELECT_OPTION"] = "SELECT_OPTION";
})(ContentType || (ContentType = {}));
export var contentTypeTitleMap = {
    PAGE: 'Page',
    PAGE_GROUP: 'Page Group',
    SCHEMA: 'Collection Schema',
    COLLECTION: 'Collection',
    SCHEMA_GROUP: 'Schema Group',
    SELECT_OPTION: 'Select Option',
};
