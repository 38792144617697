var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Button, Modal, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import './styles/ExportReportModal.scss';
import CsvExportTemplateConfigModal from './CsvExportTemplateConfigModal';
import PdfExportTemplateConfigModal from './PdfExportTemplateConfigModal';
import ExportReportModalContent from './components/ExportReportModalContent';
import { useExportReportModal } from './hooks/useExportReportModal';
var ExportReportModal = function (props) {
    var isModalVisible = props.isModalVisible, setIsModalVisible = props.setIsModalVisible, recordId = props.recordId;
    var exportReportModalState = useExportReportModal(props);
    var setIsCvsTemplateConfigModalVisible = exportReportModalState.setIsCvsTemplateConfigModalVisible, setSelectedCsvTemplateInfo = exportReportModalState.setSelectedCsvTemplateInfo, setIsPdfTemplateConfigModalVisible = exportReportModalState.setIsPdfTemplateConfigModalVisible, setSelectedPdfTemplateInfo = exportReportModalState.setSelectedPdfTemplateInfo, onExportReport = exportReportModalState.onExportReport, isCvsTemplateConfigModalVisible = exportReportModalState.isCvsTemplateConfigModalVisible, isPdfTemplateConfigModalVisible = exportReportModalState.isPdfTemplateConfigModalVisible, selectedCsvTemplateInfo = exportReportModalState.selectedCsvTemplateInfo, selectedPdfTemplateInfo = exportReportModalState.selectedPdfTemplateInfo;
    var menu = useMemo(function () { return (_jsxs(Menu, { onClick: function (info) {
            if (info.key === 'CSV') {
                setIsCvsTemplateConfigModalVisible(true);
                setSelectedCsvTemplateInfo(undefined);
            }
            if (info.key === 'PDF') {
                setIsPdfTemplateConfigModalVisible(true);
                setSelectedPdfTemplateInfo(undefined);
            }
        }, children: [_jsx(Menu.Item, { children: "CSV template" }, "CSV"), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { children: "PDF template" }, "PDF")] })); }, [
        setIsCvsTemplateConfigModalVisible,
        setIsPdfTemplateConfigModalVisible,
        setSelectedCsvTemplateInfo,
        setSelectedPdfTemplateInfo,
    ]);
    var modalFooter = (_jsxs("div", { className: "mx-5 flex ".concat(recordId ? 'justify-end' : 'justify-between'), children: [!recordId && (_jsx(Dropdown, { overlay: menu, children: _jsxs(Button, { className: "CancelBtn", children: [_jsx("span", { children: "Create Template" }), _jsx(EllipsisOutlined, {})] }) })), _jsxs("div", { children: [_jsx(Button, { className: "CancelBtn ml-auto", onClick: function () { return setIsModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "ConfirmBtn", onClick: function (e) {
                            e.stopPropagation();
                            onExportReport();
                        }, children: "Export" })] })] }));
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { className: "ExportPayrollModal", width: 600, open: isModalVisible, centered: true, closable: false, footer: modalFooter, destroyOnClose: true, onCancel: function () { return setIsModalVisible(false); }, children: _jsx(ExportReportModalContent, __assign({}, props, exportReportModalState)) }), !recordId && isCvsTemplateConfigModalVisible && (_jsx(CsvExportTemplateConfigModal, { onCloseModal: function () { return setIsCvsTemplateConfigModalVisible(false); }, existingTemplateData: selectedCsvTemplateInfo })), isPdfTemplateConfigModalVisible && (_jsx(PdfExportTemplateConfigModal, { onCloseModal: function () { return setIsPdfTemplateConfigModalVisible(false); }, existingTemplateData: selectedPdfTemplateInfo }))] }));
};
export default React.memo(ExportReportModal);
