var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import { getTagTextColor } from 'src/utils/Collection';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import RecordColorSelectorDropdown from 'src/components/RecordColorSelectorDropdown/RecordColorSelectorDropdown';
import { DropdownInput } from 'src/components/TableView/TableCell/SelectorCell';
var SelectOptionItem = function (_a) {
    var option = _a.option, draggableProvided = _a.draggableProvided, onDeleteOption = _a.onDeleteOption, onUpdateOptionColor = _a.onUpdateOptionColor, onUpdateOptionText = _a.onUpdateOptionText, isCreatingSchema = _a.isCreatingSchema;
    var _b = useState(false), isColorMenuVisible = _b[0], setIsColorMenuVisible = _b[1];
    var items = useMemo(function () { return [
        {
            key: 'change-text',
            label: (_jsx("div", { className: "selector-menu-input-wrapper", children: _jsx(DropdownInput, { tagTitle: option.name ? option.name : '', onTextChange: onUpdateOptionText, contentId: option.id, isCreate: isCreatingSchema }) })),
        },
        {
            key: 'change-color',
            label: (_jsx(RecordColorSelectorDropdown, { currentColor: option.color, visible: isColorMenuVisible, onVisibleChange: setIsColorMenuVisible, onUpdateRecordColor: onUpdateOptionColor, children: _jsx("span", { children: "Change Color" }) })),
            onClick: function () { return setIsColorMenuVisible(true); },
        },
        {
            key: 'delete-value',
            label: 'Delete Value',
            onClick: onDeleteOption,
        },
    ]; }, [
        option.name,
        option.id,
        option.color,
        onUpdateOptionText,
        isColorMenuVisible,
        onUpdateOptionColor,
        onDeleteOption,
        isCreatingSchema,
    ]);
    return (_jsxs("div", __assign({ className: "flex w-full items-center mb-2 -ml-3 h-[42px]" }, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, children: [draggableProvided && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps, { className: "ml-1" }))), _jsxs("div", { className: "flex w-full items-center justify-between rounded-xl border border-black/10 h-[42px] px-[10px]", children: [_jsx("span", { className: "text-sm font-medium rounded-md px-[6px] py-[2px]", style: {
                            backgroundColor: option.color,
                            color: getTagTextColor(option.color),
                        }, children: option.name }), _jsx(Dropdown, { menu: { items: items }, overlayClassName: "SelectOptionActionsMenu", children: _jsx(EllipsisOutlined, { className: "Ellipsis cursor-pointer" }) })] })] })));
};
export default React.memo(SelectOptionItem);
