var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useInitReverseCollectionViews, useRecordComponent, useRecordDetail, useScheduleComponent, } from 'src/hooks/component';
import { tableSchedulingDisplaySettingsState, isCurrentPageCanvasTypeSelector, blockTimeZoneState, collectionTableSchemaCanEditSelector, scheduleTimeZoneSelector, tableSelectedViewIdState, scheduleCollectionIdState, scheduleTableRowState, scheduleTableState, scheduleTimeRangeState, collectionTableNativeSchemaSelector, detailModalInstancesState, } from 'src/state';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import Emitter from 'src/utils/EventEmitter';
import { useReactToPrint } from 'react-to-print';
import { EmitterEventType, NativePath, BespokeReverseCollectionComponent } from '../../shared';
import { getAccountStartDayOfWeek, getWeekDayNumber } from '../../utils/Account';
import './ScheduleTable.scss';
import TableOptionHeaderFromReverseCollection from '../OptimizedTableView/ReverseCollection/TableOptionHeaderFromReverseCollection';
import ShowMoreSchedulingDropdown from './ShowMoreSchedulingDropdown';
import TableColorCoding from './Header/ColorCoding/TableColorCoding';
import PublishShiftDropdown from './PublishShiftDropdown';
import ScheduleTable from './ScheduleTable';
var PrintOnlySchedulingContent = lazy(function () { return import('./PrintView/PrintOnlySchedulingContent'); });
var ScheduleReverseTable = function (_a) {
    var _b, _c;
    var referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, collectionComponent = _a.collectionComponent;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var setDetailModalInstances = useSetRecoilState(detailModalInstancesState);
    var viewId = useRecoilValue(tableSelectedViewIdState(collectionComponent));
    var collectionId = (_b = useRecoilValue(scheduleCollectionIdState)) !== null && _b !== void 0 ? _b : '';
    var table = useRecoilValue(scheduleTableState(collectionComponent));
    var rowData = useRecoilValue(scheduleTableRowState(collectionComponent));
    var timeRange = useRecoilValue(scheduleTimeRangeState(collectionComponent));
    var tableDataSource = useMemo(function () {
        if (rowData && table) {
            return rowData.map(function (row, rowIndex) {
                var maxCells = 1;
                var columnsObject = timeRange
                    .slice(0, -1)
                    .reduce(function (acc, time) {
                    var _a;
                    var _b, _c, _d;
                    var columnKey = moment(time).format('YYYY-MM-DD');
                    var shiftRIds = (_d = (_c = (_b = table[rowIndex]) === null || _b === void 0 ? void 0 : _b.columns.find(function (column) { return column.columnKey === columnKey; })) === null || _c === void 0 ? void 0 : _c.cells) !== null && _d !== void 0 ? _d : [];
                    maxCells = Math.max(maxCells, shiftRIds.length);
                    return __assign(__assign({}, acc), (_a = {}, _a[columnKey] = shiftRIds, _a.maxCells = maxCells, _a));
                }, {});
                return __assign({ groupByRow: row }, columnsObject);
            });
        }
        return [];
    }, [rowData, table, timeRange]);
    var onOpenShiftRecordDetail = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (recordId, tableId) {
            var _a, _b;
            var timezone = (_b = (_a = snapshot.getLoadable(scheduleTimeZoneSelector(tableId)).valueMaybe()) !== null && _a !== void 0 ? _a : snapshot.getLoadable(blockTimeZoneState(tableId)).valueMaybe()) !== null && _b !== void 0 ? _b : moment.tz.guess();
            openRecordDetail(recordId, timezone);
        };
    }, [openRecordDetail]);
    var openShiftDetailModal = useCallback(function (recordId) {
        onOpenShiftRecordDetail(recordId, collectionComponent);
    }, [collectionComponent, onOpenShiftRecordDetail]);
    var onOpenNewOrBulkShiftDetailModal = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, recordId, defaultModalData, bulkEditData, schemaDefaults) {
            var defaultTimeZone = snapshot
                .getLoadable(scheduleTimeZoneSelector(blockId))
                .valueMaybe();
            if (!defaultTimeZone) {
                toast('Something went wrong. Refresh the page and try again.', {
                    type: 'error',
                });
                return;
            }
            setDetailModalInstances(function (prev) {
                return __spreadArray(__spreadArray([], prev, true), [
                    {
                        recordId: recordId,
                        defaultModalData: defaultModalData,
                        bulkEditData: bulkEditData,
                        schemaDefaults: schemaDefaults,
                        isCreatingNew: !bulkEditData,
                        defaultTimeZone: defaultTimeZone,
                    },
                ], false);
            });
        };
    }, [setDetailModalInstances]);
    var nativeSchemaIdForFilter = (_c = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: collectionComponent === BespokeReverseCollectionComponent.USER_SCHEDULE
            ? NativePath.SHIFT_USER_ID
            : collectionComponent === BespokeReverseCollectionComponent.LOCATION_SCHEDULE
                ? NativePath.SHIFT_LOCATION_ID
                : NativePath.SHIFT_ROLE_IDS,
    }))) === null || _c === void 0 ? void 0 : _c.id;
    var onAddNewShift = useCallback(function (schemaDefaults) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onCreateNewRecord('', collectionId, schemaDefaults, referenceCollectionId, referenceRecordId, nativeSchemaIdForFilter, collectionComponent)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        collectionComponent,
        collectionId,
        nativeSchemaIdForFilter,
        onCreateNewRecord,
        referenceCollectionId,
        referenceRecordId,
    ]);
    var _d = useScheduleComponent(), changeCalView = _d.changeCalView, initTimeRange = _d.initTimeRange;
    var viewDisplaySettings = useRecoilValue(tableSchedulingDisplaySettingsState(collectionComponent));
    var _e = useMemo(function () { return viewDisplaySettings; }, [viewDisplaySettings]), scheduleView = _e.viewType, blockTimeZone = _e.timezone;
    var initialViews = useInitReverseCollectionViews(referenceRecordId, collectionComponent, undefined, undefined, collectionComponent, undefined);
    useEffect(function () {
        if (initialViews && viewDisplaySettings) {
            initTimeRange(collectionComponent, viewDisplaySettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialViews]);
    // Update time range on start day of week change
    useEffect(function () {
        var _a;
        moment.updateLocale(collectionComponent, {
            week: {
                dow: getWeekDayNumber((_a = viewDisplaySettings.startDayOfWeek) !== null && _a !== void 0 ? _a : getAccountStartDayOfWeek()),
            },
        });
        changeCalView(collectionComponent, scheduleView, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewDisplaySettings.startDayOfWeek]);
    // Sync to block time zone state
    var setBlockTimeZone = useSetRecoilState(blockTimeZoneState(collectionComponent));
    useEffect(function () {
        if (blockTimeZone) {
            setBlockTimeZone(blockTimeZone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTimeZone]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onBeforeGetContentResolve = useRef(null);
    var _f = useState(false), isPrinting = _f[0], setIsPrinting = _f[1];
    var printRef = useRef(null);
    var handlePrint = useReactToPrint({
        documentTitle: 'Schedule',
        content: function () {
            return printRef.current;
        },
        onBeforeGetContent: function () {
            return new Promise(function (resolve) {
                setIsPrinting(true);
                onBeforeGetContentResolve.current = resolve;
            });
        },
        onAfterPrint: function () { return setIsPrinting(false); },
    });
    var printCallbackRef = useCallback(function (node) {
        if (node !== null && onBeforeGetContentResolve.current !== null) {
            printRef.current = node;
            onBeforeGetContentResolve.current();
        }
    }, []);
    var refetch = useRefetchCollection();
    useEffect(function () {
        var refreshSchedule = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                refetch(collectionId);
                return [2 /*return*/];
            });
        }); };
        Emitter.on(EmitterEventType.REFRESH_SCHEDULE, refreshSchedule);
        return function () {
            Emitter.off(EmitterEventType.REFRESH_SCHEDULE, refreshSchedule);
        };
    });
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var canPublish = useRecoilValue(collectionTableSchemaCanEditSelector({
        collectionId: collectionId,
        nativePath: NativePath.SHIFT_PUBLISHED,
    }));
    return !viewDisplaySettings || !viewId ? (_jsx(Skeleton, { active: true })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "".concat(!isCanvasPageType
                    ? 'SchedulePageBlockHeaderContainer'
                    : 'ScheduleBlockOptionHeader'), children: _jsx(TableOptionHeaderFromReverseCollection, { collectionId: collectionId, referenceCollectionId: referenceCollectionId, referenceRecordId: referenceRecordId, reverseRelationId: collectionComponent, standardCollectionComponent: collectionComponent, schemaId: nativeSchemaIdForFilter, viewId: viewId, moreOptionsDropdown: _jsx(ShowMoreSchedulingDropdown, { tableId: collectionComponent, collectionId: collectionId, type: "reverseCollection", handlePrint: handlePrint }), colorCoding: _jsx(TableColorCoding, { tableId: collectionComponent, collectionId: collectionId, type: "reverseCollection" }), schedulePublishShift: canPublish ? (_jsx(PublishShiftDropdown, { tableId: collectionComponent })) : undefined }) }), _jsx(ScheduleTable, { tableId: collectionComponent, type: "reverseCollection", tableDataSource: tableDataSource, addNewShift: onAddNewShift, openNewOrBulkShiftDetailModal: onOpenNewOrBulkShiftDetailModal, openShiftDetailModal: openShiftDetailModal, openRecordDetailModal: openRecordDetail }), isPrinting && (_jsx(Suspense, { fallback: null, children: _jsx("div", { style: { position: 'absolute', zIndex: -1000, width: '100%' }, children: _jsx(PrintOnlySchedulingContent, { tableId: collectionComponent, blockTitle: '', dataSource: tableDataSource, ref: printCallbackRef }) }) }))] }));
};
export default React.memo(ScheduleReverseTable);
