var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useMetricsBarComponent } from 'src/hooks/component';
import { canEditPageSelector } from 'src/state';
import PopupModalMetricConfig from '../../PopupModalMetricConfig/PopupModalMetricConfig';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import CalculateMetricBar from './CalculateMetricBar';
import './MetricBar.scss';
import MetricBarEntry from './MetricBarEntry';
var MetricBar = function (_a) {
    var blockId = _a.blockId, moveBlockUpDown = _a.moveBlockUpDown;
    var _b = useMetricsBarComponent(), getAvailableMetrics = _b.getAvailableMetrics, addMetricBarEntry = _b.addMetricBarEntry, updateMetricBarEntry = _b.updateMetricBarEntry, deleteMetricBarEntry = _b.deleteMetricBarEntry, recalculateMetricBar = _b.recalculateMetricBar;
    var _c = useState(), metrics = _c[0], setMetrics = _c[1];
    var _d = useState(moment().toISOString()), lastCalculatedAt = _d[0], setLastCalculatedAt = _d[1];
    // Calculate to render
    useEffect(function () {
        onRecalculateMetricBar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var canEditPage = useRecoilValue(canEditPageSelector);
    var _e = useState(false), isAddEditMetricEntryVisible = _e[0], setIsAddEditMetricEntryVisible = _e[1];
    var _f = useState(), editMetricEntryData = _f[0], setEditMetricEntryData = _f[1];
    var _g = useState(false), isEditing = _g[0], setIsEditing = _g[1];
    var openConfigMetricModal = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAvailableMetrics()];
                case 1:
                    _a.sent();
                    setIsAddEditMetricEntryVisible(true);
                    return [2 /*return*/];
            }
        });
    }); }, [getAvailableMetrics]);
    var onOpenEditMetricmodal = useCallback(function (metric) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setEditMetricEntryData(metric);
                    return [4 /*yield*/, openConfigMetricModal()];
                case 1:
                    _a.sent();
                    setIsEditing(true);
                    return [2 /*return*/];
            }
        });
    }); }, [openConfigMetricModal]);
    var onDeleteMetricEntry = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedMetrics;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedMetrics = metrics === null || metrics === void 0 ? void 0 : metrics.filter(function (metric) { return metric.metricId !== id; });
                    if (!updatedMetrics)
                        return [2 /*return*/];
                    return [4 /*yield*/, deleteMetricBarEntry(blockId, id, {
                            staticDashboardMetrics: updatedMetrics,
                            dynamicDashboardMetrics: [],
                            lastCalculatedAt: lastCalculatedAt,
                        })];
                case 1:
                    _a.sent();
                    setMetrics(updatedMetrics);
                    return [2 /*return*/];
            }
        });
    }); }, [blockId, deleteMetricBarEntry, lastCalculatedAt, metrics]);
    var onChangeMetricBar = useCallback(function (title, name, metric) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedMetrics, newMetric, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!metric.dateRange)
                        return [2 /*return*/];
                    newMetric = {
                        title: title,
                        metricId: editMetricEntryData === null || editMetricEntryData === void 0 ? void 0 : editMetricEntryData.metricId,
                        metricName: name,
                        filters: metric.filters,
                        dateRange: metric.dateRange,
                    };
                    request = {
                        blockId: blockId,
                        staticDashboardMetrics: newMetric,
                        dynamicDashboardMetric: null,
                    };
                    if (!isEditing) return [3 /*break*/, 2];
                    setEditMetricEntryData(undefined);
                    setIsEditing(false);
                    return [4 /*yield*/, updateMetricBarEntry(request)];
                case 1:
                    updatedMetrics = _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, addMetricBarEntry(request)];
                case 3:
                    updatedMetrics = _a.sent();
                    _a.label = 4;
                case 4:
                    if (updatedMetrics) {
                        setMetrics(updatedMetrics.staticDashboardMetrics);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [addMetricBarEntry, blockId, editMetricEntryData === null || editMetricEntryData === void 0 ? void 0 : editMetricEntryData.metricId, isEditing, updateMetricBarEntry]);
    var onRecalculateMetricBar = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedMetrics;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, recalculateMetricBar(blockId)];
                case 1:
                    updatedMetrics = _a.sent();
                    if (updatedMetrics) {
                        setMetrics(updatedMetrics.staticDashboardMetrics);
                        setLastCalculatedAt(updatedMetrics.lastCalculatedAt);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [blockId, recalculateMetricBar]);
    return !metrics ? (_jsx(LoadingOutlined, {})) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "MetricBarWrapper", children: [_jsx(HoverOptionButtons, { data: {
                            blockId: blockId,
                        }, moveBlockUpDown: moveBlockUpDown, isNonTitleBlock: true }), _jsx(CalculateMetricBar, { lastCalculatedAt: lastCalculatedAt, onRecalculate: onRecalculateMetricBar }), _jsxs("div", { className: "MetricBar", children: [metrics.map(function (metric) {
                                return (_jsx(MetricBarEntry, { metric: metric, deleteMetric: onDeleteMetricEntry, openEditMetricModal: onOpenEditMetricmodal }, metric.metricId));
                            }), canEditPage && (_jsx(Button, { onClick: openConfigMetricModal, className: "AddMetricEntryButton shadow-block hover:shadow-hover", icon: _jsx(PlusOutlined, {}) }))] })] }), isAddEditMetricEntryVisible && (_jsx(PopupModalMetricConfig, { isModalVisible: isAddEditMetricEntryVisible, setIsModalVisible: function (visible) { return setIsAddEditMetricEntryVisible(visible); }, onChangeMetricBar: onChangeMetricBar, metricEntryData: editMetricEntryData, isMetricBar: true }))] }));
};
export default React.memo(MetricBar);
