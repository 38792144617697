var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Breadcrumb, Layout } from 'antd';
import './IntegrationPage.scss';
import { useRecoilValue } from 'recoil';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { ENABLE_KAMANA, ENABLE_TITAN_API, ENABLE_PROLIANT_READYPAY_API, ENABLE_GREENSHADES_API, } from 'src/components/FeatureGatingKeyConstant';
import SettingsContentHeader from '../../SettingsContentHeader';
import { IpName } from '../../../../shared';
import { getIntegrationIcon } from '../../../../utils/Account';
import IPDisplayCard from './IPDisplayCard';
var Content = Layout.Content;
var IpDisplayData = {
    Square: {
        title: IpName.SQUARE,
        icon: getIntegrationIcon(IpName.SQUARE),
    },
    ADP: {
        title: IpName.ADP,
        icon: getIntegrationIcon(IpName.ADP),
    },
    Rippling: {
        title: IpName.RIPPLING,
        icon: getIntegrationIcon(IpName.RIPPLING),
    },
    Gusto: { title: IpName.GUSTO, icon: getIntegrationIcon(IpName.GUSTO) },
    Bullhorn: {
        title: IpName.BULLHORN,
        icon: getIntegrationIcon(IpName.BULLHORN),
    },
    DocuSign: {
        title: IpName.DOCUSIGN,
        icon: getIntegrationIcon(IpName.DOCUSIGN),
    },
    SignNow: {
        title: IpName.SIGNNOW,
        icon: getIntegrationIcon(IpName.SIGNNOW),
    },
    QuickBooks: {
        title: IpName.QUICKBOOKS,
        icon: getIntegrationIcon(IpName.QUICKBOOKS),
    },
    Paychex: {
        title: IpName.PAYCHEX,
        icon: getIntegrationIcon(IpName.PAYCHEX),
    },
    CheckrPay: {
        title: IpName.CHECKRPAY,
        icon: getIntegrationIcon(IpName.CHECKRPAY),
    },
    SmartRecruiters: {
        title: IpName.SmartRecruiters,
        icon: getIntegrationIcon(IpName.SmartRecruiters),
    },
    Kamana: {
        title: IpName.KAMANA,
        icon: getIntegrationIcon(IpName.KAMANA),
    },
    Titan: {
        title: IpName.TITAN,
        icon: getIntegrationIcon(IpName.TITAN),
    },
    ProliantReadyPay: {
        title: IpName.PROLIANT_READYPAY,
        icon: getIntegrationIcon(IpName.PROLIANT_READYPAY),
    },
    Greenshades: {
        title: IpName.GREENSHADES,
        icon: getIntegrationIcon(IpName.GREENSHADES),
    },
};
var documentCards = [IpDisplayData.DocuSign, IpDisplayData.SignNow];
var accountingCards = [IpDisplayData.QuickBooks];
var hrCards = [IpDisplayData.SmartRecruiters];
var IntegrationPage = function () {
    var isTitanApiEnabled = useRecoilValue(featureEnableStatusState(ENABLE_TITAN_API));
    var isKamanaEnabled = useRecoilValue(featureEnableStatusState(ENABLE_KAMANA));
    var isProliantReadyPayApiEnabled = useRecoilValue(featureEnableStatusState(ENABLE_PROLIANT_READYPAY_API));
    var isGreenshadesApiEnabled = useRecoilValue(featureEnableStatusState(ENABLE_GREENSHADES_API));
    var payRollCards = __spreadArray(__spreadArray([
        IpDisplayData.ADP,
        IpDisplayData.Rippling,
        IpDisplayData.Paychex,
        IpDisplayData.CheckrPay
    ], (isProliantReadyPayApiEnabled ? [IpDisplayData.ProliantReadyPay] : []), true), (isGreenshadesApiEnabled ? [IpDisplayData.Greenshades] : []), true);
    var customCards = useMemo(function () {
        if (!isTitanApiEnabled)
            return [];
        return [IpDisplayData.Titan];
    }, [isTitanApiEnabled]);
    var atsCards = useMemo(function () {
        var defaultAtsCards = [IpDisplayData.Bullhorn];
        return isKamanaEnabled ? defaultAtsCards.concat(IpDisplayData.Kamana) : defaultAtsCards;
    }, [isKamanaEnabled]);
    return (_jsxs(_Fragment, { children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Integrations" }) }), _jsx("div", { className: "IntegrationLayoutWrapper", children: _jsxs(Content, { children: [_jsxs("div", { className: "SectionHeader", children: [_jsx("div", { className: "IntegrationPageTitle", children: "Integrations" }), _jsx("div", { className: "IntegrationPageDescription", children: "Set up integrations to streamline and automate manual tasks with third-party services like payroll, POS sales data and more." })] }), _jsx("div", { className: "IntegrationSubHeader", children: "Payroll" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: payRollCards.map(function (card) {
                                return (_jsx(IPDisplayCard, { title: card.title, category: "payroll", icon: card.icon }, card.title));
                            }) }), _jsx("div", { className: "IntegrationSubHeader", children: "Accounting" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: accountingCards.map(function (card) {
                                return (_jsx(IPDisplayCard, { title: card.title, category: "accounting", icon: card.icon }, card.title));
                            }) }), _jsx("div", { className: "IntegrationSubHeader", children: "Document" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: documentCards.map(function (card) {
                                return (_jsx(IPDisplayCard, { title: card.title, category: "documents", icon: card.icon }, card.title));
                            }) }), _jsx("div", { className: "IntegrationSubHeader", children: "HR" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: hrCards.map(function (card) {
                                return (_jsx(IPDisplayCard, { title: card.title, category: "HR", icon: card.icon }, card.title));
                            }) }), _jsx("div", { className: "IntegrationSubHeader", children: "ATS" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: atsCards.map(function (card) {
                                return (_jsx(IPDisplayCard, { title: card.title, category: "ats", icon: card.icon }, card.title));
                            }) }), customCards.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: "IntegrationSubHeader", children: "Custom" }), _jsx("div", { className: "HorizontalScrollingWrapper", children: customCards.map(function (card) {
                                        return (_jsx(IPDisplayCard, { title: card.title, category: "HR", icon: card.icon }, card.title));
                                    }) })] }))] }) })] }));
};
export default React.memo(IntegrationPage);
