import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Statistic } from 'antd';
import { useRecoilValue } from 'recoil';
import React, { useMemo } from 'react';
import { canEditPageSelector } from 'src/state';
import { MetricBarShowCurrencySignSet } from '../../../shared';
import { getAccountCurrencySymbol } from '../../../utils/Account';
import { upperCaseStringToStartCase } from '../../../utils/Tools/LodashTool';
var Meta = Card.Meta;
var MetricBarEntry = function (_a) {
    var metric = _a.metric, deleteMetric = _a.deleteMetric, openEditMetricModal = _a.openEditMetricModal;
    var accountCurrency = getAccountCurrencySymbol();
    var canEditPage = useRecoilValue(canEditPageSelector);
    var menuItems = [
        {
            key: 'edit',
            icon: _jsx(EditOutlined, {}),
            label: 'Edit',
            onClick: function () { return openEditMetricModal(metric); },
        },
        {
            key: 'delete',
            icon: _jsx(DeleteOutlined, {}),
            label: _jsx("span", { className: "text-red-1000", children: "Delete" }),
            onClick: function () { return deleteMetric(metric.metricId); },
        },
    ];
    var metricTitle = useMemo(function () { return _jsx("h1", { children: metric.title }); }, [metric.title]);
    var displayValue = useMemo(function () {
        var _a, _b;
        if (MetricBarShowCurrencySignSet.has(metric.metricName) && metric.metricValue) {
            return "".concat(accountCurrency).concat((_a = metric.metricValue) === null || _a === void 0 ? void 0 : _a.toFixed(2));
        }
        if (metric.metricName === 'show_rate' && metric.metricValue) {
            return "".concat((_b = metric.metricValue) === null || _b === void 0 ? void 0 : _b.toFixed(2), "%");
        }
        return metric.metricValue;
    }, [accountCurrency, metric]);
    return (_jsx("div", { className: "MetricBarEntry shadow-block", children: _jsxs(Card, { children: [_jsx(Statistic, { title: metricTitle, value: displayValue !== null && displayValue !== void 0 ? displayValue : '-' }), _jsx(Meta, { description: upperCaseStringToStartCase(metric.dateRange.dateRangeType) }), canEditPage && (_jsx(Dropdown, { className: "MetricMenu", menu: { items: menuItems }, trigger: ['click'], children: _jsx(Button, { icon: _jsx(MoreOutlined, {}) }) }))] }) }));
};
export default React.memo(MetricBarEntry);
