var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, DatePicker, Input, InputNumber, Row } from 'antd';
import { useHolidayPayApi } from 'src/hooks/api';
import moment from 'moment';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
var RangePicker = DatePicker.RangePicker;
var HolidayMultiplierItem = function (_a) {
    var holidayMultiplier = _a.holidayMultiplier, refetchMultipliers = _a.refetchMultipliers;
    var _b = useHolidayPayApi(), useUpdateHolidayMultiplier = _b.useUpdateHolidayMultiplier, useDeleteHolidayMultiplier = _b.useDeleteHolidayMultiplier;
    var _c = useState(holidayMultiplier.name), inputName = _c[0], setInputName = _c[1];
    var _d = useState(holidayMultiplier.startDate), startDate = _d[0], setStartDate = _d[1];
    var _e = useState(holidayMultiplier.endDate), endDate = _e[0], setEndDate = _e[1];
    var _f = useState(String(holidayMultiplier.payMultiplier)), payRateMultiplier = _f[0], setPayRateMultiplier = _f[1];
    var _g = useState(String(holidayMultiplier.billMultiplier)), billRateMultiplier = _g[0], setBillRateMultiplier = _g[1];
    var overtimePayMultiplier = useState(String(holidayMultiplier.overtimePayMultiplier))[0];
    var updateHolidayMultiplier = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useUpdateHolidayMultiplier.mutateAsync({
                        id: holidayMultiplier.id,
                        name: inputName,
                        startDate: startDate,
                        endDate: endDate,
                        payMultiplier: Number(payRateMultiplier),
                        billMultiplier: Number(billRateMultiplier),
                        overtimePayMultiplier: Number(overtimePayMultiplier),
                    })];
                case 1:
                    _a.sent();
                    refetchMultipliers();
                    return [2 /*return*/];
            }
        });
    }); }, [
        holidayMultiplier.id,
        inputName,
        startDate,
        endDate,
        payRateMultiplier,
        billRateMultiplier,
        overtimePayMultiplier,
        refetchMultipliers,
    ]);
    // Call update API when date changes
    useEffect(function () {
        updateHolidayMultiplier();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);
    var handleOnBlur = useCallback(function (e) {
        if (!e.target.value) {
            setInputName(holidayMultiplier.name);
            updateHolidayMultiplier();
        }
        else {
            updateHolidayMultiplier();
        }
    }, [holidayMultiplier.name, updateHolidayMultiplier]);
    var deleteHolidayMultiplier = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useDeleteHolidayMultiplier.mutateAsync(holidayMultiplier.id)];
                case 1:
                    _a.sent();
                    refetchMultipliers();
                    return [2 /*return*/];
            }
        });
    }); }, [holidayMultiplier.id, refetchMultipliers]);
    var rateMultipliers = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Col, { span: 3, children: _jsx(InputNumber, { className: "bg-[#f8f8f9] font-medium hover:bg-[#f8f8f9]", min: "1", value: payRateMultiplier, onChange: function (e) { return setPayRateMultiplier(e !== null && e !== void 0 ? e : '1'); }, onBlur: updateHolidayMultiplier, onPressEnter: function (e) { return e.currentTarget.blur(); }, bordered: false }) }), _jsx(Col, { span: 3, children: _jsx(InputNumber, { className: "bg-[#f8f8f9] font-medium hover:bg-[#f8f8f9]", min: "1", value: billRateMultiplier, onChange: function (e) { return setBillRateMultiplier(e !== null && e !== void 0 ? e : '1'); }, onBlur: updateHolidayMultiplier, onPressEnter: function (e) { return e.currentTarget.blur(); }, bordered: false }) })] })); }, [billRateMultiplier, payRateMultiplier, updateHolidayMultiplier]);
    return (_jsxs(Row, { className: "relative", gutter: 8, children: [_jsx(Col, { span: 6, children: _jsx(Input, { className: "bg-[#f8f8f9] font-medium hover:bg-[#f8f8f9]", placeholder: "Enter a name for type", value: inputName, onChange: function (e) { return setInputName(e.target.value); }, onBlur: handleOnBlur, onPressEnter: function (e) { return e.currentTarget.blur(); }, bordered: false }) }), _jsx(Col, { span: 9, className: "align-self-center", children: _jsx(RangePicker, { showTime: true, showSecond: false, value: [moment(startDate), moment(endDate)], onChange: function (value) {
                        if (!value)
                            return;
                        setStartDate(moment(value[0]).format('YYYY-MM-DDTHH:mm'));
                        setEndDate(moment(value[1]).format('YYYY-MM-DDTHH:mm'));
                    }, format: 'MMM-DD h:mm A', allowClear: false }) }), rateMultipliers, _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this holiday?", onConfirm: deleteHolidayMultiplier, placement: "left", children: _jsx("div", { className: "GroupRuleDeleteBtn absolute right-[-46px] top-[-2px]", children: _jsx(TrashOutlinedIcon, {}) }) })] }));
};
export default React.memo(HolidayMultiplierItem);
