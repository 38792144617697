import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CollectionType, CollectionTableType, NativePath } from 'src/shared';
import { scheduleShiftCardBasicInfoSelector, collectionTableSchemaNativePathMapSelector, detailModalInstancesState, existingDetailModalInfo, } from 'src/state';
import { getNativeFieldCellContent } from 'src/utils/Collection';
/**
 * Returns a default start date for date cells in a break detail modal depending on the reference shift detail or scheduled break start.
 */
export var useBreakDefaultStartDate = function () {
    var _a, _b, _c, _d, _e;
    var existingDetailModalData = useRecoilValue(existingDetailModalInfo);
    var detailModalInstances = useRecoilValue(detailModalInstancesState);
    var defaultModalData = (_a = detailModalInstances[detailModalInstances.length - 1]) === null || _a === void 0 ? void 0 : _a.defaultModalData;
    var isBreakCollection = (defaultModalData === null || defaultModalData === void 0 ? void 0 : defaultModalData.mainRecord.collectionInfo.type) === CollectionType.BREAK ||
        (existingDetailModalData === null || existingDetailModalData === void 0 ? void 0 : existingDetailModalData.collectionInfo.type) === CollectionType.BREAK;
    var _f = useRecoilValue(scheduleShiftCardBasicInfoSelector({
        type: CollectionTableType.DETAIL_MODAL,
        collectionId: (_b = existingDetailModalData === null || existingDetailModalData === void 0 ? void 0 : existingDetailModalData.collectionInfo.id) !== null && _b !== void 0 ? _b : '',
        recordId: (_c = existingDetailModalData === null || existingDetailModalData === void 0 ? void 0 : existingDetailModalData.rows[0].recordId) !== null && _c !== void 0 ? _c : '',
    })), clockIn = _f.clockIn, startAt = _f.startAt;
    var referenceShiftDefaultStartDate = useMemo(function () {
        return clockIn !== null && clockIn !== void 0 ? clockIn : startAt;
    }, [clockIn, startAt]);
    var breakNativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector((_d = existingDetailModalData === null || existingDetailModalData === void 0 ? void 0 : existingDetailModalData.collectionInfo.id) !== null && _d !== void 0 ? _d : ''));
    return isBreakCollection
        ? (existingDetailModalData === null || existingDetailModalData === void 0 ? void 0 : existingDetailModalData.collectionInfo.type) === CollectionType.SHIFT
            ? // New break detail modal
                referenceShiftDefaultStartDate
            : existingDetailModalData
                ? // Existing break detail modal
                    ((_e = getNativeFieldCellContent(existingDetailModalData.cells[existingDetailModalData.rows[0].recordId], breakNativeSchemaMap, NativePath.BREAK_SCHEDULED_START_AT)) !== null && _e !== void 0 ? _e : undefined)
                : undefined
        : undefined;
};
