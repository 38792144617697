import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useSetRecoilState } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import ConfigureMetricsDropdown from './Metrics/ConfigureMetricsDropdown';
import RecordDetailSchemaColumnsSection from './Schemas/RecordDetailSchemaColumnsSection';
import RecordDetailModalMetrics from './Metrics/RecordDetailModalMetrics';
import { visibleSchemaColumnsInDetailModalSiderState } from './Schemas/atom';
var RecordDetailMetricsSchemasSection = function (_a) {
    var collectionId = _a.collectionId, recordId = _a.recordId, collectionType = _a.collectionType, timeZone = _a.timeZone, extraMetrics = _a.extraMetrics;
    var useGetRecordDetailMetricsAndSchemas = usePageApi().useGetRecordDetailMetricsAndSchemas;
    var setVisibleSchemaColumnsInDetailModalSider = useSetRecoilState(visibleSchemaColumnsInDetailModalSiderState(collectionId));
    var _b = useGetRecordDetailMetricsAndSchemas({
        recordId: recordId,
        options: {
            enabled: !!recordId,
            cacheTime: 0,
        },
    }), recordDetailMetricsAndSchemas = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    // Process visible schemas setting into state
    useEffect(function () {
        if (recordDetailMetricsAndSchemas && recordDetailMetricsAndSchemas.schemaMetrics) {
            setVisibleSchemaColumnsInDetailModalSider(recordDetailMetricsAndSchemas.schemaMetrics);
        }
    }, [recordDetailMetricsAndSchemas, setVisibleSchemaColumnsInDetailModalSider]);
    if (isLoading || !recordDetailMetricsAndSchemas) {
        return (_jsx("div", { className: "border-b-2 border-b-gray-50 pb-8 pt-7", children: _jsx(Skeleton, { title: false, paragraph: { rows: 1 }, active: true }) }));
    }
    return (_jsxs("div", { className: "overflow-x-auto pt-2 w-full", children: [_jsx(RecordDetailSchemaColumnsSection, { collectionId: collectionId, collectionType: collectionType, recordId: recordId }), _jsx(RecordDetailModalMetrics, { timeZone: timeZone, recordDetailMetrics: recordDetailMetricsAndSchemas.metrics, extraMetrics: extraMetrics }), _jsx("div", { className: "mt-4", children: _jsx(ConfigureMetricsDropdown, { collectionId: collectionId, collectionType: collectionType, visibleMetrics: recordDetailMetricsAndSchemas.metrics, refetchMetrics: refetch }) })] }));
};
export default React.memo(RecordDetailMetricsSchemasSection);
