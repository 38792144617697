import { NativePath } from 'src/shared';
import { RecordDataDisplayOptionType, } from 'src/services/Interfaces';
import { PayReportExportType } from '../Constant';
export var getSelectableUserColumns = function (userColumns, usersCollectionId) {
    return userColumns === null || userColumns === void 0 ? void 0 : userColumns.columns.filter(function (column) {
        return (column.refCollectionId && column.refCollectionId == usersCollectionId) ||
            (column.nativePath && column.nativePath == NativePath.LOCATION_EMAIL);
    });
};
var getDefaultCSVFormatOptions = function (args) {
    var recordId = args.recordId, individualPayReportTypes = args.individualPayReportTypes, aggregatePayReportTypes = args.aggregatePayReportTypes, individualReportFormatOptions = args.individualReportFormatOptions, aggregateReportFormatOptions = args.aggregateReportFormatOptions;
    return recordId
        ? individualPayReportTypes
            ? individualReportFormatOptions.filter(function (option) {
                return option.type === 'CSV' &&
                    individualPayReportTypes.supported.includes(option.value);
            })
            : individualReportFormatOptions.filter(function (option) { return option.type === 'CSV'; })
        : aggregatePayReportTypes
            ? aggregateReportFormatOptions.filter(function (option) {
                return option.type === 'CSV' &&
                    aggregatePayReportTypes.supported.includes(option.value);
            })
            : aggregateReportFormatOptions.slice(0, 13);
};
var getDefaultPDFFormatOptions = function (args) {
    var recordId = args.recordId, individualPayReportTypes = args.individualPayReportTypes, aggregatePayReportTypes = args.aggregatePayReportTypes, individualReportFormatOptions = args.individualReportFormatOptions, aggregateReportFormatOptions = args.aggregateReportFormatOptions;
    return recordId
        ? individualPayReportTypes
            ? individualReportFormatOptions.filter(function (option) {
                return option.type === 'PDF' &&
                    individualPayReportTypes.supported.includes(option.value);
            })
            : individualReportFormatOptions.filter(function (option) { return option.type === 'PDF'; })
        : aggregatePayReportTypes
            ? aggregateReportFormatOptions.filter(function (option) {
                return option.type === 'PDF' &&
                    aggregatePayReportTypes.supported.includes(option.value);
            })
            : [];
};
export var getDefaultFormatOptions = function (args) {
    return {
        defaultCSVFormatOptions: getDefaultCSVFormatOptions(args),
        defaultPDFFormatOptions: getDefaultPDFFormatOptions(args),
    };
};
export var getRulesValidationForField = function (selectedFormat, field) {
    switch (selectedFormat) {
        case PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT:
            return sagePayCustom(field);
        case PayReportExportType.INTERIM_SCRANTON_REPORT_EXPORT:
            return interimScrantonInvoiceReports(field);
        case PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT:
            return interimScrantonInvoiceReports(field);
        default:
            return [];
    }
};
var sagePayCustom = function (field) {
    switch (field) {
        case 'invoiceNumber':
            return [{ required: true, message: 'Invoice number is required' }];
        case 'invoiceDate':
            return [{ required: true, message: 'Invoice create date is required' }];
        case 'invoiceDueDate':
            return [{ required: true, message: 'Invoice due date is required' }];
        default:
            return [];
    }
};
var interimScrantonInvoiceReports = function (field) {
    switch (field) {
        case 'invoiceNumber':
            return [{ required: true, message: 'Invoice number is required' }];
        case 'invoiceDate':
            return [{ required: true, message: 'Invoice create date is required' }];
        default:
            return [];
    }
};
var getFormValueFromCellData = function (cellData) {
    if (cellData.type === RecordDataDisplayOptionType.SCHEMA) {
        return cellData.schemaId;
    }
    else if (cellData.type === RecordDataDisplayOptionType.SECONDARY_SCHEMA) {
        // We basically use the two UUIDs (primary schema ID and secondary schema ID) combined by "&"" to conveniently couple the two since the API needs both
        return (cellData.schemaId +
            '&' +
            cellData.secondarySchemaId);
    }
    return '';
};
export var mapLinkedDataTemplateToFormFieldValues = function (contentArray) {
    if (contentArray.length === 0)
        return [{ value: undefined, label: '' }];
    return contentArray.map(function (content) {
        var _a;
        return ({
            label: (_a = content.label) !== null && _a !== void 0 ? _a : '',
            value: getFormValueFromCellData(content.cellData),
        });
    });
};
export var mapLinkedDataTemplateToTableColumnValues = function (contentArray) {
    if (contentArray.length === 0)
        return [{ value: undefined, label: '' }];
    return contentArray.map(function (content) { return ({
        label: content.columnName,
        value: getFormValueFromCellData(content.cellData),
    }); });
};
var constructCellDataFromFormValue = function (value) {
    var _a;
    var schemaIds = (_a = value === null || value === void 0 ? void 0 : value.split('&')) !== null && _a !== void 0 ? _a : [];
    if (schemaIds.length <= 1) {
        return {
            type: RecordDataDisplayOptionType.SCHEMA,
            schemaId: schemaIds[0],
        };
    }
    else {
        return {
            type: RecordDataDisplayOptionType.SECONDARY_SCHEMA,
            schemaId: schemaIds[0],
            secondarySchemaId: schemaIds[1],
        };
    }
};
export var mapLinkedDataFormFieldValuesToTemplate = function (contentArray) {
    return contentArray
        .filter(function (content) { return !!content.value; })
        .map(function (content) { return ({
        label: content.label,
        cellData: constructCellDataFromFormValue(content.value),
    }); });
};
export var mapLinkedDataTableColumnValuesToTemplate = function (contentArray) {
    return contentArray
        .filter(function (content) { return !!content.value; })
        .map(function (content) { return ({
        columnName: content.label,
        cellData: constructCellDataFromFormValue(content.value),
    }); });
};
