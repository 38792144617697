import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { DropdownInput } from 'src/components/TableView/TableCell/SelectorCell';
import { isPlainSelection, OPTIONS_COLOR } from 'src/shared';
import React, { useState } from 'react';
import OptionColorPicker from './OptionColorPicker';
var EditSelectOption = function (_a) {
    var selectedColor = _a.selectedColor, schema = _a.schema, editOptionIndex = _a.editOptionIndex, availableOptions = _a.availableOptions, onOptionColorChange = _a.onOptionColorChange, onTextChange = _a.onTextChange, onDeleteColumnOption = _a.onDeleteColumnOption, onEditMenuClose = _a.onEditMenuClose;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (_jsx(Dropdown, { overlayStyle: { zIndex: 1101 }, trigger: ['click'], overlayClassName: "selector-dropdown-overlay", open: open, dropdownRender: function () { return (_jsxs(Menu, { className: "selector-menu", children: [isPlainSelection(schema.type) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "selector-menu-input-wrapper", children: _jsx(DropdownInput, { tagTitle: availableOptions[editOptionIndex]
                                    ? availableOptions[editOptionIndex].text
                                    : '', onTextChange: onTextChange, contentId: availableOptions[editOptionIndex].id }) }), _jsx("div", { className: "block-padding-6px" }), _jsxs(Menu.Item, { onClick: function () { return onDeleteColumnOption(editOptionIndex); }, children: [_jsx(DeleteOutlined, { className: "selector-menu-delete" }), _jsx("span", { className: "selector-menu-delete-text", children: "Delete" })] })] })), _jsx("div", { className: "block-padding-12px" }), _jsx("span", { className: "selector-menu-color-description", onClick: function (e) { return e.stopPropagation(); }, children: "Select a color." }), _jsx("div", { className: "block-padding-8px" }), OPTIONS_COLOR === null || OPTIONS_COLOR === void 0 ? void 0 : OPTIONS_COLOR.map(function (_color, index) { return (_jsx(Menu.Item, { onClick: onOptionColorChange(editOptionIndex), children: _jsx(OptionColorPicker, { index: index, selectedColor: selectedColor }) }, OPTIONS_COLOR[index])); })] })); }, onOpenChange: function (visible) {
            setOpen(visible);
            if (!visible) {
                onEditMenuClose(editOptionIndex);
            }
        }, children: _jsx("a", { className: "selector-dropdown-more-icon", onClick: function (e) {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true);
            }, children: _jsx(EllipsisOutlined, {}) }) }));
};
export default React.memo(EditSelectOption);
