var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { CollectionType, NativePath } from 'src/shared';
import { Modal, Select } from 'antd';
import pluralize from 'pluralize';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useRecoilValue } from 'recoil';
import { useAccountApi } from 'src/hooks/api';
import { useJobPlacementApi } from 'src/hooks/api/useJobPlacementApi';
import { useDebounce } from 'src/hooks/component';
var Option = Select.Option;
var DEBOUNCE_TIME = 1000;
var PlacementTasksPublishOption = function (_a) {
    var reverseCollectionProps = _a.reverseCollectionProps;
    var _b = useState(false), isInit = _b[0], setIsInit = _b[1];
    var _c = useState(false), modalOpen = _c[0], setModalOpen = _c[1];
    var _d = useState([]), selectedSchemaIds = _d[0], setSelectedSchemaIds = _d[1];
    var debouncedSelectedSchemaIds = useDebounce(selectedSchemaIds, DEBOUNCE_TIME);
    var jobCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.JOB));
    var placementCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.PLACEMENT));
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var _e = useJobPlacementApi(), useGetPropagateJobTasksFromPlacementInfo = _e.useGetPropagateJobTasksFromPlacementInfo, propagateJobTasksFromPlacement = _e.propagateJobTasksFromPlacement;
    // Fetch job schemas
    var jobColumns = useGetSchemaColumnsInCollection({
        collectionId: jobCollectionId !== null && jobCollectionId !== void 0 ? jobCollectionId : '',
        options: {
            enabled: modalOpen && !!jobCollectionId,
            onSuccess: function (data) {
                if (data) {
                    setSelectedSchemaIds(data.columns
                        .filter(function (column) {
                        return column.nativePath &&
                            column.nativePath === NativePath.JOB_LOCATION_ID;
                    })
                        .map(function (column) { return column.id; }));
                    //! So initial call of propagation info count has location/role preselected
                    setTimeout(function () { return setIsInit(true); }, DEBOUNCE_TIME + 100);
                }
            },
        },
    }).data;
    // Fetch job schemas
    var placementColumns = useGetSchemaColumnsInCollection({
        collectionId: placementCollectionId !== null && placementCollectionId !== void 0 ? placementCollectionId : '',
        options: {
            enabled: modalOpen && !!placementCollectionId,
        },
    }).data;
    // Job schemas that can mapped from/to placements
    var selectableJobSchemas = useMemo(function () {
        return jobColumns === null || jobColumns === void 0 ? void 0 : jobColumns.columns.filter(function (column) {
            return column.nativePath
                ? [
                    NativePath.JOB_DESCRIPTION,
                    NativePath.JOB_START_DATE,
                    NativePath.JOB_END_DATE,
                    NativePath.JOB_HOURLY_RATE,
                    NativePath.JOB_HOURS_PER_WEEK,
                    NativePath.JOB_YEARS_OF_EXPERIENCE,
                    NativePath.JOB_LOCATION_ID,
                    NativePath.JOB_ROLE_IDS,
                ].includes(column.nativePath)
                : // Custom schemas with same name & type
                    placementColumns === null || placementColumns === void 0 ? void 0 : placementColumns.columns.find(function (schema) {
                        return !column.nativePath &&
                            column.name.trim().toLowerCase() === schema.name.trim().toLowerCase() &&
                            column.type === schema.type &&
                            column.inputMode === schema.inputMode &&
                            column.refCollectionId === schema.refCollectionId &&
                            column.refGroupId === schema.refGroupId;
                    });
        });
    }, [jobColumns === null || jobColumns === void 0 ? void 0 : jobColumns.columns, placementColumns]);
    // Propagation info counts
    var _f = useGetPropagateJobTasksFromPlacementInfo({
        body: {
            placementRid: reverseCollectionProps.recordId,
            jobSchemaIds: debouncedSelectedSchemaIds,
        },
        options: {
            enabled: modalOpen && isInit && !!(selectableJobSchemas === null || selectableJobSchemas === void 0 ? void 0 : selectableJobSchemas.length),
        },
    }), propagationInfo = _f.data, isFetchingPropagationInfo = _f.isFetching;
    var publishChanges = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!propagationInfo)
                        return [2 /*return*/];
                    return [4 /*yield*/, propagateJobTasksFromPlacement({
                            placementRid: reverseCollectionProps.recordId,
                            jobSchemaIds: debouncedSelectedSchemaIds,
                        }, propagationInfo.jobCount)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        debouncedSelectedSchemaIds,
        propagateJobTasksFromPlacement,
        propagationInfo,
        reverseCollectionProps.recordId,
    ]);
    var content = useMemo(function () {
        var _a = propagationInfo !== null && propagationInfo !== void 0 ? propagationInfo : {}, _b = _a.confirmedTaskCount, confirmedTaskCount = _b === void 0 ? 0 : _b, _c = _a.dismissedTaskCount, dismissedTaskCount = _c === void 0 ? 0 : _c, _d = _a.jobCount, jobCount = _d === void 0 ? 0 : _d;
        return (_jsxs("div", { className: "flex flex-col gap-[12px]", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-sm font-medium", children: "Matching Jobs" }), _jsx("div", { className: "text-sm font-medium text-gray-400", children: "Apply to all jobs that match the selected fields" }), _jsx(Select, { className: "mt-[4px]", mode: "multiple", value: selectedSchemaIds, onChange: setSelectedSchemaIds, showSearch: false, children: selectableJobSchemas === null || selectableJobSchemas === void 0 ? void 0 : selectableJobSchemas.map(function (schema) { return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id)); }) })] }), _jsxs("div", { className: "flex flex-col text-sm font-medium text-gray-400", children: [_jsxs("div", { children: [isFetchingPropagationInfo ? _jsx(LoadingOutlined, {}) : confirmedTaskCount, ' ', pluralize('task', confirmedTaskCount), " confirmed"] }), _jsxs("div", { children: [isFetchingPropagationInfo ? _jsx(LoadingOutlined, {}) : dismissedTaskCount, ' ', pluralize('task', dismissedTaskCount), " dismissed"] })] }), _jsxs("div", { className: "text-base font-medium", children: [isFetchingPropagationInfo ? _jsx(LoadingOutlined, {}) : jobCount, ' ', pluralize('job', jobCount), " will be updated"] })] }));
    }, [isFetchingPropagationInfo, propagationInfo, selectableJobSchemas, selectedSchemaIds]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center gap-[8px] px-[12px] py-[8px] rounded-full bg-accent text-white text-sm font-medium cursor-pointer", role: "button", onClick: function () { return setModalOpen(true); }, children: [_jsx(CloudUploadOutlined, { className: "!text-white", size: 24 }), _jsx("div", { children: "Verify Tasks List" })] }), modalOpen && (_jsx(Modal, { open: true, centered: true, title: _jsx("div", { className: "text-lg font-semibold", children: "Verify Tasks List" }), closable: false, cancelButtonProps: {
                    style: { borderRadius: '16px', border: '1px solid #D3D3D7' },
                }, okText: "Apply", okButtonProps: {
                    style: {
                        borderRadius: '16px',
                        backgroundColor: 'black',
                        color: 'white',
                        cursor: isFetchingPropagationInfo ? 'not-allowed' : 'pointer',
                    },
                }, okType: "default", onCancel: function () { return setModalOpen(false); }, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, publishChanges()];
                            case 1:
                                _a.sent();
                                setModalOpen(false);
                                return [2 /*return*/];
                        }
                    });
                }); }, children: content }))] }));
};
export default React.memo(PlacementTasksPublishOption);
